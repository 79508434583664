import { useLazyQuery } from '@apollo/client';

import { JOB_EMPLOYMENT_TYPE } from '@jebel/constants';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  OrganizationSearchQuery,
  OrganizationSearchQueryVariables,
  useJobOfferCreateMutation,
} from 'shared/graphql';
import { useCurrentUser, useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

import { ORGANIZATION_SEARCH_QUERY } from '../queries';
import { getOrganizationIdSearchFilter } from '../utils';
import { CreateJobPostForm } from '../components';

export const useOrganizationSearch = () => {
  const [organizationSearch, { data }] = useLazyQuery<
    OrganizationSearchQuery,
    OrganizationSearchQueryVariables
  >(ORGANIZATION_SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const onOrganizationSearch = (organizationName: string | undefined | null) => {
    organizationSearch({
      variables: { filter: getOrganizationIdSearchFilter(organizationName) },
    });
  };

  return {
    onOrganizationSearch,
    organizationId: data?.organizationsList?.items[0]?.id,
  };
};

export function useJobPostCreate() {
  const { user, userId, isAdmin, isBusinessAdminOrManager } = useCurrentUser();

  const [jobOfferCreate, { loading }] = useJobOfferCreateMutation({
    refetchQueries: [
      'CareerSearch',
      'CareersListCount',
      'CareersFilterByZipRequest',
      'CareersPageSearch',
    ],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Your job post has been published!',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Your job post hasn't been published.`,
    },
  });

  const { showSuccess, showError } = useToast();
  const { data: school } = useSchoolConfiguration();

  const onJobOfferCreate = async (data: CreateJobPostForm, organizationId?: string | null) => {
    if (!user || !school) {
      return;
    }

    if (!(isAdmin || isBusinessAdminOrManager)) {
      showError("You don't have Administrative Preferences.");
      return;
    }

    try {
      await jobOfferCreate({
        variables: {
          data: {
            position: data?.position || '',
            description: data?.description || '',
            employmentType: data?.internship
              ? JOB_EMPLOYMENT_TYPE.intern
              : JOB_EMPLOYMENT_TYPE.fullTime,

            organization: {
              connect: {
                id: organizationId,
              },
            },

            schools: {
              // https://github.com/jebelapp/jebel/issues/1568
              connect: [{ id: school.id }],
            },

            creator: {
              connect: {
                id: userId,
              },
            },
          },
        },
      });

      showSuccess('Your job post has been published!');
    } catch (err) {
      recordError(err);
      showError(`Error: Your job post hasn't been published.`);
    }
  };

  return {
    onJobOfferCreate,
    loading,
  };
}
