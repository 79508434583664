import { Skeleton } from '@mui/material';

import { Container } from './FeedSkeleton.styles';

interface Props {
  /** @default 5 */
  count?: number;
}

const FALLBACK_COUNT = 5;

export function FeedSkeleton(props: Props) {
  const items = new Array(props.count ?? FALLBACK_COUNT).fill(null);

  return (
    <Container>
      {items.map((_item, index) => (
        <Skeleton key={index} variant="rectangular" width="100%" height="12rem" />
      ))}
    </Container>
  );
}
