import { School } from '@material-ui/icons';

import { useSchoolConfiguration } from 'shared/hooks';
import { SchoolConfigurationFragment } from 'shared/graphql';

import { AuthorAvatar, AuthorAvatarProps } from './AuthorAvatar';

export interface SchoolAvatarProps extends Omit<AuthorAvatarProps, 'name' | 'source' | 'route'> {
  school?: SchoolConfigurationFragment;
}

export function SchoolAvatar({ school: giveSchool, ...props }: SchoolAvatarProps) {
  const { data: currentSchool } = useSchoolConfiguration();

  const school = giveSchool ?? currentSchool;

  const name = school?.name;
  const source = school?.images?.logo?.downloadUrl ?? undefined;

  return (
    <AuthorAvatar {...props} size={40} source={source} name={name}>
      <School fontSize="inherit" />
    </AuthorAvatar>
  );
}
