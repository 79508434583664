import { UserStatus } from '@jebel/constants';
/**
 * Determines if a user can be approved based on their status.
 *
 * @param user - The user object containing the user's status.
 * @returns A boolean indicating whether the user can be approved.
 */
export function canUserBeApproved(user) {
    return (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.Pending || (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.Rejected;
}
/**
 * Determines if a user can be activated based on their status.
 *
 * @param user - The user object containing the user's status.
 * @returns A boolean indicating whether the user can be activated.
 */
export function canUserBeActivated(user) {
    return (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.Active || (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.Inactive;
}
/**
 * Determines if a user can be rejected based on their status.
 *
 * @param user - The user object containing the user's status.
 * @returns A boolean indicating whether the user can be rejected.
 */
export function canUserBeRejected(user) {
    return (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.InvitationSent || (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.Pending;
}
/**
 * Determines if a user can be receive an invitation again based on their status.
 *
 * @param user - The user object containing the user's status.
 * @returns A boolean indicating whether the user can be receive an invitation again.
 */
export function canUserReceiveInvitationAgain(user) {
    return (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.InvitationSent;
}
/**
 * Determines if a user can be reset based on their status.
 *
 * @param user - The user object containing the user's status.
 * @returns A boolean indicating whether the user can be reset.
 */
export function canUserBeCredentialsReset(user) {
    return (user === null || user === void 0 ? void 0 : user.userStatus) === UserStatus.InvitationSent;
}
