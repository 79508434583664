import { useState } from 'react';
import { IconButton, InputAdornment, OutlinedTextFieldProps, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FormFieldValidation, composeFieldValidators } from '@jebel/utils';
import { useField, useFormikContext } from 'formik';

import { getIsInvalid } from 'shared/components/ui';

export interface PasswordFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  /**
   * The name of the field in the formik form.
   */
  name: string;

  /**
   * The validation function for the field.
   */
  validate?: FormFieldValidation<string>;
}

export function PasswordField({ validate, ...props }: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  const validation = composeFieldValidators(
    props.required === true ? props.required : undefined,
    validate,
  );

  const [field, meta] = useField({ name: props.name, validate: validation });

  const form = useFormikContext();

  const hasError = getIsInvalid({ meta, form });
  const message = hasError ? meta.error : undefined;

  const toggleShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      value={field.value ?? ''}
      onChange={field.onChange}
      error={hasError}
      helperText={message ?? props.helperText}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword}>
              {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
