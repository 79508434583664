import { useQuery } from '@apollo/client';

import { HOME_FEED_JOB_OFFERS_LIST_QUERY } from '../queries';
import type {
  HomeFeedJobOffersListQuery,
  HomeFeedJobOffersListQueryVariables,
  CareerInfoFragment,
} from '../../../shared/graphql/__generated__';

export const useHomeFeedJobOffersList = () => {
  const { data, loading } = useQuery<
    HomeFeedJobOffersListQuery,
    HomeFeedJobOffersListQueryVariables
  >(HOME_FEED_JOB_OFFERS_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  });

  return {
    items: (data?.jobOffersList?.items || []) as CareerInfoFragment[],
    loading,
  };
};
