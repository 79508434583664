import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Box, Button } from '@material-ui/core';

import { Form, FormTextField } from 'shared/components/ui';
import { required } from 'shared/utils/form';
import { useCurrentUser, useResponsive, useToast } from 'shared/hooks';
import { PasswordField } from 'shared/components/form';

import { useUserPasswordUpdate, useUserEmailUpdate } from '../hooks';
import { SettingsContentLayout } from '../../../shared/components/layouts';
import { shouldBePassword } from '@jebel/utils';
import { recordError } from 'shared/utils/record';

const formCss = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

const actionsCSS = css`
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
`;

type FormData = {
  email?: string | undefined | null;
  previousPassword?: string | undefined | null;
  proposedPassword?: string | undefined | null;
  confirmProposedPassword?: string | undefined | null;
};

export function SecuritySettingPage() {
  const { isMobile } = useResponsive();

  const { showError } = useToast();
  const { onUserEmailUpdate } = useUserEmailUpdate();
  const { onUserPasswordUpdate } = useUserPasswordUpdate();
  const { user, loading: userLoading } = useCurrentUser();

  const initialValues = {
    email: user?.email,
    password: '',
    showPassword: false,
  };

  const onSubmit = async (values: FormData, resetForm: () => void) => {
    try {
      if (values.proposedPassword) {
        await onUserPasswordUpdate(
          values.email ?? '',
          values.previousPassword ?? '',
          values.proposedPassword ?? '',
          values.confirmProposedPassword ?? '',
        );
      }

      if (values.email !== user?.email) {
        await onUserEmailUpdate(
          user?.email ?? '',
          values?.email ?? '',
          values?.previousPassword ?? '',
        );
      }

      resetForm();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return (
    <SettingsContentLayout isLoading={userLoading} title="Sign-in & Security">
      <Form
        oldCss={formCss}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
        validateOnBlur={false}
      >
        {({ isSubmitting, resetForm }) => (
          <Fragment>
            <Box display="grid" gridTemplateRows="auto" gridGap={20}>
              <FormTextField
                inputProps={{
                  label: isMobile ? 'EMAIL ADDRESS / LOGIN ID' : 'Email',
                  variant: 'outlined',
                  autoComplete: 'email',
                }}
                fieldProps={{ name: 'email', validate: required }}
              />

              <PasswordField
                label="Password"
                name="previousPassword"
                autoComplete="current-password"
                validate={shouldBePassword}
              />

              <PasswordField
                label="New Password"
                name="proposedPassword"
                autoComplete="new-password"
                validate={shouldBePassword}
              />

              <PasswordField
                label="Confirm New Password"
                name="confirmProposedPassword"
                autoComplete="new-password"
                validate={shouldBePassword}
              />
            </Box>

            <Box css={actionsCSS}>
              <Button
                onClick={() => resetForm()}
                disabled={isSubmitting}
                size="medium"
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disabled={isSubmitting}
              >
                Save Changes
              </Button>
            </Box>
          </Fragment>
        )}
      </Form>
    </SettingsContentLayout>
  );
}
