import React from 'react';
import { css } from '@emotion/react';
import { Typography as MuiTypography } from '@material-ui/core';

import { TypographyProps } from './types';
import { Skeleton } from '../Skeleton';

type TypographyRefElement<C extends React.ElementType> = C extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[C]
  : HTMLParagraphElement;

type TypographyForwardRefComponent = <C extends React.ElementType = 'p'>(
  props: TypographyProps<C> & React.RefAttributes<TypographyRefElement<C>>,
) => JSX.Element | null;

const ellipsisCss = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const Typography: TypographyForwardRefComponent = React.forwardRef(function Typography<
  C extends React.ElementType = 'p',
>(
  { loading = false, withEllipsis, ...rest }: TypographyProps<C>,
  ref: React.Ref<TypographyRefElement<C>>,
) {
  return (
    <Skeleton loading={loading}>
      <MuiTypography
        ref={ref}
        paragraph
        color="initial"
        {...rest}
        css={css`
          margin-bottom: 0;
          ${rest.css}
          ${withEllipsis ? ellipsisCss : undefined}
        `}
      />
    </Skeleton>
  );
});
