import { PropsWithChildren } from 'react';
import { Skeleton as BaseSkeleton, SkeletonProps as BaseProps } from '@material-ui/lab';

export interface SkeletonProps extends BaseProps {
  /**
   * If `true`, the skeleton will be shown.
   * @default true
   */
  loading?: boolean;
}

/**
 * A wrapper component that conditionally renders a skeleton loader or its children.
 *
 * @param {PropsWithChildren<SkeletonProps>} props - The properties passed to the component.
 * @param {boolean} props.loading - A flag indicating whether the skeleton loader should be displayed.
 * @param {React.ReactNode} props.children - The child components to be rendered when not loading.
 */
export function Skeleton({ loading = true, ...props }: PropsWithChildren<SkeletonProps>) {
  if (!loading) {
    return props.children as JSX.Element;
  }

  return <BaseSkeleton {...props} />;
}
