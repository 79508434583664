import React from 'react';
import { useSnackbar } from 'notistack';
import { createSnackMessage, SNACK_TYPES } from 'shared/components/ui';

interface asyncHandlerOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: Error) => string;
  onFinally?: () => void;
}

type GenericAsyncFunction = (...args: any[]) => any;

export function useAsyncWrapperFactory() {
  const { enqueueSnackbar } = useSnackbar();

  return React.useCallback(
    <Fn extends GenericAsyncFunction>(callback: Fn, options: asyncHandlerOptions = {}) => {
      const { onSuccess, onError, onFinally } = options;
      return (...args: Parameters<Fn>): Promise<ReturnType<Fn>> => {
        return callback(...args)
          .then(data => onSuccess?.(data) ?? data)
          .catch((error: Error) => {
            const errorMessage = onError?.(error) ?? error.message;
            enqueueSnackbar(errorMessage, {
              autoHideDuration: 5000,
              content: createSnackMessage(SNACK_TYPES.error),
            });
            throw error;
          })
          .finally(() => onFinally?.());
      };
    },
    [enqueueSnackbar],
  );
}
