import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';

interface StyledAvatarProps {
  size?: string | number;
  disabled?: boolean;
}

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};

  ${p => {
    if (!p.size) {
      return;
    }

    let size = String(p.size);

    if (!size.includes('px') || !size.includes('em')) {
      // If the size is a number, we assume it's in pixels.
      size = `${size}px`;
    }

    return css`
      width: ${size};
      height: ${size};
      font-size: calc(${size} * 0.6);
    `;
  }}
`;
