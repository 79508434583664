import { PropsWithChildren } from 'react';

import { FeedLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';
import { LEGACY_POSTS_SORT_INFO_DEFAULT_VALUE } from 'features/legacy/constants/sort';

import { LegacyDetailsSidebar } from '../LegacyDetailsSidebar';

export function LegacyLayout(props: PropsWithChildren<unknown>) {
  return (
    <SearchProvider
      defaultFilterOption={{}}
      defaultSortOption={LEGACY_POSTS_SORT_INFO_DEFAULT_VALUE}
    >
      <FeedLayout sidebar={<LegacyDetailsSidebar />}>{props.children}</FeedLayout>
    </SearchProvider>
  );
}
