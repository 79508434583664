import { Box, IconButton, Tooltip } from '@material-ui/core';

import { useSearchContext } from '../../providers';
import { ViewList, ViewModule } from '@material-ui/icons';

export function LayoutControl() {
  const { setDisplayList, displayList } = useSearchContext();

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Show as list">
        <IconButton
          color={displayList ? 'secondary' : 'default'}
          size="medium"
          onClick={() => setDisplayList(true)}
        >
          <ViewList />
        </IconButton>
      </Tooltip>

      <Tooltip title="Show as grid">
        <IconButton
          color={displayList ? 'default' : 'secondary'}
          size="medium"
          onClick={() => setDisplayList(false)}
        >
          <ViewModule />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
