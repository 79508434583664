import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { OrganizationAvatar, SchoolAvatar } from 'shared/components/symbols';
import { CardLayout } from 'shared/components/layouts';

// Container

export const Container = styled(CardLayout.Content)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

const authorAvatarCSS = css`
  min-width: var(--author-avatar-size);
  min-height: var(--author-avatar-size);
`;

export const AuthorOrganizationAvatar = styled(OrganizationAvatar)`
  ${authorAvatarCSS};
`;

export const AuthorSchoolAvatar = styled(SchoolAvatar)`
  ${authorAvatarCSS};
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// Title

export const Title = styled.span`
  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};
`;

// Label

export const Label = styled.span``;

// Buttons

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`;
