import { JOB_EMPLOYMENT_TYPE } from '@jebel/constants';

import { searchFilterGenerator } from 'shared/utils/search';

export const getInternshipSearchFilter = (inputSearchQuery: string): any => {
  const filterArray: any[] = [];
  if (inputSearchQuery) {
    filterArray.push(searchFilterGenerator(inputSearchQuery, ['position']));
  }

  filterArray.push({ employmentType: { equals: JOB_EMPLOYMENT_TYPE.intern } });

  return {
    AND: filterArray,
  };
};
