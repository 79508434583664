import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { useSearchContext } from 'shared/features/search';
import { USERS_LIST_COUNT_QUERY } from 'shared/graphql';
import { UsersListCountQuery, UsersListCountQueryVariables } from 'shared/graphql/__generated__';

import { useResponsive } from '.';

export const useUsersListCount = (
  options: QueryHookOptions<UsersListCountQuery, UsersListCountQueryVariables>,
  localZipQuery?: string,
) => {
  const { isMobile } = useResponsive();
  const { zipQuery } = useSearchContext();
  const zipFilter = React.useMemo(
    () =>
      isMobile
        ? {
            userPreferences: localZipQuery
              ? {
                  address: {
                    zip: { equals: localZipQuery },
                  },
                }
              : {},
          }
        : {
            userPreferences: zipQuery
              ? {
                  address: {
                    zip: { equals: zipQuery },
                  },
                }
              : {},
          },
    [isMobile, localZipQuery, zipQuery],
  );

  const { data, loading, error } = useQuery<UsersListCountQuery, 
  UsersListCountQueryVariables>(
    USERS_LIST_COUNT_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: { filter: { ...options?.variables?.filter, ...zipFilter } },
    },
  );

  return {
    usersCount: data?.users?.count,
    countLoading: loading && !data,
    error,
  };
};
