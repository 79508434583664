import { ReactNode } from 'react';
import { AccountCircle, Forum } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';

import { Buttons } from './ExplorerUserCard.styles';
import { useResponsive } from 'shared/hooks';

interface Props {
  withMessage: boolean;
  withViewProfile: boolean;
  /** Allow to extend the current button options. */
  addons?: ReactNode;

  onOpenProfile(): void;
  onOpenMessage(): void;
}

export function ExplorerUserCardButtons(props: Props) {
  return (
    <Buttons>
      {props.withMessage && <MessageButton onClick={props.onOpenMessage} />}
      {props.withViewProfile && <ViewProfileButton onClick={props.onOpenProfile} />}

      {props.addons}
    </Buttons>
  );
}

function MessageButton(props: { onClick?(): void }) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <IconButton color="primary">
        <Forum fontSize="small" />
      </IconButton>
    );
  }

  return (
    <Button
      size="small"
      color="primary"
      startIcon={<Forum fontSize="small" />}
      onClick={props.onClick}
    >
      Message
    </Button>
  );
}

function ViewProfileButton(props: { onClick?(): void }) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <IconButton color="primary">
        <AccountCircle fontSize="small" />
      </IconButton>
    );
  }

  return (
    <Button
      size="small"
      color="primary"
      startIcon={<AccountCircle fontSize="small" />}
      onClick={props.onClick}
    >
      View Profile
    </Button>
  );
}
