import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { CardLayout } from 'shared/components/layouts';
import { GroupAvatar, GroupNameLink } from 'shared/components/symbols';

// Container

export const Container = styled(CardLayout)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  overflow-x: hidden;
  user-select: none;
`;

// Divided

export const Divided = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

// Logo

export const Logo = styled(GroupAvatar)`
  --size: 5rem;

  width: var(--size);
  height: var(--size);
  cursor: pointer;
`;

Logo.defaultProps = {
  variant: 'rounded',
};

// Content

export const Content = styled.div`
  display: grid;
  align-items: start;
  gap: 0.25rem;
  flex: 1;
  overflow-x: hidden;
`;

// Header

export const Header = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto max-content;
  gap: 1rem;
`;

// HeaderBanner

export const HeaderBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

// Title

export const Title = styled(GroupNameLink)`
  font-weight: bold;
`;

// MembersCount

export const MembersCount = styled(Typography)``;

MembersCount.defaultProps = {
  variant: 'body2',
  color: 'textSecondary',
};

// Description

export const Description = styled.span`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
