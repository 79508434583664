import { JOB_EMPLOYMENT_TYPE } from '@jebel/constants';

import { PreviewSearchQueryVariables } from 'shared/graphql';
import { organizationFilters } from './util';

export const getPreviewCareerSearchFilter = (
  inputSearchQuery: string,
): PreviewSearchQueryVariables => {
  const userFilter: any[] = [];
  const internshipsFilter: any[] = [];
  const jobOfferFilter: any[] = [];

  userFilter.push({
    isSeekingEmployment: { equals: true },
    OR: [
      { firstName: { contains: inputSearchQuery } },
      { lastName: { contains: inputSearchQuery } },
    ],
  });

  internshipsFilter.push({
    employmentType: { equals: JOB_EMPLOYMENT_TYPE.intern },
    position: { contains: inputSearchQuery },
  });

  jobOfferFilter.push({
    employmentType: { not_equals: JOB_EMPLOYMENT_TYPE.intern },
    position: { contains: inputSearchQuery },
    organization: organizationFilters(inputSearchQuery),
  });

  return {
    first: 1,
    userFilter: userFilter[0],
    internshipsFilter: internshipsFilter[0],
    jobOfferFilter: jobOfferFilter[0],
  };
};
