import { useSearchContext } from 'shared/features/search';
import { EventFilter } from 'shared/graphql';

import { EventZipFilter } from '../types';

/**
 * Hook to get the event filter context.
 */
export function useEventSearchContext() {
  return useSearchContext<EventFilter, never, EventZipFilter>();
}
