import { ReactNode, useMemo } from 'react';

import { useResponsive } from 'shared/hooks';
import { FeedSkeleton } from 'shared/components/ui';

import { Container } from './WithAdvertising.styles';

/** @deprecated Replace this "strategy" in the future. */
type Node = JSX.Element | ReactNode | null;

interface Props {
  items: Node[];
  advertising: Node[];
  birthdays?: Node[];
  offers?: Node[];

  loading?: boolean;
  isHome?: boolean;

  className?: string;
}

/** Number of items needed for next advertising. */
const SPACE_BETWEEN_ADV = 4;
/** Number of sections needed for each social section. */
const SPACE_BETWEEN_SOCIAL = 5;

export function WithAdvertising(props: Props) {
  const { isMobile } = useResponsive();

  const isHome = props.isHome ?? false;

  const posts = useMemo(() => {
    const showBirths = isHome && isMobile;
    const showOffers = isHome && isMobile;
    const showSocial = isHome && isMobile;

    // Create copies of the arrays because we are going to modify them.

    const feed = Array.from(props.items);
    const advertisings = Array.from(props.advertising);
    const birthdays = Array.from(props.birthdays ?? []);
    const offers = Array.from(props.offers ?? []);

    let size = feed.length;

    if (showBirths) {
      size += birthdays.length;
    }

    if (showOffers) {
      size += offers.length;
    }

    if (advertisings.length > 0) {
      // Calculate the sections of advertising needed based on the current size.
      // https://8base-dev.atlassian.net/browse/JEB-1364?focusedCommentId=42319
      const sections = size / SPACE_BETWEEN_ADV;
      // Add one because we currently set one at the begining of the ads.
      size += Math.floor(sections) + 1;
    }

    /** Array of elements mixed. */
    const spaces = new Array<Node>(size);

    /** How many sections do we have? */
    let lastSection = 0;
    /** Index of the last advertising pushed. */
    let lastSectionIndex = -SPACE_BETWEEN_ADV;

    for (let index = 0; index < size; index++) {
      const hasBirthDates = birthdays.length > 0;
      const hasOffers = offers.length > 0;

      const isSocial = showSocial && lastSection % SPACE_BETWEEN_SOCIAL === 1;
      const sectionEvery = isSocial ? SPACE_BETWEEN_ADV + 2 : SPACE_BETWEEN_ADV;

      const isAdv = index === lastSectionIndex + (sectionEvery + 1);
      const isBirth = isSocial && hasBirthDates && index === lastSectionIndex + 2;
      const isOffer = isSocial && hasOffers && index === lastSectionIndex + 3;

      if (isAdv && advertisings.length > 0) {
        spaces[index] = advertisings.shift();

        lastSectionIndex = index;
        lastSection++;

        continue;
      }

      if (showBirths && isBirth && birthdays.length > 0) {
        spaces[index] = birthdays.shift();
        continue;
      }

      if (showOffers && isOffer && offers.length > 0) {
        spaces[index] = offers.shift();
        continue;
      }

      spaces[index] = feed.shift();
    }

    return spaces;
  }, [props.items, props.advertising, props.birthdays, props.offers, isHome, isMobile]);

  if (props.loading) {
    return (
      <Container>
        <FeedSkeleton />
      </Container>
    );
  }

  return <Container className={props.className}>{posts}</Container>;
}
