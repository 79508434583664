import React, { forwardRef, useState, useEffect, useRef, CSSProperties } from 'react';
import { SerializedStyles } from '@emotion/react';
import { FormControl, FormControlProps, FormHelperText, TextField } from '@material-ui/core';
import MaterialAutocomplete, {
  AutocompleteProps as MaterialAutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { nanoid as uuid } from 'nanoid';

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export type AutocompleteProps = Omit<
  MaterialAutocompleteProps<unknown, boolean | undefined, boolean | undefined, boolean | undefined>,
  'ref'
> & {
  'data-test'?: string;
  'data-node-id'?: string;
  'data-node-render-path'?: string;
} & Pick<FormControlProps, 'variant' | 'size'> & {
    label?: React.ReactNode;
    error?: React.ReactNode;
    optionItem: Array<React.ReactElement>;
    style?: CSSProperties;
    css?: SerializedStyles;
  } & {
    onChange?: (
      event: React.ChangeEvent<Record<string, unknown>>,
      value: unknown,
      reason?: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<unknown>,
    ) => void;
  };

export const Autocomplete = forwardRef<HTMLDivElement, AutocompleteProps>(
  (
    {
      disabled,
      label,
      className,
      error,
      variant,
      size,
      value,
      open,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      ...otherAutocompleteProps
    },
    ref,
  ) => {
    const previousValue = usePrevious(open);
    const [inputKey, setInputKey] = useState(() => uuid(8));

    useEffect(() => {
      // we check the moment when a component is changing
      // the controlled open state of Autocomplete to be
      // uncontrolled and update inputKey to force remounting on React component.
      if (
        (previousValue === undefined && open !== undefined) ||
        (open === undefined && previousValue !== undefined)
      ) {
        setInputKey(uuid(8));
      }
    }, [open, previousValue]);

    return (
      <FormControl
        key={inputKey}
        variant={variant}
        size={size}
        disabled={disabled}
        className={className}
        ref={ref}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      >
        <MaterialAutocomplete
          {...otherAutocompleteProps}
          size={size}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          open={open}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
);
