import { lazy, Suspense } from 'react';

import { FeedLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const SchoolNewsContent = lazy(() => import('../components/SchoolNews/SchoolNewsContent'));

export function SchoolNewsPage() {
  return (
    <FeedLayout
      sidebar={
        <Suspense fallback={<FeedSkeleton count={2} />}>
          <CommunitySidebar />
        </Suspense>
      }
    >
      <Suspense fallback={<FeedSkeleton />}>
        <SchoolNewsContent />
      </Suspense>
    </FeedLayout>
  );
}
