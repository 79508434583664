import { lazy, Suspense } from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const OrganizationDetailsContent = lazy(() => import('../components/OrganizationDetails'));

export function OrganizationDetailsPage() {
  return (
    <RouteLayout
      withMaxWidth
      content={
        <Suspense fallback={<FeedSkeleton />}>
          <OrganizationDetailsContent />
        </Suspense>
      }
      sidebar={
        <Suspense fallback={<FeedSkeleton count={2} />}>
          <CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />
        </Suspense>
      }
    />
  );
}
