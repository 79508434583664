import {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
} from 'react';

import { Container, Content, Footer } from './CardLayout.styles';

export interface CardLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;

  /**
   * Remove the border of the card.
   * @ticket https://github.com/jebelapp/jebel/issues/1525
   * @default false
   */
  borderless?: boolean;
}

export type CardLayoutComponent = ForwardRefExoticComponent<
  CardLayoutProps & RefAttributes<HTMLDivElement>
> & {
  Content: typeof Content;
  Footer: typeof Footer;
};

/**
 * Container for a post card.
 * @ticket https://github.com/jebelapp/jebel/issues/1525
 */
export const CardLayout = forwardRef<HTMLDivElement, CardLayoutProps>((props, ref) => {
  return (
    <Container ref={ref} {...props}>
      {props.children}
    </Container>
  );
}) as CardLayoutComponent;

/**
 * Content of a post card.
 */
CardLayout.Content = Content;

/**
 * Footer of a post card.
 */
CardLayout.Footer = Footer;
