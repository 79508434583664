import { Fragment, useState } from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, Link } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useAppAuth } from 'providers/useAppAuth';
import { Form, FormTextField, FormCheckbox, Button, Icon, Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { usePushNotifications, useQueryParams } from 'shared/hooks';
import { isValidEmail } from 'shared/utils/form';
import {
  AUTH_PROVIDER_FACEBOOK,
  AUTH_PROVIDER_GOOGLE,
  AUTH_PROVIDER_LINKEDIN,
} from 'features/auth/types';
import { recordError } from 'shared/utils/record';
import { useGlobalState } from 'providers';
import { shouldBePassword } from '@jebel/utils';

const oauthBlockCss = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(3)}px;
`;

const formBlockCss = theme => css`
  display: grid;
  grid-gap: ${theme.spacing(2)}px;
`;

const formInnerCss = css`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  align-content: center;
  justify-items: center;
`;

const dividerWrapCss = css`
  display: flex;
  position: relative;
`;

const dividerCss = theme => css`
  ::after {
    position: absolute;
    top: 50%;
    left: calc(50% + 20px);
    content: '';
    height: 1px;
    width: calc((100% - 40px) / 2);
    border: 1px solid #dadada;
  }
  ::before {
    position: absolute;
    top: 50%;
    right: calc(50% + 20px);
    content: '';
    height: 1px;
    width: calc((100% - 40px) / 2);
    border: 1px solid #dadada;
  }
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  color: ${theme.palette.text.secondary};
  font-weight: 500;
  width: 100%;
`;

const submitButtonCss = theme => css`
  background-color: ${theme.palette.secondary.light};
  font-size: 1rem;
  padding: ${theme.spacing(2.25)}px;
`;

const linkCss = theme => css`
  color: ${theme.palette.primary.icon};
`;

type FormData = {
  password: string;
  email: string;
  tosPrivacy: boolean;
};

interface Params {
  accessCodeHash: string;
  email: string;
  isBusiness: string;
}

export function EmailRegistrationPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [params] = useQueryParams<Params>();

  const { requestPermission } = usePushNotifications();
  const { register, socialSignIn } = useAppAuth();
  const { push: navigate } = useHistory();
  const { toasts, helpModal } = useGlobalState();

  const email = params.email;
  const isBusiness = params.isBusiness === 'true';

  const onGoogleSignIn = () => {
    socialSignIn(AUTH_PROVIDER_GOOGLE);
  };

  const onFacebookSignIn = () => {
    socialSignIn(AUTH_PROVIDER_FACEBOOK);
  };

  const onLinkedInSignIn = () => {
    socialSignIn(AUTH_PROVIDER_LINKEDIN);
  };

  const OAUTH_BUTTONS = [
    {
      color: '#0077B7',
      name: 'Register with Linkedin',
      icon: 'LinkedIn',
      onClick: () => onLinkedInSignIn(),
    },
    {
      color: '#2C4288',
      name: 'Register with Facebook',
      icon: 'Facebook',
      onClick: onFacebookSignIn,
    },
    {
      color: '#4285F4',
      name: 'Register with Google',
      icon: 'Google',
      onClick: onGoogleSignIn,
    },
  ];

  const onSubmit = async ({ email, password, tosPrivacy }: FormData): Promise<any> => {
    if (!tosPrivacy) {
      return;
    }

    try {
      await register({ email, password, accessCodeHash: params.accessCodeHash });
      // https://github.com/jebelapp/jebel/issues/1515
      await requestPermission();

      if (isBusiness) {
        return navigate(APP_URL.user.organizations.index, { isBusiness });
      }

      return navigate(APP_URL.user.settings.information);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        toasts.showError(err.message);
      }
    }
  };

  const handleOpenNeedHelp = () => {
    helpModal.open({
      subject: 'Need help creating my password',
      email: params.email,
    });
  };

  return (
    <Fragment>
      <Box display="grid" gridTemplateRows="auto auto">
        <Box display="flex" justifyContent="center" alignItems="end">
          <Typography align="center" variant="subtitle3">
            <strong>Welcome!</strong> Enter a password to complete registration
          </Typography>
        </Box>
      </Box>

      <Form
        oldCss={formBlockCss}
        initialValues={{ tosPrivacy: false, email, password: '' }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Fragment>
            <FormTextField
              disabled
              inputProps={{
                color: 'primary',
                label: 'Email Address',
                variant: 'outlined',
                disabled: true,
              }}
              fieldProps={{ name: 'email', validate: isValidEmail }}
            />
            <FormTextField
              inputProps={{
                color: 'primary',
                label: 'Enter your password',
                variant: 'outlined',
                type: showPassword ? 'text' : 'password',
                autoComplete: 'new-password',
                InputProps: {
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(showPass => !showPass);
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                },
              }}
              fieldProps={{ name: 'password', validate: shouldBePassword }}
            />
            <Box css={formInnerCss}>
              <FormCheckbox
                fieldProps={{ name: 'tosPrivacy' }}
                checkboxProps={{
                  label: '',
                  disabled: false,
                  indeterminate: false,
                  labelPlacement: 'end',
                }}
              />

              <Box>
                <Typography>
                  By continuing, you agree to the &nbsp;
                  <span css={linkCss}>
                    <a target="_blank" href="/terms-of-service.html">
                      Terms of Service
                    </a>
                  </span>
                  &nbsp; and &nbsp;
                  <span css={linkCss}>
                    <a target="_blank" href="/privacy-policy.html">
                      Privacy Policy
                    </a>
                  </span>
                  &nbsp;.
                </Typography>
              </Box>
            </Box>

            <Button
              loading={isSubmitting}
              css={submitButtonCss}
              variant="contained"
              type="submit"
              color="primary"
              disabled={!values.tosPrivacy}
            >
              Register
            </Button>

            <Box css={dividerWrapCss}>
              <Typography css={dividerCss} paragraph={false}>
                OR
              </Typography>
            </Box>

            <Box css={oauthBlockCss}>
              {OAUTH_BUTTONS.map(item => (
                <Fragment key={item.name}>
                  <Button
                    startIcon={<Icon name={item.icon} viewBox="0 0 24 24" />}
                    variant="contained"
                    onClick={item.onClick}
                    color="primary"
                  >
                    {item.name}
                  </Button>
                </Fragment>
              ))}
            </Box>

            <Link align="center" onClick={handleOpenNeedHelp}>
              Need Help?
            </Link>
          </Fragment>
        )}
      </Form>
    </Fragment>
  );
}
