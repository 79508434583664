import { Button, ButtonGroup } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { isEqual } from 'lodash';
import { Fragment, useMemo, useState } from 'react';

import { useCurrentUser, useModalState, useStreamChatReactions } from 'shared/hooks';
import { useHomeFeedCardData, useStreamActivityContext } from 'shared/features/posts/providers';
import { sendToSentry } from 'shared/utils/sentry';

import { useHomeFeedAuthor } from '../../useHomeFeedAuthor';
import { HomeFeedCardLikedModal } from './HomeFeedCardLikedModal';

interface Reaction {
  id: string;
  user_id: string;
}

export function HomeFeedCardLikeButton() {
  const { data: activity } = useStreamActivityContext();
  const { onLikeAdd, onReactionRemove: onLikeRemove } = useStreamChatReactions();

  const [reactions, setReactions] = useState<Reaction[]>(activity?.latest_reactions?.like ?? []);

  const { user } = useCurrentUser();
  const { data: author } = useHomeFeedAuthor();

  const {
    show: showWhoLikes,
    haveBeenOpened: haveWhoLikesBeenOpened,
    open: openWhoLikes,
    close: closeWhoLikes,
  } = useModalState();

  const post = useHomeFeedCardData();

  const currentUserReaction = useMemo(() => {
    return reactions.find(reaction => reaction.user_id === user?.id);
  }, [user, reactions]);

  const hasCurrentUserLike = Boolean(currentUserReaction);

  const removeReaction = async (selected: typeof reactions[number]) => {
    const prev = [...reactions];

    // Change the state optimistically before the response arrives.
    setReactions(reactions => {
      return reactions.filter(reaction => !isEqual(reaction.id, selected.id));
    });

    try {
      await onLikeRemove(selected.id);
    } catch (err) {
      setReactions(prev);
      sendToSentry(err);
    }
  };

  const attachReaction = async () => {
    const prev = [...reactions];

    if (!activity?.id) {
      return;
    }

    const params: Parameters<typeof onLikeAdd>[0] = {
      activityId: activity.id,
      foreignId: post.id as string,
      parentEntityAuthorId: author?.id ?? undefined,
    };

    // Change the state optimistically before the response arrives.
    setReactions(prev => [...prev, { id: String(), user_id: String(user?.id) }]);

    try {
      const response = await onLikeAdd(params);
      setReactions(response ?? []);
    } catch (err) {
      setReactions(prev);
      sendToSentry(err);
    }
  };

  const handleToggleLike = async () => {
    if (currentUserReaction?.id) {
      await removeReaction(currentUserReaction);
      return;
    }

    await attachReaction();
  };

  const handleOpenWhoLikes = () => {
    openWhoLikes();
  };

  return (
    <Fragment>
      <ButtonGroup>
        <Button
          variant="text"
          size="small"
          onClick={handleToggleLike}
          color={hasCurrentUserLike ? 'secondary' : 'primary'}
        >
          <ThumbUp fontSize="small" />
        </Button>

        <Button
          size="small"
          variant="text"
          color={hasCurrentUserLike ? 'secondary' : 'primary'}
          onClick={handleOpenWhoLikes}
        >
          {reactions.length}
        </Button>
      </ButtonGroup>

      <HomeFeedCardLikedModal
        show={showWhoLikes}
        haveBeenOpened={haveWhoLikesBeenOpened}
        onClose={closeWhoLikes}
      />
    </Fragment>
  );
}
