import React from 'react';
import { useQuery } from '@apollo/client';

import { UsersFilter } from 'features/explorer/types';
import { PAGE_SIZE, USER_SORT_BY_NAME_INFO, USER_SORT_INFO } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import { isSortOptionMatchesQuery } from 'shared/features/search/utils';
import {
  UserSearchQuery,
  UserSearchQueryVariables,
  UserInfoFragment,
} from 'shared/graphql/__generated__';
import { getJobSeekerSearchFilter, getUserSearchFilter } from 'shared/utils/search';
import { useResponsive } from 'shared/hooks';

import { USER_SEARCH_QUERY } from '../queries';

const transformQueryData = (listData: UserSearchQuery | undefined) => {
  const jobSeekerItems = listData?.users.items || [];

  return { jobSeekerItems };
};

export const useUserSearch = (
  localZipQuery?: string,
  options?: {
    defaultSearch?: boolean;
    extraFilter: UsersFilter;
  },
) => {
  const usersRef = React.useRef<UserInfoFragment[]>([]);
  const { isUserTyping, sortOption, searchQuery, zipQuery } = useSearchContext();
  const { isMobile } = useResponsive();
  const filter = React.useMemo(
    () =>
      !options?.defaultSearch
        ? getJobSeekerSearchFilter(searchQuery)
        : getUserSearchFilter({ searchQuery, currentFilter: options?.extraFilter }),
    [searchQuery, options],
  );

  const zipFilter = React.useMemo(
    () =>
      isMobile
        ? {
            userPreferences: localZipQuery
              ? {
                  address: {
                    zip: { equals: localZipQuery },
                  },
                }
              : {},
          }
        : {
            userPreferences: zipQuery
              ? {
                  address: {
                    zip: { equals: zipQuery },
                  },
                }
              : {},
          },
    [isMobile, localZipQuery, zipQuery],
  );

  const onQueryCompleted = React.useCallback((data: UserSearchQuery) => {
    usersRef.current = data.users.items;
  }, []);

  const {
    data: listData,
    loading: listLoading,
    refetch: refetchList,
    fetchMore: rawFetchMore,
  } = useQuery<UserSearchQuery, UserSearchQueryVariables>(USER_SEARCH_QUERY, {
    nextFetchPolicy: 'cache-first',
    onCompleted: onQueryCompleted,
    variables: {
      first: PAGE_SIZE,
      filter: {
        ...filter,
        ...zipFilter,
      },
      sort:
        isSortOptionMatchesQuery(sortOption, USER_SORT_INFO) ||
        isSortOptionMatchesQuery(sortOption, USER_SORT_BY_NAME_INFO)
          ? sortOption
          : undefined,
    },
  });

  const returnData = React.useMemo(() => transformQueryData(listData), [listData]);

  const fetchMore = React.useCallback(
    async (variables: UserSearchQueryVariables) => {
      const { data } = await rawFetchMore({ variables });

      onQueryCompleted(data);
    },
    [onQueryCompleted, rawFetchMore],
  );

  const refetch = React.useCallback(() => {
    const refetchListPromise = refetchList({ first: usersRef.current.length || 1 });

    return refetchListPromise;
  }, [refetchList]);

  return {
    ...returnData,
    jobSeekerLoading: (listLoading && !listData) || (!isMobile && isUserTyping),
    fetchMore,
    refetch,
  };
};
