import { uniqBy } from 'ramda';
import { useMemo } from 'react';

import { useSearchContext } from 'shared/features/search';
import { getUserSearchFilter } from 'shared/utils/search';
import { UserSearchQuery, useUserSearchQuery } from 'shared/graphql';
import { PAGE_SIZE } from 'shared/constants';

import { UsersFilter } from '../types';
import { useSchoolConfiguration } from 'shared/hooks';

interface Options {
  first?: number;
}

/** Explore all the users using the same API as **Explorer Nation** page. */
export function useExplorerUser(options?: Options) {
  const { data: school } = useSchoolConfiguration();

  const {
    sortOption,
    searchQuery,
    filter: currentFilter,
    zipQuery: zipCode,
  } = useSearchContext<UsersFilter>();

  const filter = useMemo(() => {
    return getUserSearchFilter({ searchQuery, currentFilter, zipCode, school });
  }, [searchQuery, zipCode, currentFilter, school]);

  const {
    data: response,
    loading,
    fetchMore,
  } = useUserSearchQuery({
    nextFetchPolicy: 'cache-first',
    variables: {
      filter,
      first: options?.first ?? PAGE_SIZE,
      sort: [sortOption],
    },
  });

  const next = async () => {
    await fetchMore({
      variables: {
        first: PAGE_SIZE,
        skip: data.length,
      },

      updateQuery(prev, { fetchMoreResult: curr }) {
        const oldest = prev?.users.items ?? [];
        const newest = curr?.users?.items ?? [];

        const count = curr?.users.count ?? 0;
        /** Merge between `oldest` items and `newest` reduced by ID. */
        const items = uniqBy(user => user.id, [...oldest, ...newest]);

        const data: UserSearchQuery = {
          ...curr,

          users: {
            ...prev.users,

            count,
            items,
          },
        };

        return data;
      },
    });
  };

  const data = response?.users.items ?? [];
  const count = response?.users.count ?? 0;

  return {
    data,
    count,

    loading: data.length === 0 && loading,
    refreshing: data.length > 0 && loading,
    hasMore: count > data.length,

    fetchMore: next,
  };
}
