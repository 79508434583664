import { createFilterBuilder } from '@jebel/utils';
import { GroupAdminStatus, GroupStatus } from '@jebel/constants';

import {
  GroupFilter,
  SchoolConfigurationKeyFilter,
  UserFilter,
  UserKeyFilter,
} from 'shared/graphql';

interface Options {
  initial?: GroupFilter | undefined | null;

  /**
   * Filter by the selected user.
   */
  user: UserKeyFilter;

  /**
   * Filter by the selected school.
   */
  school?: SchoolConfigurationKeyFilter;

  /**
   * Filter only active groups.
   * @default true
   */
  onlyActive?: boolean;
}

/**
 * Creates a filter for user groups based on the provided options.
 *
 * @param options - The options to configure the filter.
 * @param options.onlyActive - A boolean indicating whether to filter only active groups. Defaults to `true`.
 * @param options.initial - The initial filter configuration.
 * @param options.school - An optional school object to filter groups by school ID.
 * @param options.user - An optional user object to filter groups by user ID.
 *
 * @returns A filter object built based on the provided options.
 */
export function createUserGroupsFilter(options: Options) {
  const onlyActive = options.onlyActive ?? true;

  const filter = createFilterBuilder<GroupFilter>(options.initial);

  if (options.school) {
    // https://github.com/jebelapp/jebel/issues/1570
    filter.and({ school: { id: { equals: options.school.id } } });
  }

  if (options.user) {
    const filterUser: UserFilter = { id: { equals: options.user?.id } };

    filter.and({
      OR: [
        { createdBy: filterUser },
        {
          groupToJoin: {
            // https://github.com/8base-services/jebel/issues/1435
            some: { status: { equals: GroupStatus.Active }, user: filterUser },
          },
        },
        {
          groupAdminPreferences: {
            // https://github.com/8base-services/jebel/issues/1435
            some: { status: { equals: GroupAdminStatus.Active }, user: filterUser },
          },
        },
      ],
    });
  }

  if (onlyActive) {
    filter.and({ status: { equals: GroupStatus.Active } });
  }

  return filter.build();
}
