import { Skeleton } from '@material-ui/lab';
import { Fragment, ReactNode } from 'react';

interface Props {
  /**
   * Whether the loading overlay should be displayed.
   * @default false
   */
  loading?: boolean;

  /**
   * The content to display when loading is true.
   * @default 'Loading...'
   */
  overlay?: ReactNode;

  /**
   * The content to display when loading is false.
   */
  children: ReactNode;
}

/**
 * A component that conditionally renders a loading overlay or its children based on the loading state.
 *
 * @param {boolean} loading - A flag indicating whether the loading overlay should be displayed.
 * @param overlay - An optional custom overlay to display when loading.
 * @param children - The content to display when not loading.
 * @returns The children if not loading, otherwise the overlay or a default loading message.
 */
export function LoadingOverlay({ loading = false, overlay, children }: Props) {
  if (!loading) {
    return <Fragment>{children}</Fragment>;
  }

  if (overlay) {
    return <Fragment>{overlay}</Fragment>;
  }

  return <Skeleton>{children}</Skeleton>;
}
