import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ExplorerUserList } from 'features/explorer/components';
import { useExplorerUser } from 'features/explorer/hooks';
import { ROUTES } from 'shared/constants';
import { useQueryParams } from 'shared/hooks';

import { SearchSection } from '../SearchSection';

interface Props {
  canFetchMore?: boolean;
}

const PREVIEW_COUNT = 3;

export function SearchPagePeople(props: Props) {
  const [params] = useQueryParams();

  const { push: navigate } = useHistory();

  const { data, count, loading, refreshing, hasMore, fetchMore } = useExplorerUser({
    first: props.canFetchMore ? undefined : PREVIEW_COUNT,
  });

  const title = useMemo(() => `People (${count})`, [count]);

  const showMore = () => {
    const search = new URLSearchParams(params as Record<string, string>);
    const route = `${ROUTES.user.search.people}?${search}`;

    navigate(route);
  };

  return (
    <SearchSection
      title={title}
      loading={loading}
      fetching={refreshing}
      hasMore={hasMore}
      showMoreLabel="Show all people"
      canShowMore={props.canFetchMore}
      onShowMore={showMore}
      onFetchMore={fetchMore}
    >
      <ExplorerUserList usersList={data} />
    </SearchSection>
  );
}
