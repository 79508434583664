import { useMemo } from 'react';
import { People } from '@material-ui/icons';

import { GroupInfoFragment } from 'shared/graphql';
import { generateGroupRoute } from 'shared/routes';

import { AuthorAvatar, AuthorAvatarProps } from './AuthorAvatar';

interface Props extends Omit<AuthorAvatarProps, 'source' | 'name' | 'route'> {
  group: GroupInfoFragment | undefined | null;
}

export function GroupAvatar({ group, ...props }: Props) {
  const source = group?.logo?.downloadUrl ?? undefined;
  const name = group?.title ?? '(Group)';

  const route = useMemo(() => {
    return generateGroupRoute(group?.id);
  }, [group]);

  return (
    <AuthorAvatar
      {...props}
      source={source}
      name={name}
      route={route}
      disabled={!group || props.disabled}
    >
      <People fontSize="inherit" />
    </AuthorAvatar>
  );
}
