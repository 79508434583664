import { generatePath } from 'react-router-dom';

import { ROUTES } from 'shared/constants';

/**
 * Generates the user profile route based on the provided user ID.
 * If the ID is not provided, it returns the default user explorer index route.
 * If the ID is provided, it generates the user explorer profile route with the ID as a parameter.
 * @param id - The user ID.
 * @returns The generated user profile route.
 */
export function generateUserProfileRoute(id: string | undefined | null) {
  if (!id) {
    return ROUTES.user.explorer.index;
  }

  return generatePath(ROUTES.user.explorer.profile, { id });
}

/**
 * Generates a route for a group based on the provided group ID.
 * If no ID is provided, the index route for user groups is returned.
 * If an ID is provided, the details route for the corresponding group is returned.
 * @param id - The ID of the group.
 * @returns The generated route.
 */
export function generateGroupRoute(id: string | undefined | null) {
  if (!id) {
    return ROUTES.user.groups.index;
  }

  return generatePath(ROUTES.user.groups.details, { id });
}

/**
 * Generates a route for an organization based on the provided ID.
 * If no ID is provided, it returns the index route for user organizations.
 * If an ID is provided, it generates a route for the organization with the given ID.
 * @param id - The ID of the organization.
 * @returns The generated route for the organization.
 */
export function generateOrganizationRoute(id: string | undefined | null) {
  if (!id) {
    return ROUTES.user.organizations.index;
  }

  return generatePath(ROUTES.user.organizations.organization, { id });
}

const ADVERTISING_DOMAIN = process.env.REACT_APP_ADVERTISING_DOMAIN;

/**
 * Generates a route for an advertising business profile based on the provided ID.
 */
export function generateAdvertisingBusinessProfileRoute(id?: string | undefined | null) {
  const pathname = id
    ? `/business-details/business-information?fromCommunity&organization=${id}`
    : '/dashboard?fromCommunity';

  const url = new URL(pathname, ADVERTISING_DOMAIN);

  return url.toString();
}
