import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { CardLayout } from 'shared/components/layouts';
import { OrganizationAvatar, OrganizationNameLink, SchoolAvatar } from 'shared/components/symbols';

// Container

export const Container = styled(CardLayout)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  overflow-x: hidden;
  user-select: none;
`;

// Divided

export const Divided = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

// Logo

export const Logo = styled(OrganizationAvatar)`
  --size: 5rem;

  width: var(--size);
  height: var(--size);
  cursor: pointer;
`;

Logo.defaultProps = {
  variant: 'rounded',
};

// SupporterLogo

export const SUPPORTER_ICON_SIZE = 18;

export const SupporterLogo = styled(SchoolAvatar)`
  --size: ${SUPPORTER_ICON_SIZE}px;

  width: var(--size);
  height: var(--size);
  font-size: calc(var(--size) * 0.6);
`;

SupporterLogo.defaultProps = {
  size: SUPPORTER_ICON_SIZE,
};

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 0.25rem;
  flex: 1;
`;

// Header

export const Header = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto max-content;
  gap: 1rem;
`;

// HeaderBanner

export const HeaderBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

// Title

export const Title = styled(OrganizationNameLink)`
  font-weight: bold;
`;

// Location

export const Location = styled(Typography)`
  color: ${p => p.theme.palette.grey[500]};
`;

Location.defaultProps = {
  variant: 'body2',
};

// Count

export const Count = styled.span`
  color: ${p => p.theme.palette.grey[500]};
`;

// Description

export const Description = styled.span`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
