import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { Typography } from 'shared/components/ui';
import { OrganizationItemFragment } from 'shared/graphql';
import { OrganizationAvatar, OrganizationNameLink } from 'shared/components/symbols';

const ORGANIZATION_AVATAR_SIZE = 60;

const containerCSS = css`
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow-x: hidden;
`;

const avatarCSS = css`
  width: ${ORGANIZATION_AVATAR_SIZE}px;
  height: ${ORGANIZATION_AVATAR_SIZE}px;
`;

interface Props {
  organization: OrganizationItemFragment;
}

export function OrganizationSidebarItem({ organization }: Props) {
  return (
    <Box css={containerCSS}>
      <OrganizationAvatar css={avatarCSS} variant="square" organization={organization} />

      <Box display="flex" flexDirection="column" overflow="hidden">
        <OrganizationNameLink organization={organization} />

        <Typography variant="body2" color="textSecondary" withEllipsis>
          {organization?.description}
        </Typography>
      </Box>
    </Box>
  );
}
