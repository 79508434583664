import styled from '@emotion/styled';
import { CardLayout } from 'shared/components/layouts';

// Container

export const Container = styled(CardLayout.Content)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Reactions

export const Reactions = styled.div`
  min-height: 2rem;
  display: flex;
  gap: 0.5rem;
`;
