import { useCallback, useMemo } from 'react';
import { useTheme, css } from '@emotion/react';
import { Box, Chip, Divider, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Separator } from 'shared/components/symbols';
import { Typography, Icon } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { CareerInfoFragment } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { extractYear } from 'shared/utils/date';

import { ProfilePreviewExperience } from './ProfilePreviewExperience';

const USER_AVATAR_SIZE = 90;

const userAvatarCSS = css`
  width: ${USER_AVATAR_SIZE}px;
  height: ${USER_AVATAR_SIZE}px;
`;

const labelDefaultStyleCSS = (theme: any) => css`
  font-size: ${theme.typography.fontSize - 2}px;
  color: #ffffff;
  border-radius: ${theme.spacing(2)}px;
`;

const userAffiliationLabelCSS = (theme: any) => css`
  ${labelDefaultStyleCSS(theme)}
  background-color: ${theme.palette.primary.light};
  border-radius: 4px;
`;

const userJobSeekingLabelCSS = (theme: any) => css`
  ${labelDefaultStyleCSS(theme)}
  background-color: ${theme.palette.info.main};
  margin-left: ${theme.spacing(2)}px;
`;

const userClassCSS = (theme: any) => css`
  color: ${theme.palette.text.secondary};
`;

const BOTTOM_BUTTONS_PADDING = 10;

const messageButtonCss = css`
  text-transform: capitalize;
  text-align: center;
`;

const commonButtonsCSS = css`
  padding-left: ${BOTTOM_BUTTONS_PADDING}px;
  padding-right: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.primary.dark};
  margin-right: ${BOTTOM_BUTTONS_PADDING}px;
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

const MAX_ABOUT_INFO_LENGTH = 250;

type ProfilePreviewModalProps = {
  onModalClose: () => void;
  adminCreator: CareerInfoFragment['createdBy'];
  onJobDetailsClose: () => void;
};

export const ProfilePreviewModal = ({
  onModalClose,
  adminCreator,
  onJobDetailsClose,
}: ProfilePreviewModalProps) => {
  const theme: any = useTheme();
  onJobDetailsClose();
  const history = useHistory();

  const userId = adminCreator?.id;
  const adminCreatorName = formatUserName(adminCreator);
  const professionalDesignations = adminCreator?.professionalDesignations;
  const graduationClass = extractYear(adminCreator?.graduatingYear);

  const onMessageClick = useCallback(
    user => {
      history.push(APP_URL.user.mail.inbox, {
        isModalOpen: true,
        messageType: 'personal',
        members: [user],
      });
    },
    [history],
  );
  const onViewResumeClick = useCallback(() => {
    history.push(`${APP_URL.user.explorer.index}/profile/${userId}/posts`);
  }, [history, userId]);

  const professionalTitle = useMemo(() => {
    const [latestExperience] = adminCreator?.jobExperience?.items ?? [];

    if (!latestExperience) {
      return adminCreator?.currentIndustry;
    }

    const companyName = latestExperience.companyName;
    return `${companyName}, ${adminCreator?.currentIndustry}`;
  }, [adminCreator]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box display="flex" justifyContent="space-between" pb={2}>
        <Box display="flex" justifyContent="start">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
            mr={1}
          >
            <Avatar
              css={userAvatarCSS}
              srcSet={processFilestackUrlSrcSet(adminCreator?.avatar?.downloadUrl || '', {
                compress: true,
                resize: {
                  width: USER_AVATAR_SIZE,
                  height: USER_AVATAR_SIZE,
                  fit: 'crop',
                },
              })}
              src={adminCreator?.avatar?.downloadUrl || ''}
              variant="circular"
            />
          </Box>
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle4" align="inherit" color="primary">
                {adminCreatorName}
              </Typography>
              {adminCreator?.isSeekingEmployment && (
                <Chip css={userJobSeekingLabelCSS} label="Seeking employment" size="small" />
              )}
            </Box>
            <Box display="flex" justifyContent="start" alignItems="center">
              {adminCreator?.affiliation && (
                <Chip
                  css={userAffiliationLabelCSS}
                  disabled={false}
                  label={adminCreator?.affiliation}
                  size="small"
                />
              )}
              <Box ml={1}>
                {graduationClass && (
                  <Separator diameter="4px" color={theme.palette.text.secondary} />
                )}
              </Box>
              <Box ml={1}>
                <Typography css={userClassCSS} variant="body1">
                  {graduationClass && `Class of ${graduationClass}`}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="start" flexDirection="row">
              <Typography css={userClassCSS} variant="inherit" align="inherit" paragraph={false}>
                {professionalTitle}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="flex-end">
          <Box display="flex" justifyContent="end" alignItems="end">
            <Button
              css={messageButtonCss}
              color="secondary"
              startIcon={<Icon name="QuestionAnswer" fontSize="small" />}
              size="small"
              onClick={() => onMessageClick(adminCreator)}
            >
              message
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        alignItems="start"
        pt={2}
        pb={2}
      >
        <Typography variant="subtitle5" align="inherit" color="primary">
          About me
        </Typography>
        <Typography variant="inherit" align="inherit" color="inherit">
          {professionalDesignations && professionalDesignations.length > MAX_ABOUT_INFO_LENGTH
            ? professionalDesignations.slice(0, MAX_ABOUT_INFO_LENGTH).concat('...')
            : professionalDesignations}
        </Typography>
      </Box>
      <Divider />
      <ProfilePreviewExperience adminCreator={adminCreator} />
      <Box display="flex" justifyContent="end" pt={2} pb={1}>
        <Button
          css={cancelButtonCSS}
          color={theme.palette.primary.light}
          size="small"
          onClick={onModalClose}
        >
          CANCEL
        </Button>
        <Button
          css={submitButtonCSS}
          disableElevation
          variant="contained"
          size="small"
          onClick={onViewResumeClick}
        >
          VIEW RESUME
        </Button>
      </Box>
    </Box>
  );
};
