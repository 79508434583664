import { ReactNode, useMemo } from 'react';
import { FormControl, FormHelperText, SwitchProps } from '@material-ui/core';
import { FieldValidator, useField, useFormikContext } from 'formik';

import { getIsInvalid } from 'shared/components/ui';
import { SwitchToggle } from 'shared/components/ui/SwitchToggle';
import { Control } from './SwitchField.styles';

export interface SwitchFieldProps extends Omit<SwitchProps, 'size'> {
  name: string;
  validate?: FieldValidator;

  label?: string;
  helperText?: ReactNode;
}

export function SwitchField(props: SwitchFieldProps) {
  const form = useFormikContext();

  const [field, meta, helpers] = useField<boolean>({
    name: props.name,
    validate: props.validate,
  });

  const showError = useMemo(() => getIsInvalid({ meta, form }), [meta, form]);

  const message = useMemo(() => {
    return showError ? meta.error : props.helperText;
  }, [showError, meta.error, props.helperText]);

  const handleChange = (_event: unknown, value: boolean) => {
    helpers.setValue(value);
  };

  return (
    <FormControl>
      <Control
        control={
          <SwitchToggle {...props} size="small" checked={field.value} onChange={handleChange} />
        }
        label={props.label}
      />

      {Boolean(message) && <FormHelperText error={showError}>{message}</FormHelperText>}
    </FormControl>
  );
}
