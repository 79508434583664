import styled from '@emotion/styled';
import { IconButton as IconButtonBase } from '@material-ui/core';

// Container

export const Container = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${p => p.theme.palette.primary.side};
  color: ${p => p.theme.palette.primary.sideFont};
  padding: 0 1.5rem;
  gap: 1rem;
  box-shadow: 0 0.5rem 1rem 0.01rem rgba(0, 0, 0, 0.05);
`;

// SchoolContainer

export const SchoolContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow-x: hidden;
`;

// SchoolName

export const SchoolName = styled.span`
  font-size: ${p => p.theme.typography.body2.fontSize};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// IconButton

export const IconButton = styled(IconButtonBase)`
  color: ${p => p.theme.palette.primary.sideFont};
`;
