import React, { forwardRef, useMemo } from 'react';
import {
  KeyboardTimePicker as MuiKeyboardTimePicker,
  KeyboardTimePickerProps as MuiKeyboardTimePickerProps,
} from '@material-ui/pickers';

import { ComponentDataProps } from '../../types';

export type KeyboardTimePickerProps = ComponentDataProps &
  Omit<MuiKeyboardTimePickerProps, 'value' | 'initialFocusedTime'> & {
    initialFocusedTime?: string;
    value: string;
    css?: string;
  };

export const KeyboardTimePicker = forwardRef<HTMLDivElement, KeyboardTimePickerProps>(
  (
    {
      onChange,
      value,
      initialFocusedTime,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      ...props
    },
    ref,
  ) => {
    const InputProps = useMemo(
      () => ({ ref, style: props.style, css: props.css, ...props.InputProps }),
      [props.InputProps, props.style, props.css, ref],
    );

    return (
      <MuiKeyboardTimePicker
        InputProps={InputProps}
        onChange={onChange}
        value={value}
        format="hh:mm"
        {...props}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      />
    );
  },
);
