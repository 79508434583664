import { Fragment, useCallback } from 'react';
import { Theme, css } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { RouterLink } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { GroupDetailsFragment, OrganizationItemFragment } from 'shared/graphql';
import { buildUrl } from 'shared/routes';
import { OrganizationSidebarItem } from 'features/organizations/components';
import { Groups, Work } from '@mui/icons-material';
import { GroupAvatar } from 'shared/components/symbols';
import { CardLayout } from 'shared/components/layouts';

const ITEMS_COUNT = 3;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const titleCss = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const sectionCSS = css``;

const groupItemCss = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const groupNameCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExplorerNationProfileSidebar = ({
  groups,
  organizations,
}: {
  groups: GroupDetailsFragment[] | undefined;
  organizations: OrganizationItemFragment[] | undefined;
}) => {
  const history = useHistory();

  const onGroupOpen = useCallback(
    group => () => {
      if (!group?.id) {
        return;
      }

      const url = buildUrl(APP_URL.user.groups.details, {
        pathParams: { id: group?.id },
      });

      history.push(url);
    },
    [history],
  );

  return (
    <Box css={containerCSS}>
      <CardLayout css={sectionCSS}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="primary" css={titleCss}>
            <Groups color="secondary" />
            <span>My Groups</span>
          </Typography>

          {groups && groups.length > 3 && (
            <RouterLink color="secondary" absolute={false} to={APP_URL.user.groups.index}>
              View All
            </RouterLink>
          )}
        </Box>

        {groups?.slice(0, ITEMS_COUNT).map(group => {
          return (
            <Box
              key={group.id}
              onClick={onGroupOpen(group)}
              display="flex"
              alignItems="center"
              mb={1}
              css={groupItemCss}
            >
              <GroupAvatar group={group} />

              <Typography variant="body1" css={groupNameCss}>
                {group?.title}
              </Typography>
            </Box>
          );
        })}

        {groups?.length === 0 && (
          <Typography color="textSecondary" align="center">
            No groups joined.
          </Typography>
        )}
      </CardLayout>

      <CardLayout css={sectionCSS}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="primary" css={titleCss}>
            <Work color="secondary" />
            <span>Related Organizations</span>
          </Typography>

          {organizations && organizations.length > 3 && (
            <RouterLink color="secondary" absolute={false} to={APP_URL.user.organizations.index}>
              View All
            </RouterLink>
          )}
        </Box>

        {organizations?.slice(0, ITEMS_COUNT).map(organization => {
          return <OrganizationSidebarItem key={organization.id} organization={organization} />;
        })}

        {organizations?.length === 0 && (
          <Typography color="textSecondary" align="center">
            No organizations related.
          </Typography>
        )}
      </CardLayout>
    </Box>
  );
};
