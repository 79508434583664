import React from 'react';
import { MutationHookOptions, useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  ContentCurationPostDeleteMutation,
  ContentCurationPostDeleteMutationVariables,
  GroupPostDeleteInput,
  useGroupPostDeleteMutation,
} from 'shared/graphql';

import { CONTENT_CURATION_POST_DELETE_MUTATION } from '../queries';

export const useContentCurationPostDelete = () => {
  const [postDelete, { loading, data, ...rest }] = useMutation<
    ContentCurationPostDeleteMutation,
    ContentCurationPostDeleteMutationVariables
  >(CONTENT_CURATION_POST_DELETE_MUTATION, {
    refetchQueries: [
      'UnreadSidebarElementsList',
      'FullContentReportsList',
      'ContentCurationCount',
      // Refresh the feed
      'HomeFeedItems',
    ],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Post has been deleted.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Post hasn't been deleted. Try again.`,
    },
  });

  const onPostDelete = React.useCallback(
    (
      data: ContentCurationPostDeleteMutationVariables['data'],
      mutationOptions?: MutationHookOptions<
        ContentCurationPostDeleteMutation,
        ContentCurationPostDeleteMutationVariables
      >,
    ) => {
      return postDelete({
        variables: {
          data,
        },
        ...mutationOptions,
      });
    },
    [postDelete],
  );

  return { onPostDelete, loading, data, ...rest };
};

export const useGroupPostDelete = () => {
  const [groupPostDelete, { loading, data, ...rest }] = useGroupPostDeleteMutation({
    refetchQueries: ['FullContentReportsList', 'ContentCurationCount', 'HomeFeedItems'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Group Post has been deleted.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Group Post hasn't been deleted. Try again.`,
    },
  });

  const onGroupPostDelete = async (data: GroupPostDeleteInput) => {
    if (!window.confirm('Are you sure you want delete the post?')) {
      return;
    }

    await groupPostDelete({
      variables: { data: { id: data.id, force: data.force } },
    });
  };

  return { onGroupPostDelete, loading, data, ...rest };
};
