import { useLayoutEffect } from 'react';
import { useToast } from './useToast';

export interface UsePreventBrowserCloseOptions {
  /**
   * Whether to prevent the browser from closing.
   */
  prevent: boolean;

  /**
   * The message to display when the browser is prevented from closing.
   * @default `Please do not close this tab while process is in progress.`
   */
  preventMessage?: string;
}

const PREVENT_MESSAGE = `Please do not close this tab while process is in progress.`;

/**
 * Prevents the browser from closing while a process is in progress.
 * @param options - The options for preventing the browser from closing.
 */
export function usePreventBrowserClose(options: UsePreventBrowserCloseOptions) {
  const { showWarning } = useToast();

  const preventMessage = options.preventMessage || PREVENT_MESSAGE;

  useLayoutEffect(() => {
    function preventClose(event: BeforeUnloadEvent) {
      if (options.prevent) {
        event.preventDefault();
        event.returnValue = options.preventMessage;

        showWarning(preventMessage);
        return options.preventMessage;
      }
    }

    window.addEventListener('beforeunload', preventClose);

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, [options.prevent]);
}
