import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { GroupLeaveMutation, GroupLeaveMutationVariables } from 'shared/graphql/__generated__';
import { useCurrentUser } from 'shared/hooks';

import { GROUP_LEAVE_MUTATION } from '../queries';

export const useGroupLeave = (groupTitle: string) => {
  const { userId } = useCurrentUser();
  const [groupLeave, { loading }] = useMutation<GroupLeaveMutation, GroupLeaveMutationVariables>(
    GROUP_LEAVE_MUTATION,
    {
      refetchQueries: ['GroupSearch'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: `Success: You are no longer a member of the ${groupTitle} group. You may request to re-join the group at any time.`,
        [SNACKBAR_ERROR_MESSAGE]: `Error: You haven't left the group. Something went wrong. Try again.`,
      },
    },
  );

  const onGroupLeave = React.useCallback(
    (id: string) => {
      if (!userId) {
        return;
      }

      return groupLeave({
        variables: {
          data: {
            id,
            members: {
              disconnect: [
                {
                  id: userId,
                },
              ],
            },
          },
        },
      });
    },
    [userId, groupLeave],
  );

  return {
    onGroupLeave,
    loading,
  };
};
