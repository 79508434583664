import { createFilterBuilder } from '@jebel/utils';
import { GroupStatus } from '@jebel/constants';

import { GroupFilter, SchoolConfigurationKeyFilter } from 'shared/graphql';

import { createUserFilter } from './createUserFilter';

interface Options {
  initial?: GroupFilter;

  /**
   * Filter by the selected school.
   */
  school?: SchoolConfigurationKeyFilter;

  search?: string;

  /**
   * Filter only active groups.
   * @default true
   */
  onlyActive?: boolean;
}

export function createGroupFilter(options?: Options) {
  const onlyActive = options?.onlyActive ?? true;

  const filter = createFilterBuilder<GroupFilter>(options?.initial);

  if (options?.school) {
    // https://github.com/jebelapp/jebel/issues/1570
    filter.and({ school: { id: { equals: options.school.id } } });
  }

  if (onlyActive) {
    filter.and({ status: { equals: GroupStatus.Active } });
  }

  if (options?.search) {
    const userFilter = createUserFilter({ search: options.search });
    const searchFilter = createFilterBuilder<GroupFilter>();

    searchFilter
      .or({ title: { contains: options.search } })
      .or({ description: { contains: options.search } })
      .or({ members: { some: userFilter } });

    filter.and(searchFilter);
  }

  return filter.build();
}
