import { createContext } from 'react';

import { SORT } from '@jebel/constants';

import { SELECT_OPTION_ALL } from 'shared/constants';

import { SearchContextValue } from '../types';

export const DEFAULT_CONTEXT_VALUE: SearchContextValue = {
  searchQuery: '',
  setSearchQuery: () => {},
  isUserTyping: false,
  setIsUserTyping: () => {},
  displayList: true,
  setSortOption: () => {},
  setDisplayList: () => {},
  sortOption: {
    createdAt: SORT.desc,
  },
  filter: SELECT_OPTION_ALL,
  setFilter: () => {},
  dateFilter: null,
  setDateFilter: () => {},
  zipQuery: '',
  setZipQuery: () => {},
  zipFilter: null,
  setZipFilter: () => {},
  isFilterModalOpen: false,
  setIsFilterModalOpen: () => {},
  openFilterModal: () => {},
  closeFilterModal: () => {},
};

export const SearchContext = createContext<SearchContextValue>(DEFAULT_CONTEXT_VALUE);
