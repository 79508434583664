import { lazy, Suspense } from 'react';

import { FeedLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { SearchProvider } from 'shared/features/search';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const GroupsContent = lazy(() => import('../components/GroupsContent'));

export function GroupsPage() {
  return (
    <SearchProvider>
      <FeedLayout
        sidebar={
          <Suspense fallback={<FeedSkeleton count={2} />}>
            <CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />
          </Suspense>
        }
      >
        <Suspense fallback={<FeedSkeleton />}>
          <GroupsContent />
        </Suspense>
      </FeedLayout>
    </SearchProvider>
  );
}
