import { generatePath, useHistory } from 'react-router-dom';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Business } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

import { CareerInfoFragment } from 'shared/graphql';
import { ROUTES } from 'shared/constants';
import {
  useCurrentUser,
  useCurrentUserOrganizations,
  useCurrentUserSavedOffers,
  useResponsive,
} from 'shared/hooks';
import { useInboxContext } from 'providers/InboxProvider';
import { useHasJobOfferExpired } from 'shared/features/career';
import { useJobOfferSave } from 'features/career/hooks';
import { getOrganizationAddress } from 'shared/utils/address';
import { recordError } from 'shared/utils/record';

import {
  Container,
  Content,
  Description,
  Header,
  BusinessAvatar,
  Title,
} from './ExplorerJobPostCard.styles';
import { ExplorerJobPostCardButtons } from './ExplorerJobPostCardButtons';

interface Props {
  data: CareerInfoFragment;

  /** @default false */
  withMessage?: boolean;
  /** @default false */
  withSave?: boolean;
  /**
   * Whether the current user can edit the current job offer.
   * @default false
   */
  withEdit?: boolean;
  /** Allow to extend the current button options. */
  addons?: ReactNode;

  onOpenDetails(): void;
  onOpenEdit?(): void;
}

export function ExplorerJobPostCard(props: Props) {
  const [isSaved, setSaved] = useState(false);

  const { push: navigate } = useHistory();
  const { isMobile } = useResponsive();
  const { onOpenInboxModal } = useInboxContext();
  const { user: currentUser, loading: currentUserLoading } = useCurrentUser();
  const { data: savedOffers, loading: savedOffersLoading } = useCurrentUserSavedOffers();
  const { data: organizations, loading: organizationsLoading } = useCurrentUserOrganizations();
  const { onSaveJob, onUnsaveJob } = useJobOfferSave();

  const loading = currentUserLoading || savedOffersLoading || organizationsLoading;

  useEffect(() => {
    const isSaved = savedOffers.some(offer => offer.id === props.data.id);
    // Sync the state within the current data from the database.
    setSaved(isSaved);
  }, [props.data, savedOffers, setSaved]);

  const title = props.data.position ?? '(Job Post)';
  const business = props.data.organization;
  const description = business?.name ?? '(Organization)';
  const author = props.data.createdBy;
  const imageSource = business?.logo?.downloadUrl ?? undefined;

  const withMessage = props.withMessage ?? false;
  const withSave = props.withSave ?? false;

  const withEdit = useMemo(() => {
    const isMember = organizations.some(organization => organization.id === business?.id);
    const isOwner = author?.id === currentUser?.id;

    const canEdit = isMember || isOwner;

    return Boolean(props.withEdit) && canEdit;
  }, [props.withEdit, currentUser, business, organizations, author]);

  const isExpired = useHasJobOfferExpired(props.data);

  const location = useMemo(() => getOrganizationAddress(business), [business]);

  const openOrganization = () => {
    if (!business?.id) {
      return;
    }

    const url = generatePath(ROUTES.user.organizations.organization, { id: business.id });
    navigate(url);
  };

  const openMessage = () => {
    if (!onOpenInboxModal || !author) {
      return;
    }

    onOpenInboxModal({
      isOpen: true,
      options: {
        members: [author],
        messageType: 'personal',
      },
    });
  };

  const toggleSave = async () => {
    const wasSaved = Boolean(isSaved);
    setSaved(prev => !prev);

    try {
      if (wasSaved === false) {
        await onSaveJob(props.data.id);
        return;
      }

      await onUnsaveJob(props.data.id);
    } catch (err) {
      recordError(err);
      setSaved(wasSaved);
    }
  };

  return (
    <Container borderless={isMobile}>
      <BusinessAvatar src={imageSource} onClick={openOrganization}>
        <Business />
      </BusinessAvatar>

      <Content>
        <Header>
          <Title aria-label={title} title={title} onClick={props.onOpenDetails}>
            {title}
          </Title>

          {isExpired && <Chip label="Expired" />}
        </Header>

        {description && <Description>{description}</Description>}
        <Description>{location}</Description>
      </Content>

      <ExplorerJobPostCardButtons
        isSaved={isSaved}
        disableSave={loading}
        disableEdit={loading}
        withMessage={withMessage}
        withSave={withSave}
        withEdit={withEdit}
        addons={props.addons}
        onToggleSave={toggleSave}
        onOpenMessage={openMessage}
        onOpenEdit={props.onOpenEdit}
      />
    </Container>
  );
}
