import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const ellipsis = css`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const Container = styled(Link)`
  color: inherit;
  text-decoration: none;

  ${ellipsis};

  &:hover {
    text-decoration: underline;
  }
`;

export const Name = styled.span`
  ${ellipsis};
`;
