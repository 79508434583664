import { GroupKeyFilter, useGroupMemberRemoveMutation } from 'shared/graphql';
import { NormalizedGroupMember } from './useGroupMembers';
import { useToast } from 'shared/hooks';
import { recordDebug, recordError } from 'shared/utils/record';

interface UseGroupMemberRemoveOptions {
  group: GroupKeyFilter | undefined | null;
}

export function useGroupMemberRemove(options: UseGroupMemberRemoveOptions) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();

  const [removeMutation] = useGroupMemberRemoveMutation({
    refetchQueries: ['GroupMembers'],
    awaitRefetchQueries: true,
  });

  const mutate = async (member: NormalizedGroupMember) => {
    if (!options.group) {
      recordDebug(`Group is not defined. Cannot promote member.`);
      return;
    }

    const messageRemovingKey = `REMOVE_GROUP_MEMBER_REQUEST_${member.id}`;

    try {
      showMessage(`Removing member from group`, { id: messageRemovingKey });

      await removeMutation({
        variables: {
          user: { id: member.user.id },
          group: { id: options.group.id },
        },
      });

      showSuccess(`Member has been removed.`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    dismiss(messageRemovingKey);
  };

  return {
    action: mutate,
  };
}
