import { forwardRef } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Field, FieldProps, FieldValidator } from 'formik';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';
import { Multiselect, MultiselectProps } from '../Multiselect';

export type FormMultiselectProps = ComponentDataProps & {
  fieldProps: {
    name: string;
    validate?: FieldValidator;
  };
  selectProps: MultiselectProps;
};

export const FormMultiselect = forwardRef<HTMLDivElement, FormMultiselectProps>(
  (
    {
      fieldProps,
      selectProps,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    return (
      <Field name={fieldProps.name} validate={fieldProps.validate}>
        {({ field, meta, form }: FieldProps) => {
          const isInvalid = getIsInvalid({ meta, form });
          const errorText = isInvalid && meta.error;

          return (
            <FormControl
              ref={ref}
              data-test={dataTest}
              data-node-id={dataNodeID}
              data-node-render-path={dataRenderPath}
            >
              <Multiselect
                {...selectProps}
                name={fieldProps.name}
                value={field.value || []}
                onChange={field.onChange}
                error={isInvalid}
              />
              {isInvalid && <FormHelperText error>{errorText}</FormHelperText>}
            </FormControl>
          );
        }}
      </Field>
    );
  },
);
