import React from 'react';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';

import { Box } from 'shared/components/ui';

const container72CSS = css`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  grid-gap: 15px;
`;

const container73CSS = css`
  display: flex;
  flex-direction: column;
`;

const typography24CSS = css`
  font-weight: 500;
  color: #8b8b8b;
  font-size: 14px;
`;

const typography25CSS = css`
  font-size: 16px;
  color: #162d46;
  font-weight: 400;
`;

const typography227CSS = css`
  color: #ef5c5c;
  min-height: auto;
  font-weight: 500;
`;

type BuilderComponentsBoxUserSymbol1Props = {
  img?: string;
  title?: string;
  body?: string;
  link?: string;
  imgW?: string;
  imgH?: string;
  children?: React.ReactNode;
  onClick?: (event: any) => void;
};

export const BuilderComponentsBoxUserSymbol1: React.FC<
  BuilderComponentsBoxUserSymbol1Props
> = symbolProps => {
  return (
    <Box css={container72CSS}>
      <img
        css={css`
          flex-shrink: 0;
          width: 80px;
          height: 80px;
          height: ${symbolProps.imgH};
          width: ${symbolProps.imgW};
          border-radius: 5px;
          border-style: solid;
          border-width: 1px;
          border-color: #dbdbdb;
        `}
        src={symbolProps.img || ''}
        width={100}
      />
      <Box css={container73CSS}>
        <Typography
          css={typography24CSS}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {symbolProps.title}
        </Typography>
        <Typography
          css={typography25CSS}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {symbolProps.body}
        </Typography>
        <Typography
          css={typography227CSS}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {symbolProps.link}
        </Typography>
      </Box>
    </Box>
  );
};
