import { Fragment, MouseEvent, ReactNode, forwardRef, useMemo } from 'react';
import { Box, Menu as MuiMenu, MenuProps as MuiMenuProps } from '@material-ui/core';

import { isFn } from '@jebel/utils';

import { useModalState } from 'shared/hooks';

import { ComponentDataProps } from '../../types';

export interface MenuRenderParams {
  close(): void;
  /** @deprecated Use `close` instead. */
  onClose(): void;
}

export type MenuRender = (params: MenuRenderParams) => ReactNode;

export type MenuProps = ComponentDataProps &
  Omit<MuiMenuProps, 'open'> & {
    target: ReactNode;
    content: (args: { onClose: () => void }) => ReactNode;
    forceOpen?: boolean;
    __nodeAlias?: string;
  };

export const Menu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  const { show: isOpen, params: anchor, open, close } = useModalState<Element>();

  const children = useMemo(() => {
    const children = props.children ?? props.content;

    if (isFn<MenuRender>(children)) {
      return children({ onClose: close, close });
    }

    return children;
  }, [props.children, props.content, close]);

  const handleOpen = (event: MouseEvent) => {
    open(event.currentTarget);
  };

  return (
    <Fragment>
      <Box onClick={handleOpen}>{props.target}</Box>

      <MuiMenu {...props} ref={ref} anchorEl={anchor} open={isOpen}>
        {children}
      </MuiMenu>
    </Fragment>
  );
});
