import React from 'react';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';

import { Box } from 'shared/components/ui';

const container71CSS = css`
  min-height: auto;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
`;

const container74CSS = css`
  min-height: auto;
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto;
  align-content: center;
  justify-items: center;
  grid-column-gap: 8px;
`;

const typography26CSS = css`
  color: #0089ff;
  font-weight: 500;
  min-height: auto;
`;

const typography227CSS = css`
  color: #ef5c5c;
  min-height: auto;
  font-weight: 500;
`;

type BuilderComponentsBoxUserSymbol4Props = {
  title?: string;
  children?: React.ReactNode;
  onClick?: (event: any) => void;
};

export const BuilderComponentsBoxUserSymbol4: React.FC<
  BuilderComponentsBoxUserSymbol4Props
> = symbolProps => {
  return (
    <Box css={container71CSS}>
      <Box css={container74CSS}>
        <Box>{symbolProps.children}</Box>
        <Typography
          css={typography26CSS}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {symbolProps.title}
        </Typography>
      </Box>
      <Typography
        css={typography227CSS}
        variant="inherit"
        align="inherit"
        color="initial"
        paragraph={false}
      >
        View all
      </Typography>
    </Box>
  );
};
