import { FeedLayout } from 'shared/components/layouts';

import { FeedSkeleton } from '../FeedSkeleton';

export function FeedPageSkeleton() {
  return (
    <FeedLayout sidebar={<FeedSkeleton count={2} />}>
      <FeedSkeleton />
    </FeedLayout>
  );
}
