import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Typography, MenuItem, Button, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { USER_AFFILIATIONS } from '@jebel/constants';

import {
  RouteLayout,
  Box,
  Form,
  FormTextField,
  FormPhoneFieldV2 as FormPhoneField,
  FormSelect,
} from 'shared/components/ui';
import { APP_URL, FIELDS_CONFIG_USER } from 'shared/constants';
import { required } from 'shared/utils/form';
import { useUserRequestAccess } from 'shared/hooks';
import { useGlobalState } from 'providers';
import { recordError } from 'shared/utils/record';

const requestAccessCSS = css`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-direction: column;
  height: 100%;
  padding-bottom: 30px;
`;

const topBoxCSS = css`
  display: grid;
  grid-row-gap: 35px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
`;

const formTitleBoxCSS = css`
  display: grid;
  justify-content: center;
  text-align: center;
`;

const titleCSS = css`
  font-size: 16px;
  line-height: 26px;
`;

const formBoxCSS = css`
  display: grid;
  grid-gap: 20px;
`;

const namesBoxCSS = theme => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${theme.spacing(2.5)}px;

  ${theme.breakpoints.down('xs')} {
    grid-template-columns: 1fr;
  }
`;

const buttonsBoxCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const haveAccountCSS = css`
  text-align: center;
  white-space: nowrap;
`;

type FormData = {
  affiliation?: string;
  affiliationDescription?: string;
  email?: string;
  firstName?: string;
  graduatingYearIso?: string;
  lastName?: string;
  phoneNumber?: string;
};

export function RequestAccessPage() {
  const { helpModal, toasts } = useGlobalState();
  const { onUserRequestAccess } = useUserRequestAccess({ shouldRedirect: true });

  const onSubmit = async (form: FormData) => {
    try {
      await onUserRequestAccess({
        affiliation: form.affiliation as string,
        affiliationDescription: form.affiliationDescription,
        email: form.email as string,
        firstName: form.firstName as string,
        graduatingYearIso: form.graduatingYearIso,
        lastName: form.lastName as string,
        phoneNumber: form.phoneNumber,
      });

      toasts.showSuccess(
        'Thank you. Your request to join Columbus Connects has been submitted and is currently under review. You will receive an email notification once your request has been processed.',
      );
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        toasts.showError(err.message);
      }
    }
  };

  const handleOpenNeedHelp = () => {
    helpModal.open({ subject: 'Need help creating my account' });
  };

  return (
    <RouteLayout css={requestAccessCSS}>
      <Box css={topBoxCSS}>
        <Box css={formTitleBoxCSS}>
          <Typography
            css={titleCSS}
            variant="inherit"
            align="inherit"
            color="initial"
            paragraph={false}
          >
            Columbus Connects is a private online community for Christopher Columbus High School.
          </Typography>
        </Box>
        <Form<FormData> oldCss={formBoxCSS} onSubmit={onSubmit}>
          {({ values, isSubmitting }) => (
            <Fragment>
              <Box css={namesBoxCSS}>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'First Name',
                    variant: 'outlined',
                  }}
                  fieldProps={{ name: 'firstName', validate: required }}
                />
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Last Name',
                    variant: 'outlined',
                  }}
                  fieldProps={{ name: 'lastName', validate: required }}
                />
              </Box>
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'Email Address',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'email', validate: required }}
              />
              <FormPhoneField fieldProps={{ name: 'phoneNumber' }} />
              <FormSelect
                selectProps={{
                  children: FIELDS_CONFIG_USER.affiliation.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                  variant: 'outlined',
                  label: 'Affiliation',
                }}
                fieldProps={{ name: 'affiliation', validate: required }}
              />

              {values.affiliation === USER_AFFILIATIONS.alumni && (
                <FormSelect
                  selectProps={{
                    children: FIELDS_CONFIG_USER.graduatingYear.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )),
                    variant: 'outlined',
                    label: 'Graduation Year',
                  }}
                  fieldProps={{ name: 'graduatingYearIso', validate: required }}
                />
              )}

              {values.affiliation === USER_AFFILIATIONS.other && (
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Type a brief description of your affiliation with the school',
                    variant: 'outlined',
                  }}
                  fieldProps={{ name: 'affiliationDescription', validate: required }}
                />
              )}

              <Box css={buttonsBoxCSS}>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Request Access
                </Button>

                <Typography css={haveAccountCSS}>
                  <span>If you already have an account, &nbsp;</span>

                  <Link component={RouterLink} color="secondary" to={APP_URL.public.login}>
                    Login here
                  </Link>

                  <span>.</span>
                </Typography>

                <Link color="secondary" align="center" onClick={handleOpenNeedHelp}>
                  Need Help?
                </Link>
              </Box>
            </Fragment>
          )}
        </Form>
      </Box>
    </RouteLayout>
  );
}
