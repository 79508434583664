import { Fragment, useState } from 'react';
import { Button } from '@material-ui/core';
import styled from '@emotion/styled';

import { SORT } from '@jebel/constants';

import { Modal, Typography } from 'shared/components/ui';
import { SpreadsheetProvider } from 'shared/features/spreadsheet/providers';
import { useCrudPermissions } from 'shared/hooks';
import { InviteMemberModal } from 'shared/components/dialogs/InviteModal/components/InviteMemberModal';

import { MembersSpreadsheet } from './MembersSpreadsheet';
import { MembersStats } from './MembersStats';
import { SearchProvider } from '../../../shared/features/search';

const FALLBACK_SORT = { sort: { createdAt: SORT.desc }, order: SORT.desc, headcellId: 'createdAt' };

export function MembersContent() {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const { membersPermissions } = useCrudPermissions();

  const onModalClose = () => {
    setIsInviteModalOpen(false);
  };

  const onModalOpen = () => {
    setIsInviteModalOpen(true);
  };

  return (
    <Fragment>
      <Modal
        dialogProps={{ open: isInviteModalOpen, onClose: onModalClose }}
        titleProps={{ title: 'Invite Members' }}
      >
        <InviteMemberModal onClose={onModalClose} isAdminInviting />
      </Modal>

      <Content>
        <Header>
          <Typography variant="subtitle2" color="primary">
            Members
          </Typography>

          {membersPermissions.add && (
            <Button color="primary" variant="contained" onClick={onModalOpen}>
              Invite
            </Button>
          )}
        </Header>

        <MembersStats />

        <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
          <SearchProvider>
            <MembersSpreadsheet />
          </SearchProvider>
        </SpreadsheetProvider>
      </Content>
    </Fragment>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
