import { createFilterBuilder } from '@jebel/utils';

import { CollectiveIntelligenceThreadFilter, SchoolConfigurationKeyFilter } from 'shared/graphql';
import { createUserFilter } from './createUserFilter';

interface Options {
  initial?: CollectiveIntelligenceThreadFilter;

  search?: string;

  school?: SchoolConfigurationKeyFilter;
}

export function createForumThreadFilter(options?: Options) {
  const filter = createFilterBuilder<CollectiveIntelligenceThreadFilter>(options?.initial);

  if (options?.search) {
    const searchFilter = createFilterBuilder<CollectiveIntelligenceThreadFilter>();

    searchFilter.or({ title: { contains: options.search } });
    searchFilter.or({ text: { contains: options.search } });
    searchFilter.or({ creator: createUserFilter({ search: options.search }) });

    filter.and(searchFilter);
  }

  if (options?.school) {
    filter.and({
      schools: {
        some: {
          id: { equals: options.school.id },
        },
      },
    });
  }

  return filter.build();
}
