import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  ${p => p.theme.breakpoints.down('sm')} {
    gap: 0.5rem;
  }
`;
