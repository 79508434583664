import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import { IconButton, List, ListItemText } from '@material-ui/core';

import { Popover, Icon } from 'shared/components/ui';
import { ListItemSymbol, ListItemIconSymbol } from 'shared/components/symbols';
import { useGlobalState } from 'providers';

const popoverTargetCSS = css`
  display: inline-flex;
  justify-content: center;
`;

const list7CSS = css`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 230px;
`;

const listItem1CSS = css`
  :hover {
    color: #fff !important;
    background-color: #295f7b !important;
    transition: 0.25s !important;
  }
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
  padding-top: 6px;
`;

const listItemIcon15CSS = css`
  margin-right: 8px;
  font-size: 20px;
`;

const listItem2CSS = css`
  :hover {
    color: #fff !important;
    background-color: #295f7b !important;
    transition: 0.25s !important;
  }
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
`;

const listItemIcon16CSS = css`
  margin-right: 8px;
`;

const listItem3CSS = css`
  :hover {
    color: #fff !important;
    background-color: #295f7b !important;
    transition: 0.25s !important;
  }
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const listItemIcon17CSS = css`
  margin-right: 8px;
`;

type BuilderComponentsPopoverUserSymbol2Props = {
  stringProp?: string;
  numberProp?: number;
  booleanProp?: boolean;
  children?: React.ReactNode;
  onClick?: (event: any) => void;
};

export const BuilderComponentsPopoverUserSymbol2: React.FC<
  BuilderComponentsPopoverUserSymbol2Props
> = symbolProps => {
  const { markPost, editPost } = useGlobalState();

  return (
    <Popover
      target={
        <div css={popoverTargetCSS}>
          <IconButton color="default" size="medium">
            <Icon name="MoreHoriz" />
          </IconButton>
        </div>
      }
      content={args => (
        <Fragment>
          <div>
            <List css={list7CSS} dense={false} disablePadding={false}>
              <ListItemSymbol
                css={listItem1CSS}
                onClick={event => {
                  editPost.open();
                }}
                button={true}
                disableGutters={true}
                startIcon={
                  <ListItemIconSymbol
                    css={listItemIcon15CSS}
                    name="Edit"
                    variant="filled"
                    color="inherit"
                    fontSize="default"
                    viewBox="0 0 24 24"
                  />
                }
                listItemText={<ListItemText>Edit Post</ListItemText>}
              />
              <ListItemSymbol
                css={listItem2CSS}
                button={true}
                disableGutters={true}
                startIcon={
                  <ListItemIconSymbol
                    css={listItemIcon16CSS}
                    name="DeleteForever"
                    variant="filled"
                    color="inherit"
                    fontSize="default"
                    viewBox="0 0 24 24"
                  />
                }
                listItemText={<ListItemText>Delete Post</ListItemText>}
              />
              <ListItemSymbol
                css={listItem3CSS}
                onClick={event => {
                  markPost.open();
                }}
                button={true}
                disableGutters={true}
                startIcon={
                  <ListItemIconSymbol
                    css={listItemIcon17CSS}
                    name="Flag"
                    variant="filled"
                    color="inherit"
                    fontSize="default"
                    viewBox="0 0 24 24"
                  />
                }
                listItemText={<ListItemText>Flag as Inappropriate</ListItemText>}
              />
            </List>
          </div>
        </Fragment>
      )}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      elevation={4}
    />
  );
};
