import { lazy, Suspense } from 'react';

import { FeedLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';

const HomeFeedContent = lazy(() => import('../components/HomeFeed/HomeFeedContent'));
const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));

export function HomePage() {
  return (
    <FeedLayout
      sidebar={
        <Suspense fallback={<FeedSkeleton count={2} />}>
          <CommunitySidebar />
        </Suspense>
      }
    >
      <Suspense fallback={<FeedSkeleton />}>
        <HomeFeedContent />
      </Suspense>
    </FeedLayout>
  );
}
