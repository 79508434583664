/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@mui/material';
import { useMemo } from 'react';

import { LegacyFeedPostFilter, Maybe } from 'shared/graphql';
import { useLegacyTimelinePosts } from 'features/legacy/hooks';

import { LegacyTimelinePost } from '../LegacyTimelinePost';
import { Container, Message, MessageContainer } from './LegacyTimelinePosts.styles';

interface Props {
  /** Filter by the selected year */
  year: Maybe<number> | undefined;
  /** Filter by the selected month */
  month?: Maybe<number> | undefined;

  /** @default 3 */
  perPage?: number;
}

export function LegacyTimelinePosts(props: Props) {
  const perPage = props.perPage ?? 3;

  const filter = useMemo<LegacyFeedPostFilter>(() => {
    const filter: LegacyFeedPostFilter = {
      month: {
        month: { equals: props.month },
        legacyYears: {
          legacyYear: { equals: props.year },
        },
      },
    };

    return filter;
  }, [props]);

  const { data, loading } = useLegacyTimelinePosts({
    variables: {
      first: perPage,
      filter,
    },
  });

  if (loading) {
    const skeletons = Array.from({ length: perPage }).fill(null);

    return (
      <Container>
        {skeletons.map((skeleton, index) => (
          <Skeleton key={index} variant="rectangular" height="8rem" />
        ))}
      </Container>
    );
  }

  if (data.length === 0) {
    return (
      <MessageContainer>
        <Message>No posts found for the current filter</Message>
      </MessageContainer>
    );
  }

  return (
    <Container>
      {data.map((post, index) => (
        <LegacyTimelinePost
          key={post.id ?? index}
          content={post.text}
          legacyDate={post.legacyDate}
          media={post.media?.items}
        />
      ))}
    </Container>
  );
}
