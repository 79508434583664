import React from 'react';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import type { PickerOptions } from 'filestack-js';
import { FileInputProps, FileInputValue, OriginalFileInputValue } from '@8base-react/file-input';
import _ from 'lodash';
import { Close as IconClose } from '@mui/icons-material';
import pluralize from 'pluralize';

import { fileSizeToHuman } from '@jebel/utils';

import { ONE_MEGABYTE } from 'shared/constants/files';
import { ResultFile, ResultFileValue } from 'shared/types/files';
import { SxProp } from 'shared/types/styles';

import { FileInputWrap as FileInput } from '../FileInputWrap';

interface VideoInputProps {
  label?: string;
  pickerOptions?: PickerOptions;
  fileInputProps?: FileInputProps;
  initialValue?: string | null;
  name?: string;
  showPreview?: boolean;
  showDescription?: boolean;
  maxFiles?: number;
  onChange: (data: ResultFileValue | null) => void;
}

const MAX_FILE_SIZE = ONE_MEGABYTE * 100;

export const VideoInput: React.FC<VideoInputProps> = ({
  children,
  label,
  pickerOptions,
  onChange,
  fileInputProps,
  initialValue,
  name,
  showDescription = true,
  showPreview = true,
  maxFiles = 6,
}) => {
  const [value, setValue] = React.useState<ResultFileValue | null>(null);

  const handleChange = React.useCallback(
    (newValue: ResultFileValue | null) => {
      setValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  const addFiles = React.useCallback(
    (resultFile: ResultFileValue) => {
      if (_.isArray(value)) {
        const newResult = [...value, ...(resultFile as ResultFile[])];
        handleChange(newResult);
      } else {
        handleChange(resultFile);
      }
    },
    [value, handleChange],
  );

  const pickerContent = React.useMemo(() => {
    return (
      <React.Fragment>
        {children}
        {label && (
          <Typography variant="body1" color="secondary">
            {label}
          </Typography>
        )}
      </React.Fragment>
    );
  }, [children, label]);

  const onPickVideo = React.useCallback(
    (pick: (options: PickerOptions) => void) => {
      // picker spamming his script into DOM on every pick
      document.getElementById('fs-loader-picker')?.remove();

      pick({
        accept: 'video/*',
        fromSources: ['local_file_system'],
        maxSize: MAX_FILE_SIZE,
        ...pickerOptions,
      });
    },
    [pickerOptions],
  );

  const onUploadDone = async (
    file: FileInputValue,
    originalFile?: OriginalFileInputValue | undefined,
  ): Promise<FileInputValue> => {
    if (!_.isNil(originalFile)) {
      if (_.isArray(file) && _.isArray(originalFile) && file.length === originalFile.length) {
        const files = file as ResultFile[];

        addFiles(
          files.map((data, index) => ({
            ...data,
            size: originalFile[index].size,
          })),
        );
      }

      if (!_.isArray(file) && !_.isArray(originalFile)) {
        addFiles({ ...file, size: originalFile.size } as ResultFile);
      }
    } else {
      addFiles(file as ResultFileValue);
    }

    return file;
  };

  const onRemoveFile = React.useCallback(
    (id: string) => {
      const newValue = _.isArray(value) ? value.filter(file => file.fileId === id) : null;

      handleChange(newValue);
    },
    [value, handleChange],
  );

  const preview = React.useMemo(() => {
    if (showPreview) {
      if (initialValue && _.isNil(value)) {
        return renderFilePreview(
          {
            downloadUrl: initialValue,
            filename: name || 'video',
            fileId: 'initialValueVideo',
          },
          onRemoveFile,
          !!showDescription,
        );
      }
      if (!_.isNil(value)) {
        if (_.isArray(value)) {
          return (
            <React.Fragment>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Uploaded {value.length} {pluralize('file', value.length)} out of {maxFiles}
                </Typography>
              </Box>
              <List>
                {value.map(file => renderFilePreview(file, onRemoveFile, !!showDescription))}
              </List>
            </React.Fragment>
          );
        }
        return renderFilePreview(value, onRemoveFile, !!showDescription);
      }
    }

    return null;
  }, [value, initialValue, maxFiles, name, onRemoveFile, showDescription, showPreview]);

  const filesLeft = React.useMemo(() => {
    return maxFiles - (_.isArray(value) ? value.length : 0);
  }, [maxFiles, value]);

  const isNoFilesLeft = React.useMemo(() => {
    return filesLeft === 0;
  }, [filesLeft]);

  return (
    <Box>
      <Box sx={isNoFilesLeft ? { cursor: 'not-allowed' } : {}}>
        <FileInput {...(fileInputProps || {})} maxFiles={filesLeft} onUploadDone={onUploadDone}>
          {({ pick }) => (
            <Box
              sx={isNoFilesLeft ? { opacity: 0.5, pointerEvents: 'none' } : {}}
              onClick={() => onPickVideo(pick)}
            >
              {pickerContent}
            </Box>
          )}
        </FileInput>
      </Box>
      {preview}
    </Box>
  );
};

const deleteButtonStyles: SxProp = theme => ({
  px: 1,
  py: 0,
  minWidth: 'auto',
  color: theme.palette.error.main,
});

const renderFilePreview = (
  fileInfo: ResultFile,
  onDelete: (fileId: string) => void,
  showDescription: boolean,
) => {
  return (
    <ListItem key={fileInfo.fileId} sx={{ padding: 0, gap: 1 }}>
      <ListItemAvatar>
        <Avatar
          src={fileInfo.downloadUrl || ''}
          alt={fileInfo.filename || ''}
          sx={{ width: 65 }}
          variant="rounded"
        />
      </ListItemAvatar>
      {showDescription && (
        <ListItemText
          primary={
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                variant="body1"
                color="black"
                sx={{
                  lineHeight: '1.25rem',
                  wordBreak: 'break-all',
                }}
              >
                {fileInfo.filename}
              </Typography>
              <Button
                onClick={() => onDelete(fileInfo.fileId || '')}
                variant="text"
                sx={deleteButtonStyles}
              >
                <IconClose />
              </Button>
            </Box>
          }
          secondary={
            <Typography variant="body1" color="black" sx={{ lineHeight: '1.5rem' }}>
              {fileSizeToHuman(fileInfo.size, 'Unknown file size')}
            </Typography>
          }
        />
      )}
    </ListItem>
  );
};
