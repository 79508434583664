import { useSearchContext } from 'shared/features/search';
import { useOrganizationsFilter, useRandomSchoolSupporters } from 'features/organizations/hooks';

/**
 * Generates a list of school supporters sorted randomly every time that's executed.
 * @ticket https://8base-dev.atlassian.net/browse/JEB-1457
 * @ticket https://github.com/jebelapp/jebel/issues/1565
 * @returns List of school supporters sorted randomly.
 */
export function useExplorerSupporters() {
  const { sortOption } = useSearchContext();
  const { filter: currentFilter } = useOrganizationsFilter();

  const { data, count, loading } = useRandomSchoolSupporters({
    variables: { filter: currentFilter, sort: sortOption },
  });

  return {
    data,
    count,
    loading,
  };
}
