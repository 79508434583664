import { Theme, css, useTheme } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { US_TIMEZONES } from '@jebel/constants';
import { processFilestackUrlSrcSet } from '@jebel/utils';

import { glueStrings } from 'shared/utils/strings';
import { Button, Icon, Typography } from 'shared/components/ui';
import { EventInfoFragment, Organization } from 'shared/graphql';
import { CardLayout } from 'shared/components/layouts';
import { getFullMonthAndWeekdayAndTime } from 'shared/utils/date';
import { useResponsive } from 'shared/hooks';
import { buildUrl } from 'shared/routes';
import { APP_URL } from 'shared/constants';
import { EMPTY_IMAGE } from 'features/settings/assets';

const DEFAULT_TIMEZONE = 'CST';

export const containerCSS = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > * {
    flex: 1;
  }

  ${theme.breakpoints.up('md')} {
    justify-content: space-between;
    align-items: center;
  }
`;

export const headerCSS = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const contentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const eventDateBox = css`
  display: grid;
`;

export const eventLocationBox = css`
  display: grid;
`;

export const locationCss = (theme: Theme) => css`
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${theme.palette.primary.dark};
`;

export const eventDateTime = (theme: Theme) => css`
  color: ${theme.palette.text.secondary};
  line-height: 26px;
  font-size: 14px;
  font-weight: 500;
`;

export const dateTimeCss = (theme: Theme) => css`
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${theme.palette.primary.dark};
`;

export const eventLocation = (theme: Theme) => css`
  color: ${theme.palette.text.secondary};
  line-height: 26px;
  font-size: 14px;
  font-weight: 500;
`;

export const learnMoreIcon = (theme: Theme) => css`
  font-size: 20px;
  fill: ${theme.palette.primary.light};
`;

export const learnMoreBox = css`
  align-items: center;
  grid-column-gap: 4px;
`;

export const imageContainerCSS = css`
  display: grid;
  grid-template-columns: auto;
  align-items: end;
  grid-column-gap: 30px;
`;

const titleSponsorContainerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const EVENT_IMAGE_SIZE = 180;
export const SPONSOR_IMAGE_SIZE = 78;

export const eventPreview = {
  height: `${EVENT_IMAGE_SIZE}px`,
  width: `${(EVENT_IMAGE_SIZE / 4) * 3}px`,
};

export const eventAvatarCSS = (theme: Theme) => css`
  --size: 14rem;

  width: 100%;
  height: var(--size);
  object-fit: contain;

  ${theme.breakpoints.down('sm')} {
    --size: 6rem;
  }
`;

export const sponsorAvatarCSS = (theme: Theme) => css`
  --size: 8rem;

  width: var(--size);
  height: var(--size);
  object-fit: contain;

  ${theme.breakpoints.down('sm')} {
    --size: 5rem;
  }
`;

export const eventTitle = (isMobile: boolean) => (theme: Theme) =>
  css`
    font-size: 24px;
    color: ${theme.palette.primary.dark};
    line-height: ${isMobile ? 24 : 43}px;
    min-height: auto;
    letter-spacing: 0;
    font-weight: 500;
  `;

export const learnMoreCss = (theme: Theme) => css`
  text-transform: none;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  padding: ${theme.spacing(0.7)}px ${theme.spacing(0.5)}px;
  color: ${theme.palette.primary.light};
`;

export type EventProps = {
  title?: string | null | undefined;
  date: Date;
  location: EventInfoFragment['location'];
  images: EventInfoFragment['images'];
  onEventOpen: (event?: any) => void;
  timezone?: string | null | undefined;
  id?: string | null | undefined;
  titleSponsor?: Organization | null;
};

export const Event = ({
  title,
  date,
  location,
  images,
  onEventOpen,
  timezone,
  titleSponsor,
}: EventProps) => {
  const { isMobile } = useResponsive();
  const fullLocation = glueStrings(
    ', ',
    location?.street1,
    location?.city,
    location?.state,
    location?.zip,
  );
  const history = useHistory();
  const theme: Theme = useTheme();

  const openTitleSponsor = () => {
    if (!titleSponsor?.id) {
      return;
    }

    const url = buildUrl(APP_URL.user.organizations.organization, {
      pathParams: { id: titleSponsor?.id },
    });

    history.push(url);
  };

  return (
    <CardLayout borderless={isMobile}>
      <CardLayout.Content css={containerCSS}>
        <Box css={contentCSS}>
          <Box css={headerCSS}>
            <Typography variant="subtitle3">{title}</Typography>

            {isMobile && (
              <Box css={imageContainerCSS}>
                {images?.items[0] && (
                  <Avatar
                    css={eventAvatarCSS}
                    src={images?.items[0]?.downloadUrl || EMPTY_IMAGE}
                    srcSet={processFilestackUrlSrcSet(images?.items[0]?.downloadUrl || '', {
                      compress: true,
                      resize: {
                        width: EVENT_IMAGE_SIZE,
                        height: EVENT_IMAGE_SIZE,
                        fit: 'crop',
                      },
                    })}
                    variant="square"
                  />
                )}
              </Box>
            )}
          </Box>

          <Box css={eventDateBox}>
            <Typography css={dateTimeCss}>Date/time</Typography>
            <Typography css={eventDateTime}>
              {getFullMonthAndWeekdayAndTime(
                date,
                US_TIMEZONES.find(item => item.abbreviation === timezone)?.abbreviation ||
                  DEFAULT_TIMEZONE,
              )}
            </Typography>
          </Box>

          <Box css={eventLocationBox}>
            <Typography css={locationCss}>Location</Typography>
            <Typography css={eventLocation}>{fullLocation}</Typography>
          </Box>

          <Button css={learnMoreCss} startIcon={<Icon name="Launch" />} onClick={onEventOpen}>
            Learn more
          </Button>
        </Box>

        {!isMobile && Boolean(titleSponsor) && (
          <Box css={titleSponsorContainerCSS} onClick={openTitleSponsor}>
            <Box alignSelf="center">
              <Typography css={locationCss}>Title Sponsor</Typography>
            </Box>

            <Avatar
              css={sponsorAvatarCSS}
              src={titleSponsor?.logo?.downloadUrl ?? EMPTY_IMAGE}
              srcSet={processFilestackUrlSrcSet(titleSponsor?.logo?.downloadUrl || '', {
                compress: true,
                resize: {
                  width: EVENT_IMAGE_SIZE,
                  height: EVENT_IMAGE_SIZE,
                  fit: 'crop',
                },
              })}
              variant="square"
            />

            <Typography align="center" css={{ color: theme.palette.error.light }}>
              {titleSponsor?.name || ''}
            </Typography>
          </Box>
        )}

        {!isMobile && (
          <Box css={imageContainerCSS}>
            <Avatar
              css={eventAvatarCSS}
              src={images?.items[0]?.downloadUrl ?? EMPTY_IMAGE}
              srcSet={processFilestackUrlSrcSet(images?.items[0]?.downloadUrl || '', {
                compress: true,
                resize: {
                  width: EVENT_IMAGE_SIZE,
                  height: EVENT_IMAGE_SIZE,
                  fit: 'crop',
                },
              })}
              variant="square"
            />
          </Box>
        )}
      </CardLayout.Content>
    </CardLayout>
  );
};
