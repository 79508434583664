import { POST_REPORT_STATUS, POST_STATUSES } from '@jebel/constants';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { HomeFeedPostCreateInput, useHomeFeedPostCreateMutation } from 'shared/graphql';
import { useCurrentUser, useSchoolConfiguration } from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';

/** Create a new `HomeFeedPost` instance. */
export function useHomeFeedPostCreate() {
  const { userId } = useCurrentUser();
  const { data: school } = useSchoolConfiguration();

  const [mutate, { loading }] = useHomeFeedPostCreateMutation({
    refetchQueries: ['HomeFeedItems'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: `Your post will be moderated, we'll let you know when it's available on the feed.`,
      [SNACKBAR_ERROR_MESSAGE]: 'Something went wrong creating your post, please try again.',
    },
  });

  const create = async (data: Omit<HomeFeedPostCreateInput, 'author' | 'schools'>) => {
    if (!userId || !school) {
      return;
    }

    try {
      await mutate({
        variables: {
          data: {
            ...data,

            // https://8base-dev.atlassian.net/browse/JEB-1553
            status: POST_STATUSES.pending,
            reportStatus: POST_REPORT_STATUS.pendingReview,

            author: {
              connect: {
                id: userId,
              },
            },

            schools: {
              // Create a connection to the school the user is currently viewing.
              // https://github.com/jebelapp/jebel/issues/1577
              connect: [{ id: school?.id }],
            },
          },
        },
      });
    } catch (err) {
      sendToSentry(err);
    }
  };

  return {
    create,
    loading,
  };
}
