import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  InviteBusinessMutation,
  InviteBusinessMutationVariables,
} from 'shared/graphql/__generated__';

import { INVITE_BUSINESS_MUTATION } from '../queries';

export const useBusinessInvite = () => {
  const [inviteBusiness, { loading: isInviting }] = useMutation<
    InviteBusinessMutation,
    InviteBusinessMutationVariables
  >(INVITE_BUSINESS_MUTATION, {
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Your invitations have been sent. Thank you.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your invitations haven't been sent`,
    },
  });

  const onBusinessInviteSend = React.useCallback(
    (emails: string[]) => {
      return inviteBusiness({
        variables: {
          emails,
        },
      });
    },
    [inviteBusiness],
  );

  return { onBusinessInviteSend, isInviting };
};
