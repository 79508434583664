import { Fragment, ReactNode, useMemo } from 'react';

import { useModalState } from 'shared/hooks';
import { FeedSkeleton, Typography } from 'shared/components/ui';
import { JobOffer, JobOfferPresentationFragment, Organization } from 'shared/graphql';
import { ContactOrganizationModal } from 'shared/components/dialogs/ContactOrganizationModal';

import { useAdsList } from '../hooks';
import { WithAdvertising } from './withAdvertising';

interface BaseProps {
  loading?: boolean;

  /**
   * Remove the border of the card.
   * @ticket https://github.com/jebelapp/jebel/issues/1525
   * @default false
   */
  borderless?: boolean;
}

interface PropsWithRender<T> extends BaseProps {
  items: T[];
  renderItem(data: T, index: number, arr: T[]): ReactNode;
}

interface PropsWithElement extends BaseProps {
  items: ReactNode[];
}

type Props<T> = PropsWithRender<T> | PropsWithElement;

export function AddAdsToContent<T>(props: Props<T>) {
  const {
    show: isContactModalOpen,
    params: contactParams,
    open: openContactModal,
    close: closeContactModal,
  } = useModalState<JobOfferPresentationFragment>();

  const PostsNotFound = () => <Typography variant="subtitle5">No content was found</Typography>;

  const onContactModalClose = () => {
    closeContactModal();
  };

  const onContactModalOpen = (data: { jobOffer?: JobOffer; organization?: Organization }): void => {
    if (!data?.jobOffer && !data?.organization) {
      return;
    }

    if (data?.jobOffer) {
      openContactModal(data.jobOffer);
      return;
    }

    if (data?.organization) {
      openContactModal({ organization: data.organization });
      return;
    }
  };

  const { items: ads, loading: adsLoading } = useAdsList({
    borderless: props.borderless,
    onContactModalOpen,
  });

  const content = useMemo(() => {
    if ('renderItem' in props) {
      const elements = props.items ?? [];

      return (
        <WithAdvertising
          advertising={ads}
          loading={props.loading}
          items={elements.map(props.renderItem)}
        />
      );
    }

    return <WithAdvertising loading={props.loading} advertising={ads} items={props.items} />;
  }, [props, ads]);

  if (props.loading || adsLoading) {
    return <FeedSkeleton />;
  }

  if (props.items.length > 0) {
    return (
      <Fragment>
        <ContactOrganizationModal
          isOpen={isContactModalOpen}
          onClose={onContactModalClose}
          jobOffer={contactParams}
        />

        {content}
      </Fragment>
    );
  }

  return <PostsNotFound />;
}
