import { Fragment } from 'react';

import { UserInfoFragment } from 'shared/graphql';
import { useResponsive } from 'shared/hooks';
import { AddAdsToContent } from 'features/advertising';

import ExplorerUserCard from '../ExplorerUserCard';

type ExplorerListProps = {
  usersList: UserInfoFragment[] | undefined;
  /** @default false */
  withAds?: boolean;
};

export function ExplorerUserList(props: ExplorerListProps) {
  const users = props.usersList ?? [];

  const { isMobile } = useResponsive();

  const renderItem = (user: UserInfoFragment) => {
    return <ExplorerUserCard data={user} withViewProfile withMessage />;
  };

  if (props.withAds) {
    return <AddAdsToContent items={users} renderItem={renderItem} borderless={isMobile} />;
  }

  return <Fragment>{users.map(renderItem)}</Fragment>;
}
