import { forwardRef } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Field, FieldProps, FieldValidator } from 'formik';

import { ComponentDataProps } from '../../types';
import { Autocomplete, AutocompleteProps } from '../Autocomplete';
import { getIsInvalid } from '../common';

export type FormAutocompleteProps = ComponentDataProps & {
  fieldProps: {
    name: string;
    validate?: FieldValidator;
  };
  autocompleteProps: AutocompleteProps & {
    onChange?: (
      event: React.ChangeEvent<Record<string, unknown>>,
      value: unknown,
      reason?: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<unknown>,
      fieldProps?: FieldProps,
    ) => void;
  };
};

/** @deprecated Use `AutocompleteField` instead. */
export const FormAutocomplete = forwardRef<HTMLDivElement, FormAutocompleteProps>(
  (
    {
      fieldProps,
      autocompleteProps,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    return (
      <Field name={fieldProps.name} validate={fieldProps.validate}>
        {({ field, meta, form }: FieldProps) => {
          const isInvalid = getIsInvalid({ meta, form });
          const errorText = isInvalid && meta.error;

          return (
            <FormControl
              ref={ref}
              data-test={dataTest}
              data-node-id={dataNodeID}
              data-node-render-path={dataRenderPath}
            >
              <Autocomplete
                {...autocompleteProps}
                value={field.value ?? ''}
                onChange={(
                  event: React.ChangeEvent<Record<string, unknown>>,
                  value: unknown,
                  reason?: AutocompleteChangeReason,
                  details?: AutocompleteChangeDetails<unknown>,
                ) => {
                  if (autocompleteProps.onChange) {
                    autocompleteProps.onChange(event, value, reason, details, {
                      field,
                      meta,
                      form,
                    });
                  } else {
                    form.setFieldValue(fieldProps.name, value);
                  }
                }}
                error={isInvalid}
              />
              {isInvalid && <FormHelperText error>{errorText}</FormHelperText>}
            </FormControl>
          );
        }}
      </Field>
    );
  },
);
