import { css, useTheme } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Separator } from 'shared/components/symbols';
import { Typography } from 'shared/components/ui';
import { CareerInfoFragment } from 'shared/graphql';

const COMPANY_LOGO_SIZE = 80;

const companyLogoCSS = css`
  width: ${COMPANY_LOGO_SIZE}px;
  height: ${COMPANY_LOGO_SIZE}px;
`;

const companyLogoBoxCSS = (theme: any) => css`
  border: 1px solid ${theme.palette.text.secondary};
  border-radius: ${theme.spacing(0.5)}px;
  width: ${COMPANY_LOGO_SIZE + 2}px;
  height: ${COMPANY_LOGO_SIZE + 2}px;
`;

const mainExperienceInfoCSS = css`
  height: ${COMPANY_LOGO_SIZE + 2}px;
`;

type ProfilePreviewExperienceProps = {
  adminCreator: CareerInfoFragment['createdBy'];
};

export const ProfilePreviewExperience = ({ adminCreator }: ProfilePreviewExperienceProps) => {
  const theme: any = useTheme();
  const adminJobExperienceList = adminCreator?.jobExperience?.items || [];
  if (adminJobExperienceList?.length === 0) return null;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      alignItems="start"
      pt={2}
      pb={2}
    >
      <Typography variant="subtitle5" align="inherit" color="primary">
        Relevant Experience
      </Typography>
      <Box display="flex" justifyContent="start" flexDirection="row" pt={2}>
        <Box css={companyLogoBoxCSS}>
          <Avatar
            css={companyLogoCSS}
            srcSet={processFilestackUrlSrcSet(
              adminJobExperienceList[0]?.companyImage?.downloadUrl ?? undefined,
              {
                compress: true,
                resize: {
                  width: COMPANY_LOGO_SIZE,
                  height: COMPANY_LOGO_SIZE,
                  fit: 'crop',
                },
              },
            )}
            src={adminJobExperienceList[0]?.companyImage?.downloadUrl ?? undefined}
            variant="square"
          />
        </Box>
        <Box
          css={mainExperienceInfoCSS}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          ml={1}
        >
          <Box display="flex" justifyContent="start" alignItems="center">
            <Typography variant="subtitle5" color="primary">
              {adminJobExperienceList[0]?.positionTitle}
            </Typography>
            <Box pl={1} pr={1}>
              <Separator diameter="4px" color={theme.palette.text.secondary} />
            </Box>
            <Typography variant="body2" color="textSecondary">
              {adminJobExperienceList[0]?.companyName}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="start">
            {adminJobExperienceList[0]?.isCurrentlyWorking && (
              <Typography variant="body2" color="textSecondary">
                Current
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
