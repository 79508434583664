import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Chip } from '@material-ui/core';
import { CardLayout } from 'shared/components/layouts';

import { UserNameLink } from 'shared/components/symbols';

// Divided

export const Divided = styled.div`
  display: flex;
  gap: 0.5rem;
`;

// Container

interface ContainerProps {
  withDivider?: boolean;
}

export const Container = styled(CardLayout)<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-x: hidden;
  user-select: none;
  gap: 0.5rem;

  ${p =>
    p.withDivider &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid ${p.theme.palette.divider};
      }
    `}
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 0.25rem;
  flex: 1;

  ${p => p.theme.breakpoints.down('sm')} {
    align-items: flex-start;
  }
`;

// Header

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  overflow-x: hidden;
`;

// Title

export const Title = styled(UserNameLink)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;
  overflow: hidden;
  font-weight: bold;
  color: ${p => p.theme.palette.primary.dark};
  line-height: normal;
  cursor: pointer;
`;

// Affiliation

export const Affiliation = styled(Chip)`
  text-transform: capitalize;
`;

Affiliation.defaultProps = {
  color: 'secondary',
  size: 'small',
};

// Description

export const Description = styled.span`
  display: block;
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
