import { useMemo } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import { DateTime } from 'luxon';

import { DISCOUNT_STATUS_LABELS, DISCOUNT_STATUSES, DISCOUNT_TYPES } from '@jebel/constants';
import { fileManyRelations } from '@jebel/utils';

import {
  Form,
  Button,
  FormTextField,
  FormKeyboardDateTimePicker,
  Modal,
  ImageInput,
  Typography,
  FormSelect,
  ResultFile,
} from 'shared/components/ui';
import { DISCOUNT_TYPE_OPTIONS, US_TIMEZONES_OPTIONS } from 'shared/constants';
import { DISCOUNT_CATEGORIES, DISCOUNT_TYPES_LABELS } from 'shared/constants/discounts';
import {
  AdminDiscountFragment,
  DiscountCreateRequestInput,
  DiscountUpdateInput,
} from 'shared/graphql';
import { isCorrectDateInterval, isValidAmountDiscount, required } from 'shared/utils/form';
import { useSchoolConfiguration, useToast } from 'shared/hooks';

import { useDiscountInteraction } from '../../hooks';
import {
  Container,
  ImageUploaderContainer,
  ImageUploaderIcon,
  ImageUploaderText,
} from './DiscountModal.styles';

interface FormValues {
  title: string;
  amountPercent: number;
  category: string;
  status: string;
  startDate: string;
  expirationDate: string;
  timezone: string;
  terms: string;
  imageId?: string;
  images?: Array<ResultFile>;
  type?: string;
}

type Props = {
  onModalClose: () => void;
  isModalOpen: boolean;
  selectedDiscount?: AdminDiscountFragment;
  onDiscountsChange?: () => Promise<unknown> | unknown;
};

const MINUTES_STEP = 30;

export function DiscountModal({
  isModalOpen,
  onModalClose,
  selectedDiscount: selected,
  onDiscountsChange,
}: Props) {
  const { onDiscountCreate, onDiscountUpdate } = useDiscountInteraction();
  const { data: school } = useSchoolConfiguration();
  const { showError } = useToast();

  const disableChanges =
    selected?.status === DISCOUNT_STATUSES.expired ||
    selected?.status === DISCOUNT_STATUSES.rejected;

  const minExpirationDate = useMemo(() => {
    return DateTime.now().set({ minute: 0 }).plus({ hours: 1 });
  }, []);

  const minStartDate = useMemo(() => {
    return DateTime.now().set({ minute: 0 }).plus({ hours: 1, minutes: MINUTES_STEP });
  }, []);

  const initial: FormValues = useMemo(() => {
    return {
      title: selected?.title || '',
      amountPercent: selected?.amountPercent || 0,
      expirationDate: selected?.expirationDate || minExpirationDate,
      startDate: selected?.startDate || minStartDate,
      timezone: selected?.timezone || 'America/Cancun',
      category: selected?.category || '',
      terms: selected?.terms || '',
      status: selected?.status || DISCOUNT_STATUSES.pending,
      images: selected?.images?.items ?? [],
      type: selected?.type ?? 'discount',
    };
  }, [selected, minExpirationDate, minStartDate]);

  const statuses = useMemo(() => {
    if (selected && disableChanges) {
      return [selected.status as string];
    }

    if (selected) {
      return [DISCOUNT_STATUSES.active, DISCOUNT_STATUSES.inactive];
    }

    return [DISCOUNT_STATUSES.pending, DISCOUNT_STATUSES.active];
  }, [selected, disableChanges]);

  const handleSubmit = async (form: FormValues) => {
    const expirationDate = DateTime.fromISO(form.expirationDate).toISO();
    const startDate = DateTime.fromISO(form.startDate).toISO();

    if (!school?.id) {
      showError('No school configuration found');
      return;
    }

    if (disableChanges && form.status === DISCOUNT_STATUSES.active) {
      showError("Can't active an expired discount");
      return;
    }

    const amountPercent = form.type === DISCOUNT_TYPES.free ? 0 : Number(form.amountPercent);
    const images = fileManyRelations(form.images, initial.images);

    const data: DiscountCreateRequestInput = {
      title: form.title,
      type: form.type,
      category: form.category,
      status: form.status,
      terms: form.terms,
      timezone: form.timezone,
      images,
      amountPercent,
      expirationDate,
      startDate,
      // https://github.com/jebelapp/jebel/issues/1567
      schools: { connect: [{ id: school.id }] },
    };

    if (selected?.id) {
      const payload: DiscountUpdateInput = { id: selected?.id, ...data };

      await onDiscountUpdate(payload);
      await onDiscountsChange?.();

      onModalClose();
      return;
    }

    await onDiscountCreate(data);
    await onDiscountsChange?.();

    onModalClose();
  };

  return (
    <Modal
      dialogProps={{ open: isModalOpen, onClose: onModalClose }}
      titleProps={{ title: selected ? 'Edit Discount' : 'Create Discount' }}
      isVisibleDivider
    >
      <Form onSubmit={handleSubmit} initialValues={initial}>
        {({ values, isSubmitting, setFieldValue, setFieldError, errors }) => {
          const amountLabel = DISCOUNT_TYPES_LABELS[values.type] ?? 'Amount';

          if (values.type === 'free' && errors.offerValue) {
            setFieldError('offerValue', undefined);
          }

          return (
            <Container>
              <FormTextField
                inputProps={{
                  autoComplete: 'off',
                  label: 'Title',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'title', validate: required }}
              />

              <Box mt={2} display="grid">
                <FormSelect
                  selectProps={{
                    children: DISCOUNT_TYPE_OPTIONS.map(({ label, value }) => (
                      <MenuItem key={label} value={value}>
                        {label}
                      </MenuItem>
                    )),
                    variant: 'outlined',
                    size: 'medium',
                    label: 'Discount Type',
                  }}
                  fieldProps={{ name: 'type' }}
                />
              </Box>

              {values.type !== 'free' && (
                <Box mt={2} display="grid">
                  <FormTextField
                    inputProps={{
                      color: 'primary',
                      label: amountLabel,
                      variant: 'outlined',
                      type: 'number',
                    }}
                    fieldProps={{
                      name: 'amountPercent',
                      validate: value => isValidAmountDiscount(value, values.type),
                    }}
                  />
                </Box>
              )}

              <Box mt={2} display="grid">
                <FormSelect
                  selectProps={{
                    variant: 'outlined',
                    label: 'Category',
                    children: DISCOUNT_CATEGORIES.map(({ key, value }) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    )),
                  }}
                  fieldProps={{ name: 'category', validate: required }}
                />
              </Box>

              <Box mt={2} display="grid">
                <FormSelect
                  selectProps={{
                    variant: 'outlined',
                    label: 'Status',
                    disabled: disableChanges,
                    children: statuses.map((variant: string) => (
                      <MenuItem key={variant} value={variant}>
                        {DISCOUNT_STATUS_LABELS[variant]}
                      </MenuItem>
                    )),
                  }}
                  fieldProps={{ name: 'status', validate: required }}
                />
              </Box>

              <Box mt={2} display="grid">
                <FormKeyboardDateTimePicker
                  dateTimePickerProps={{
                    allowKeyboardControl: true,
                    inputVariant: 'outlined',
                    label: 'START DATE',
                    timeLabel: 'START TIME',
                    variant: 'inline',
                    minDate: minStartDate,
                    disabled: disableChanges,
                  }}
                  fieldProps={{ name: 'startDate', validate: required }}
                />
              </Box>

              <Box mt={2} display="grid">
                <FormKeyboardDateTimePicker
                  dateTimePickerProps={{
                    allowKeyboardControl: true,
                    inputVariant: 'outlined',
                    label: 'EXPIRATION DATE',
                    timeLabel: 'EXPIRATION TIME',
                    variant: 'inline',
                    minDate: minExpirationDate,
                    disabled: disableChanges,
                  }}
                  fieldProps={{
                    name: 'expirationDate',
                    validate: isCorrectDateInterval(values.startDate, { minute: MINUTES_STEP }),
                  }}
                />
              </Box>

              <Box mt={2} display="grid">
                <FormSelect
                  selectProps={{
                    variant: 'outlined',
                    label: 'Timezone',
                    children: US_TIMEZONES_OPTIONS.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )),
                  }}
                  fieldProps={{ name: 'timezone', validate: required }}
                />
              </Box>

              <Box mt={2} mb={1} display="grid">
                <FormTextField
                  inputProps={{
                    autoComplete: 'off',
                    label: 'Terms',
                    variant: 'outlined',
                    multiline: true,
                    rows: '5',
                    rowsMax: '5',
                  }}
                  fieldProps={{ name: 'terms', validate: required }}
                />
              </Box>

              <Box display="flex" flexDirection="column" justifyContent="start" mb={2}>
                <Box display="flex" justifyContent="flex-start" flexDirection="column" mb={1}>
                  <Typography variant="body1" align="left">
                    Images
                  </Typography>

                  <Typography variant="caption" align="left">
                    Upload recommendations 1440x810 (16:9)
                  </Typography>
                </Box>

                <ImageInput
                  name="logo"
                  label="Images"
                  height={80}
                  width={80}
                  maxFiles={1}
                  initialValue={initial?.images}
                  onChange={images => {
                    setFieldValue('images', Array.isArray(images) ? images : [images]);
                  }}
                  customPicker={
                    <ImageUploaderContainer>
                      <ImageUploaderIcon />
                      <ImageUploaderText>Upload images here</ImageUploaderText>
                    </ImageUploaderContainer>
                  }
                />
              </Box>

              <Box display="flex" justifyContent="end" gridGap={8}>
                <Button onClick={onModalClose} disabled={isSubmitting} size="medium">
                  CANCEL
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disableElevation
                >
                  {selected?.id ? 'SAVE CHANGES' : 'CREATE DISCOUNT'}
                </Button>
              </Box>
            </Container>
          );
        }}
      </Form>
    </Modal>
  );
}
