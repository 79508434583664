import { Fragment, useMemo } from 'react';

import { OrganizationItemFragment } from 'shared/graphql';
import { SUPPORTERS_RESULT_SIZE } from 'features/explorer/constants';
import { ExplorerBusinessCard } from 'features/explorer/components';
import { useResponsive } from 'shared/hooks';

interface Props {
  supportersList: OrganizationItemFragment[];
  /** @default true */
  viewAll?: boolean;
}

export function ExplorerSupportersList(props: Props) {
  const { isTablet } = useResponsive();

  const supporters = useMemo(() => {
    return props.supportersList ?? [];
  }, [props.supportersList]);

  const showRest = props.viewAll ?? true;

  const renderCard = (item: OrganizationItemFragment) => {
    return (
      <ExplorerBusinessCard
        key={item?.id}
        data={item}
        variant={isTablet ? 'grid' : 'list'}
        withMessage
        withViewProfile
      />
    );
  };

  if (showRest) {
    return <Fragment>{supporters.map(renderCard)}</Fragment>;
  }

  return <Fragment>{supporters.slice(0, SUPPORTERS_RESULT_SIZE).map(renderCard)}</Fragment>;
}
