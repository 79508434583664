import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Typography } from '@material-ui/core';

const typographyCSS = css`{{ symbolProps?.css }}`;

type HeadingSymbolProps = {
  classes?: Record<string, any>;
  style?: Record<string, any>;
  css?: SerializedStyles;
  className?: string;
  onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseOver?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseOut?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseDown?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseUp?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseEnter?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseLeave?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onWheel?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onContextMenu?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onAuxClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  key?: number;
  children?: React.ReactNode;
  variant?: any;
  align?: any;
  color?: any;
  gutterBottom?: boolean;
  noWrap?: boolean;
  component?: any;
};

export const HeadingSymbol: React.FC<HeadingSymbolProps> = symbolProps => {
  return (
    <Typography
      component={symbolProps.component}
      paragraph={false}
      classes={symbolProps?.classes}
      style={symbolProps?.style}
      css={symbolProps?.css}
      className={symbolProps?.className}
      onClick={symbolProps?.onClick}
      onMouseOver={symbolProps?.onMouseOver}
      onMouseOut={symbolProps?.onMouseOut}
      onMouseDown={symbolProps?.onMouseDown}
      onMouseUp={symbolProps?.onMouseUp}
      onMouseEnter={symbolProps?.onMouseEnter}
      onMouseLeave={symbolProps?.onMouseLeave}
      onWheel={symbolProps?.onWheel}
      onContextMenu={symbolProps?.onContextMenu}
      onAuxClick={symbolProps?.onAuxClick}
      variant={symbolProps.variant}
      align={symbolProps.align}
      color={symbolProps.color}
      gutterBottom={symbolProps.gutterBottom}
      noWrap={symbolProps.noWrap}
    >
      {symbolProps.children}
    </Typography>
  );
};
