import { JOB_EMPLOYMENT_TYPE } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { JobOfferFilter, SchoolConfigurationKeyFilter } from 'shared/graphql';

import { createOrganizationFilter } from './createOrganizationFilter';

interface Options {
  initial?: JobOfferFilter;

  search?: string;

  /**
   * Filter by the current school.
   */
  school?: SchoolConfigurationKeyFilter;

  /**
   * @default false
   */
  skipInternships?: boolean;

  /**
   * @default false
   */
  skipFullTime?: boolean;

  /**
   * Filter expired jobs based on the current date.
   * Should be formatted as an ISO date string.
   */
  nonExpiredJobsDate?: string;
}

/**
 * Create a filter for job offers.
 */
export function createJobOfferFilter(options?: Options) {
  const filter = createFilterBuilder<JobOfferFilter>();

  if (options?.nonExpiredJobsDate) {
    // https://8base-dev.atlassian.net/browse/JEB-1303
    filter.and({ createdAt: { gte: options.nonExpiredJobsDate } });
  }

  if (options?.skipInternships) {
    filter.and({ employmentType: { not_equals: JOB_EMPLOYMENT_TYPE.intern } });
  }

  if (options?.skipFullTime) {
    filter.and({ employmentType: { not_equals: JOB_EMPLOYMENT_TYPE.fullTime } });
  }

  if (options?.search) {
    const search = options.search.trim();
    const searchFilter = createFilterBuilder<JobOfferFilter>();

    searchFilter.or({ position: { contains: search } });
    searchFilter.or({ description: { contains: search } });
    searchFilter.or({ createdBy: { firstName: { contains: search } } });
    searchFilter.or({ createdBy: { lastName: { contains: search } } });
    searchFilter.or({ createdBy: { fullName: { starts_with: search } } });
    searchFilter.or({ organization: createOrganizationFilter({ search }) });

    filter.and(searchFilter);
  }

  if (options?.school?.id) {
    // https://github.com/jebelapp/jebel/issues/1568
    filter.and({
      schools: {
        some: {
          id: { equals: options.school.id },
        },
      },
    });
  }

  return filter.build();
}
