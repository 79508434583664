import { gql, useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { createSnackMessage, SNACK_TYPES } from 'shared/components/ui';
import { FormDataReset, ResetLayout } from 'shared/components/layouts/ResetLayout';
import { APP_URL } from 'shared/constants';
import { sendToSentry } from 'shared/utils/sentry';

const CHECK_RESET_PASSWORD_CODE_QUERY = gql`
  query CheckResetPasswordCode($data: CheckResetPasswordCodeInput!) {
    checkResetPasswordCode(data: $data) {
      email
    }
  }
`;

const USER_CHANGE_PASSWORD_MUTATION = gql`
  mutation UserChangePassword($data: UserForgotPasswordChangeInput!) {
    userForgotPasswordChange(data: $data) {
      success
    }
  }
`;

export const ChangePasswordPage: React.FC = () => {
  const history = useHistory();
  const { resetPasswordCode } = useParams<{ resetPasswordCode: string }>();
  const [resetError, setResetError] = React.useState<string>('');

  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async (data: FormDataReset) => {
    setResetError('');

    let userConfiguration;

    try {
      userConfiguration = await client.query({
        query: CHECK_RESET_PASSWORD_CODE_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            resetPasswordCode,
          },
        },
      });
    } catch (error: any) {
      sendToSentry(`You don't have permissions to change password for that user. ${error}`);
      return setResetError("You don't have permissions to change password for that user.");
    }

    const email = userConfiguration?.data.checkResetPasswordCode.email;

    if (data.password !== data.confirmPassword) {
      return setResetError("The passwords don't match!");
    }

    await client.mutate({
      mutation: USER_CHANGE_PASSWORD_MUTATION,
      fetchPolicy: 'no-cache',
      variables: {
        data: {
          email,
          proposedPassword: data.password,
          confirmProposedPassword: data.confirmPassword,
        },
      },
    });

    enqueueSnackbar('Success! Your password has been changed.', {
      autoHideDuration: 5000,
      content: createSnackMessage(SNACK_TYPES.success),
    });

    history.push(APP_URL.public.login);
  };

  return <ResetLayout onSubmit={handleSubmit} resetError={resetError} />;
};
