import React from 'react';
import * as R from 'ramda';
import { CircularProgressProps, Box, BoxProps } from '@mui/material';

import { Preloader } from 'shared/components/ui';
import { CenterLayout } from 'shared/components/layouts';

import { ComponentDataProps } from '../../types';

type AsyncContentProps = ComponentDataProps & {
  stretch?: boolean;
  center?: boolean;
  loading?: boolean;
  boxProps?: BoxProps;
  circularProgressProps?: CircularProgressProps;
  height?: number;
};

const STRETCH_PROPS: BoxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  padding: '30px',
};

/** @deprecated Use `LoadingOverlay` instead. */
export const AsyncContent: React.FC<AsyncContentProps> = ({
  children,
  loading,
  stretch = true,
  center = false,
  boxProps = {},
  circularProgressProps = {},
  height,
}) => {
  const stretchProps = stretch ? STRETCH_PROPS : {};

  const resultBoxProps: BoxProps = {
    ...boxProps,
    ...stretchProps,
  };

  if (loading || !children || R.isEmpty(children)) {
    if (center) {
      return (
        <CenterLayout {...resultBoxProps}>
          <Preloader circularProgressProps={circularProgressProps} />
        </CenterLayout>
      );
    }
    return (
      <Box sx={{ height }} {...resultBoxProps}>
        <Preloader circularProgressProps={circularProgressProps} />
      </Box>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
