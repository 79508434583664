import { useMemo } from 'react';

import { SpreadsheetStatsProps } from 'shared/components/ui/SpreadsheetStats';
import { useDiscountsStatsQuery } from 'shared/graphql';

import { DISCOUNT_ACTIVATION_DAYS_COUNT } from '../constants';

export const useDiscountsStats = () => {
  const { data, refetch } = useDiscountsStatsQuery({
    variables: { totalDiscountsDaysCount: DISCOUNT_ACTIVATION_DAYS_COUNT },
  });

  const { totalReedemed, activeDiscounts, pendingDiscounts } = useMemo(
    () => ({
      totalReedemed:
        data?.totalDiscounts.items.reduce((sum, discount) => sum + (discount?.count || 0), 0) || 0,
      activeDiscounts: data?.activeDiscounts.count || 0,
      pendingDiscounts: data?.pendingDiscounts.count || 0,
    }),
    [data],
  );

  const discountStats: SpreadsheetStatsProps['data'] = [
    { label: 'Total Active Discounts', value: activeDiscounts },
    { label: 'Total Discounts Redeemed (last 30 days)', value: totalReedemed },
    { label: 'Pending Approval', value: pendingDiscounts, color: 'secondary' },
  ];

  return { discountStats, refetchDiscountsStats: refetch };
};
