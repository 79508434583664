import { Suspense, lazy } from 'react';
import { RouteLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';

const SchoolPostsContent = lazy(() => import('../components/SchoolPostsContent'));

export function SchoolPostsPage() {
  return (
    <RouteLayout
      withMaxWidth
      content={
        <Suspense fallback={<FeedSkeleton />}>
          <SchoolPostsContent />
        </Suspense>
      }
    />
  );
}
