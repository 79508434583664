import { GroupCreateInput, useGroupCreateRequestMutation } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';

export function useGroupCreateRequest() {
  const { data: school } = useSchoolConfiguration();

  const [mutate, params] = useGroupCreateRequestMutation({
    refetchQueries: ['GroupsList'],
  });

  const onGroupCreateRequest = (data: GroupCreateInput) => {
    if (!school) {
      return;
    }

    return mutate({
      variables: {
        data: {
          ...data,

          school: {
            connect: { id: school.id },
          },
        },
      },
    });
  };

  return {
    onGroupCreateRequest,

    ...params,
  };
}
