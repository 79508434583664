import { useMemo } from 'react';

import { OrganizationFilter } from 'shared/graphql';
import { useRandomOrganizations } from './useRandomOrganizations';
import { useSchoolConfiguration } from 'shared/hooks';
import { createFilterBuilder } from '@jebel/utils';

type Options = Parameters<typeof useRandomOrganizations>[0];

/**
 * Generates a list of school supporters sorted randomly every time that's executed.
 * @ticket https://8base-dev.atlassian.net/browse/JEB-1457
 * @ticket https://github.com/jebelapp/jebel/issues/1565
 * @returns List of school supporters sorted randomly.
 */
export function useRandomSchoolSupporters(options?: Options) {
  const { data: school } = useSchoolConfiguration();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<OrganizationFilter>({
      schoolSupporter: {
        some: {
          school: {
            // https://github.com/jebelapp/jebel/issues/1565
            id: { equals: school?.id },
          },
        },
      },
    });

    return filter.build();
  }, [school]);

  const { data, count, loading } = useRandomOrganizations({
    ...options,

    variables: { ...options?.variables, filter },
  });

  return {
    data,
    count,
    loading,
  };
}
