import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const contentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const contentPaddingCSS = css`
  padding: 1rem 2rem;
`;

export const Header = styled.div`
  ${contentCSS};
  ${contentPaddingCSS};

  border-bottom: 1px solid ${p => p.theme.palette.divider};
`;

export const Content = styled.div`
  ${contentCSS};
  ${contentPaddingCSS};
`;

export const Section = styled.div`
  ${contentCSS};
`;

export const SectionTitle = styled(Typography)`
  font-weight: bold;
`;

export const PresetOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  ${p => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
