import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import { Paper } from '@material-ui/core';

import {
  BuilderComponentsBoxUserSymbol4,
  BuilderComponentsBoxUserSymbol1,
} from 'shared/components/symbols';
import { Box } from 'shared/components/ui';

const supportersCSS = css`
  padding: 30px 30px 25px 30px;
  display: grid;
  grid-gap: 25px;
`;

const SUPPORTERS_ICON_SIZE = 25;

const supportersIcon = css`
  height: ${SUPPORTERS_ICON_SIZE}px;
  width: ${SUPPORTERS_ICON_SIZE}px;
  display: block;
`;

const supportersList = css`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr;
`;

// TODO: implement EventsSupporters component (JEB-421)
export const EventsSupporters: React.FC = () => {
  return (
    <Paper elevation={1} variant="elevation">
      <Box css={supportersCSS}>
        <BuilderComponentsBoxUserSymbol4 title="Supporters">
          <img
            css={supportersIcon}
            src="https://cdn.filestackcontent.com/security=policy:eyJjYWxsIjpbInJlYWQiLCJjb252ZXJ0Il0sImV4cGlyeSI6MjUyNDYwODAwMCwiaGFuZGxlIjoiM0pKdlFoeFFNV1pzMmtKYlZPOE4ifQ==,signature:483b75a8bfca3ed0b84c0fd32e30913f378c4dbc7c52e507434564b8ac0c1007/3JJvQhxQMWZs2kJbVO8N"
            width={100}
          />
        </BuilderComponentsBoxUserSymbol4>
        <Box css={supportersList}>
          {[1, 2, 3]?.map(item => (
            <Fragment key={item}>
              <BuilderComponentsBoxUserSymbol1
                img="https://cdn.filestackcontent.com/security=policy:eyJjYWxsIjpbInJlYWQiLCJjb252ZXJ0Il0sImV4cGlyeSI6MjUyNDYwODAwMCwiaGFuZGxlIjoiWU1LVE5ERWJRdGVpTGh5SjU1ZFgifQ==,signature:b2117290b745d40ea17233e4e4f68fd72248f5a8d3731f03cf3fef1d93444ad4/YMKTNDEbQteiLhyJ55dX"
                title="8base"
                body="The best wasdasday to build, run anasdasdd grow professional-grade web and mobile...."
                link=""
              />
            </Fragment>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};
