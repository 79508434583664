import { FC, Fragment, MouseEvent, PropsWithChildren, ReactNode, useState } from 'react';
import {
  ButtonProps,
  ClickAwayListener as Listener,
  PopoverProps as BaseProps,
} from '@material-ui/core';

import { Content, Popover } from './Popper.styles';

interface Props {
  popperProps?: BaseProps;
  onClick?: (event: MouseEvent) => void;
  onClose?: () => void;
  targetTrigger: FC<ButtonProps>;
  targetContent: ReactNode;
  targetProps?: ButtonProps;
}

export function Popper({
  popperProps,
  onClick,
  onClose,
  targetTrigger: TriggerButton,
  targetContent,
  targetProps,
  children,
}: PropsWithChildren<Props>) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isOpen, setOpen] = useState(false);

  const handleClick = (event: MouseEvent) => {
    anchorEl ? handleClose() : handleOpen(event);
  };

  const handleOpen = (event: MouseEvent) => {
    onClick?.(event);
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onClose?.();
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Listener onClickAway={handleClose}>
        <TriggerButton {...targetProps} onClick={handleClick}>
          {targetContent}
        </TriggerButton>
      </Listener>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...popperProps}
        anchorEl={anchorEl}
        open={isOpen}
      >
        <Content onClick={e => e.stopPropagation()}>{children}</Content>
      </Popover>
    </Fragment>
  );
}
