import { lazy, Suspense, useMemo } from 'react';
import { EChartsOption as ChartOptions } from 'echarts';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@emotion/react';

import { DountChartProps } from './DountChart.types';
import { createDountChartOptions } from './createDountChartOptions';

const Chart = lazy(() => import('echarts-for-react'));

export function DountChart(props: DountChartProps) {
  const theme = useTheme();

  const options = useMemo<ChartOptions>(() => {
    return createDountChartOptions(props, theme);
  }, [props, theme]);

  return (
    <Suspense fallback={<Skeleton width="100%" height="5rem" />}>
      <Chart
        option={options}
        lazyUpdate
        style={{ width: props.size ?? '100%', height: props.size ?? '100%' }}
      />
    </Suspense>
  );
}
