import { gql } from '@apollo/client';

// NOTE: Use the same fragment either client-side and server-side to avoid apollo cache mismatches.
export const MEMBER_INFO_FRAGMENT = gql`
  fragment MemberInfo on User {
    id
    firstName
    lastName
    email
    affiliation
    userStatus
    hobbies
    activityClubs
    birthDate
    gender
    graduatingYear
    professionalDesignations
    createdAt

    avatar {
      id
      fileId
      downloadUrl
    }

    rolesAddons {
      items {
        id
        name
      }
    }

    roles {
      items {
        id
        name
      }
    }

    userConfiguration {
      id
      accessCodeHash
    }

    userPreferences {
      id
      phoneNumber
      address {
        id
        zip
      }
    }

    groupPosts(filter: { status: { equals: "approved" }, isFlagged: { equals: false } }) {
      count
    }

    homeFeedPosts(filter: { status: { equals: "approved" }, isFlagged: { equals: false } }) {
      count
    }

    # Count all their organizations that have school support
    ownedOrganizations(
      filter: {
        schoolSupporter: { some: { id: { is_not_empty: true }, status: { equals: "active" } } }
      }
    ) {
      count
    }
  }
`;

export const MEMBERS_STATS_QUERY = gql`
  query MemberStats($filter: UserFilter) {
    affiliations: usersList(
      filter: $filter
      groupBy: {
        query: { id: { as: "count", fn: { aggregate: COUNT } }, affiliation: { as: "affiliation" } }
      }
    ) {
      totalCount: count
      groups {
        affiliation: String
        count: Int
      }
    }
  }
`;

export const MEMBERS_WITH_ZIP_LIST_QUERY = gql`
  query MembersWithZipList(
    $first: Int
    $skip: Int
    $filter: UserFilter
    $sort: [UserSort!]
    $startPointZip: String!
    $radius: String!
  ) {
    members: membersFilterByZipRequest(
      first: $first
      skip: $skip
      filter: $filter
      sort: $sort
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
      items {
        ...MemberInfo
      }
    }
  }

  ${MEMBER_INFO_FRAGMENT}
`;

export const MEMBERS_WITH_ZIP_LIST_COUNT_QUERY = gql`
  query MembersWithZipListCount($filter: UserFilter, $startPointZip: String!, $radius: String!) {
    membersFilterByZipRequest(filter: $filter, startPointZip: $startPointZip, radius: $radius) {
      count
    }
  }
`;

export const TOGGLE_ACTIVITY_STATUS_MUTATION = gql`
  mutation ToggleActivityStatus($id: ID!, $status: String!) {
    userUpdate(data: { id: $id, userStatus: $status }) {
      id
    }
  }
`;

/**
 * Only users with the role of "School Admin" can invoke this mutation.
 */
export const ACCEPT_SCHOOL_MEMBER_MUTATION = gql`
  mutation AcceptSchoolMember($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    acceptSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;

/**
 * Only users with the role of "School Admin" can invoke this mutation.
 */
export const REJECT_SCHOOL_MEMBER_MUTATION = gql`
  mutation RejectSchoolMember($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    rejectSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;

/**
 * Only users with the role of "School Admin" can invoke this mutation.
 */
export const RESEND_SCHOOL_APPROVAL_MUTATION = gql`
  mutation ResendSchoolApproval($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    resendSchoolApprovals(filter: $filter, school: $school) {
      success
    }
  }
`;
