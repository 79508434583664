import { SchoolConfigurationFragment, useSchoolConfigurationsQuery } from 'shared/graphql';

type Options = Parameters<typeof useSchoolConfigurationsQuery>[0];

/**
 * Hook for fetching school configurations.
 * @ticket https://github.com/jebelapp/jebel/issues/1562
 */
export function useSchoolConfigurations(options?: Options) {
  const { data: response, loading } = useSchoolConfigurationsQuery(options);

  const schools: SchoolConfigurationFragment[] = response?.schools?.items ?? [];

  return {
    data: schools,
    loading,
  };
}
