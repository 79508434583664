import { PropsWithChildren, useEffect, useState } from 'react';

import { usePersistentState } from 'shared/hooks';

import type { SearchContextValue } from '../types';
import { SearchContext, DEFAULT_CONTEXT_VALUE } from './SearchContext';

export type SearchContextProps<T, Z> = {
  defaultSortOption?: SearchContextValue<T>['sortOption'];
  /** @default true */
  defaultDisplayList?: boolean;
  defaultFilterOption?: SearchContextValue<T>['filter'];

  /** Allow to save the state of the `displayList` value in local storage. Left empty to DO NOT persist. */
  displayListPersistence?: string | false;

  searchQuery?: string;

  zipFilter?: Z;
};

export function SearchProvider<T, D = any, Z = any>(
  props: PropsWithChildren<SearchContextProps<T, Z>>,
) {
  const [isUserTyping, setIsUserTyping] = useState(false);

  const [sortOption, setSortOption] = useState(
    props.defaultSortOption ?? DEFAULT_CONTEXT_VALUE.sortOption,
  );

  const [searchQuery, setSearchQuery] = useState<string>(props.searchQuery ?? '');
  const [filter, setFilter] = useState<T>(props.defaultFilterOption as T);
  const [dateFilter, setDateFilter] = useState<D>();
  const [zipQuery, setZipQuery] = useState('');
  const [zipFilter, setZipFilter] = useState<Z>(() => props.zipFilter as Z);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { value: displayList, setValue: setDisplayList } = usePersistentState<boolean>({
    initial: props.defaultDisplayList,
    key: props.displayListPersistence || undefined,
  });

  useEffect(() => {
    if (typeof props.searchQuery === 'string') {
      setSearchQuery(props.searchQuery);
    }
  }, [props.searchQuery]);

  const openFilterModal = () => setIsFilterModalOpen(true);

  const closeFilterModal = () => setIsFilterModalOpen(false);

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        isUserTyping,
        setIsUserTyping,
        displayList: displayList ?? props.defaultDisplayList ?? false,
        sortOption,
        setSortOption,
        setDisplayList,
        filter,
        setFilter,
        dateFilter,
        setDateFilter,
        zipQuery,
        setZipQuery,
        zipFilter,
        setZipFilter,
        isFilterModalOpen,
        setIsFilterModalOpen,
        openFilterModal,
        closeFilterModal,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
}
