import React, { forwardRef, useState } from 'react';
import { css } from '@emotion/react';
import { Chip } from '@material-ui/core';

import { Select, SelectProps } from '../Select';

export type MultiselectProps = Omit<SelectProps, 'value'> & {
  value?: string[];
  renderValue?: (value?: string[]) => React.ReactNode;
};

const chipCss = (theme: any) => css`
  background-color: rgba(9, 167, 250, 0.2);
  border-radius: 4px;
  color: ${theme.palette.primary.light};
  margin-right: ${theme.spacing(0.5)}px;
`;

export const Multiselect = forwardRef<HTMLDivElement, MultiselectProps>(
  ({ multiple = true, value = [], onChange, ...otherMultiselectProps }, ref) => {
    const [localValue, setLocalValue] = useState<string[]>(value);

    const handleChange = (
      event: React.ChangeEvent<{ name?: string; value: unknown }>,
      children: React.ReactNode,
    ) => {
      setLocalValue(event.target.value as string[]);
      onChange?.(event, children);
    };

    return (
      <Select
        ref={ref}
        multiple={multiple}
        value={localValue}
        onChange={handleChange}
        {...otherMultiselectProps}
        renderValue={(selected: unknown) =>
          (selected as string[]).map(selectedValue => (
            <Chip key={selectedValue} label={selectedValue} css={chipCss} />
          ))
        }
      />
    );
  },
);
