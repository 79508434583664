import { forwardRef, lazy, Ref, Suspense } from 'react';
import { Skeleton } from '@material-ui/lab';

import { ComponentDataProps } from '../../types';
import { MaterialIconName, MaterialIconProps } from './MaterialIcons';

export type MaterialIconNames = MaterialIconName;

export type IconProps = Omit<MaterialIconProps, 'name'> &
  ComponentDataProps & { name?: MaterialIconNames | string; variant?: 'filled' | 'outline' };

const MaterialIcons = lazy(() => import('./MaterialIcons'));

/**
 * Dynamic `Icon` component that loads the icon from the [Material Icons](https://mui.com/material-ui/material-icons/) library.
 * Do not use this component if you already know the icon you want to use, use the import directly instead.
 * Use this component when you need to dynamically load an icon based on a prop or a variable.
 */
export const Icon = forwardRef(
  ({ name, variant = 'filled', ...props }: IconProps, ref: Ref<SVGSVGElement>) => {
    const suffix = variant === 'filled' ? '' : capitalize(variant);
    const fullname = `${name}${suffix}` as MaterialIconName;

    return (
      <Suspense fallback={<Skeleton variant="rect" width={26} height={26} />}>
        <MaterialIcons {...props} ref={ref} name={fullname} />
      </Suspense>
    );
  },
);

const capitalize = (value = ''): string => {
  const head = value[0] || '';
  const rest = value.slice(1) || '';
  return head.toUpperCase() + rest;
};
