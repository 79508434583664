import { Button, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { useMemo } from 'react';

import { Stack } from 'shared/components/ui';
import { SliderField, SwitchField } from 'shared/components/form';
import { useSchoolConfiguration, useSchoolConfigurationUpdate, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

import {
  Container,
  Content,
  Footer,
  Header,
  PresetOptions,
  Section,
  SectionTitle,
} from './SettingsSchoolContentModeration.styles';
import { SettingsSchoolContentModerationThresholdPreset } from './SettingsSchoolContentModerationThresholdPreset';

interface FormValues {
  threshold: number;
  moderateSchoolPost: boolean;
  moderateLegacyPost: boolean;
}

interface ThresholdPreset {
  title: string;
  description: string;
  subtitle?: string;
  value: number;
}

const DEFAULT_THRESHOLD = 0.5;

const THRESHOLD_PRESETS: ThresholdPreset[] = [
  {
    title: 'Balanced',
    description: 'This is a low threshold. We recommend this for most schools.',
    value: 0.5,
  },
  {
    title: 'Liberal',
    description: 'Minimze false positives.',
    value: 0.25,
  },
  {
    title: 'Convervative',
    description: 'Minimize false negatives.',
    value: 0.75,
  },
];

const MESSAGE_SAVING_KEY = 'content-moderation-threshold-saving';

/**
 * Setup the content moderation settings for the school.
 * @ticket https://github.com/8base-services/jebel/issues/1501
 */
export function SettingsSchoolContentModeration() {
  const { data: school, loading: isSchoolLoading } = useSchoolConfiguration();
  const { mutate } = useSchoolConfigurationUpdate();
  const { showMessage, showError, showSuccess, dismiss } = useToast();

  const isLoading = isSchoolLoading;

  const initials = useMemo<FormValues>(() => {
    return {
      threshold: school?.moderationThreshold ?? DEFAULT_THRESHOLD,
      moderateLegacyPost: school?.moderateLegacyPosts ?? false,
      moderateSchoolPost: school?.moderateSchoolPosts ?? false,
    };
  }, [school]);

  const handleSubmit = async (values: FormValues) => {
    showMessage('Saving content moderation threshold, please stand by.', {
      id: MESSAGE_SAVING_KEY,
    });

    try {
      await mutate({
        moderationThreshold: values.threshold,
        moderateSchoolPosts: values.moderateSchoolPost,
        moderateLegacyPosts: values.moderateLegacyPost,
      });

      showSuccess('Content moderation threshold has been saved.');
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    dismiss(MESSAGE_SAVING_KEY);
  };

  return (
    <Container>
      <Header>
        <Typography variant="h6">Content Auto-Moderation</Typography>
      </Header>

      <Formik initialValues={initials} enableReinitialize onSubmit={handleSubmit}>
        {form => (
          <Content>
            <Section>
              <SectionTitle>Auto-Moderation Threshold</SectionTitle>

              <Typography>
                The automated content moderation engine assigns a score between 0 and 1 to each
                submitted image or video, indicating the likelihood of containing content that
                should be blocked and reviewed (e.g., offensive content, nudity, violence, drugs,
                gore). You can set an auto-rejection threshold here. Content with a score above this
                threshold will be rejected and flagged for review in the Content Curation section;
                content with a score below the threshold will be automatically published. For
                example, setting a threshold of 0.50 means anything scoring above 0.50 will be
                auto-rejected, while content scoring below 0.50 will be published.
              </Typography>

              <PresetOptions>
                {THRESHOLD_PRESETS.map(preset => (
                  <SettingsSchoolContentModerationThresholdPreset
                    key={preset.title}
                    title={preset.title}
                    description={preset.description}
                    checked={form.values.threshold === preset.value}
                    onCheck={() => form.setFieldValue('threshold', preset.value)}
                  />
                ))}

                <SettingsSchoolContentModerationThresholdPreset
                  title="Custom"
                  description="Set your own threshold by dragging the slider."
                  checked={
                    !THRESHOLD_PRESETS.some(preset => form.values.threshold === preset.value)
                  }
                  onCheck={() => form.setFieldValue('threshold', 0)}
                />
              </PresetOptions>

              <SliderField
                name="threshold"
                min={0}
                max={1}
                step={0.05}
                valueLabelDisplay="auto"
                marks
              />
            </Section>

            <Section>
              <SectionTitle>Auto-Moderated Content</SectionTitle>

              <SwitchField
                name="moderateSchoolPost"
                label="Moderate School Posts"
                helperText={
                  <span>
                    Enable this option to automatically moderate <strong>School Posts</strong>.
                  </span>
                }
              />

              <SwitchField
                name="moderateLegacyPost"
                label="Moderate Legacy Posts"
                helperText={
                  <span>
                    Enable this option to automatically moderate <strong>Legacy Posts</strong>.
                  </span>
                }
              />
            </Section>

            <Footer>
              <Stack direction="row" spacing={1}>
                <Button
                  disabled={isLoading || !form.dirty || form.isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={form.submitForm}
                >
                  Save
                </Button>

                <Button
                  disabled={isLoading || !form.dirty || form.isSubmitting}
                  variant="outlined"
                  onClick={() => form.resetForm()}
                >
                  Cancel
                </Button>
              </Stack>
            </Footer>
          </Content>
        )}
      </Formik>
    </Container>
  );
}
