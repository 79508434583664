import React, { forwardRef } from 'react';
import { DropzoneArea, DropzoneAreaProps } from 'material-ui-dropzone';

import { ComponentDataProps } from '../../types';

export type FilePickerProps = DropzoneAreaProps & ComponentDataProps;

export const FilePicker = forwardRef<HTMLDivElement, FilePickerProps>(
  (
    {
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      ...otherFilePickerProps
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      >
        <DropzoneArea
          {...otherFilePickerProps}
          // temporary solution, waiting to fix: https://github.com/Yuvaleros/material-ui-dropzone/issues/265
          // right now maybe replace with user's own alert on change / on delete events
          showAlerts={false}
        />
      </div>
    );
  },
);
