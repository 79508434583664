import { Skeleton } from '@material-ui/lab';
import { Box, Button, Typography } from '@material-ui/core';

import { Container } from './DashboardPageSkeleton.styles';

export function DashboardPageSkeleton() {
  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton>
          <Typography variant="subtitle2">Lorem ipsum</Typography>
        </Skeleton>

        <Skeleton variant="rect">
          <Button variant="contained" color="primary">
            Lorem
          </Button>
        </Skeleton>
      </Box>

      <Skeleton>
        <Typography variant="h6">Lorem ipsum dolor sit, amet consectetur</Typography>
      </Skeleton>

      <Skeleton variant="rect" width="100%" height="30rem" />
    </Container>
  );
}
