export const NOTIFICATION_TEMPLATE_KEYS = {
    newPostReply: 'NEW_POST_REPLY',
    newPostLike: 'NEW_POST_LIKE',
    newCommentReply: 'NEW_COMMENT_REPLY',
    newCommentLike: 'NEW_COMMENT_LIKE',
    newCommentHelpful: 'NEW_COMMENT_HELPFUL',
    newCommentNotHelpful: 'NEW_COMMENT_NOT_HELPFUL',
    newTopicReply: 'NEW_TOPIC_REPLY',
    newMemberRequest: 'NEW_MEMBER_REQUEST',
    yourClassYearAdded: 'YOUR_CLASS_YEAR_ADDED',
    newClassYearMember: 'NEW_CLASS_YEAR_MEMBER',
    newDiscountRequest: 'NEW_DISCOUNT_REQUEST',
    newDiscount: 'NEW_DISCOUNT',
    newDiscountForOrganizationFollowers: 'NEW_DISCOUNT_FOR_ORGANIZATION_FOLLOWERS',
    newDiscountRedeemForOrganization: 'NEW_DISCOUNT_REDEEM_FOR_ORGANIZATION',
    newGroupApprovalRequest: 'NEW_GROUP_APPROVAL_REQUEST',
    newGroupMemberRequest: 'NEW_GROUP_MEMBER_REQUEST',
    groupMemberRejected: 'GROUP_MEMBER_REJECTED',
    groupMemberApproved: 'GROUP_MEMBER_APPROVED',
    newEventApprovalRequest: 'NEW_EVENT_APPROVAL_REQUEST',
    newFlaggedPostReviewRequest: 'NEW_FLAGGED_POST_REVIEW_REQUEST',
    yourPostWasFlagged: 'YOUR_POST_WAS_FLAGGED',
    newEditedPostReviewRequest: 'NEW_EDITED_POST_REVIEW_REQUEST',
    newOrganizationProfileLike: 'NEW_ORGANIZATION_PROFILE_LIKE',
    newDiscountAll: 'NEW_DISCOUNT_ALL',
    newOrganizationEvent: 'NEW_ORGANIZATION_EVENT',
    newBusinessApprovalRequest: 'NEW_BUSINESS_APPROVAL_REQUEST',
    businessPendingInvitationFourteenDays: 'BUSINESS_PENDING_INVITATION_14_DAYS',
    newContactOrganization: 'NEW_CONTACT_ORGANIZATION',
    newFlaggedPostApproveRequest: 'NEW_FLAGGED_POST_APPROVE',
    yourEventWasApproved: 'YOUR_EVENT_WAS_APPROVED',
    yourEventWasRejected: 'YOUR_EVENT_WAS_REJECTED',
    newInboxMessage: 'NEW_INBOX_MESSAGE',
    newFlaggedPostDeleteRequest: 'NEW_FLAGGED_POST_DELETE_REQUEST',
    newGroupApprove: 'NEW_GROUP_APPROVE',
    newGroupReject: 'NEW_GROUP_REJECT',
    newAdminPromotion: 'NEW_ADMIN_PROMOTION',
    newOrganizationClaimed: 'NEW_ORGANIZATION_CLAIMED',
    groupMemberConnectNotification: 'GROUP_MEMBER_CONNECT_NOTIFICATION',
    groupMemberDisconnectNotification: 'GROUP_MEMBER_DISCONNECT_NOTIFICATION',
    newRedeemptionPostForOrganization: 'NEW_REDEMPTION_POST_FOR_ORGANIZATION',
    BUSINESS_APPROVED_FOR_ORGANIZATION: 'BUSINESS_APPROVED_FOR_ORGANIZATION',
    /**
     * Send a notification when a user has been mentioned on a post.
     * @ticket https://8base-dev.atlassian.net/browse/JEB-1458
     */
    USER_HAS_BEEN_MENTIONED: 'USER_HAS_BEEN_MENTIONED',
    /**
     * Send a notification when a group has been mentioned on a post.
     * @ticket https://8base-dev.atlassian.net/browse/JEB-1458
     */
    GROUP_HAS_BEEN_MENTIONED: 'GROUP_HAS_BEEN_MENTIONED',
    /**
     * Send a notification when a organization has been mentioned on a post.
     * @ticket https://8base-dev.atlassian.net/browse/JEB-1458
     */
    ORGANIZATION_HAS_BEEN_MENTIONED: 'ORGANIZATION_HAS_BEEN_MENTIONED',
    /**
     * Send a notification when a organization has been inactivated due a billing issue.
     * @ticket https://8base-dev.atlassian.net/browse/JEB-1328?focusedCommentId=44081
     */
    BUSINESS_INACTIVATED_DUE_BILLING_FOR_ORGANIZATION: 'BUSINESS_INACTIVATED_DUE_BILLING_FOR_ORGANIZATION',
    /**
     * Send a notification when a discount has been approved.
     * @ticket https://8base-dev.atlassian.net/browse/JEB-1461?focusedCommentId=44529
     */
    BUSINESS_DISCOUNT_APPROVAL: 'BUSINESS_DISCOUNT_APPROVAL',
    /**
     * Send a notification when a discount has been rejected.
     * @ticket https://8base-dev.atlassian.net/browse/JEB-1461?focusedCommentId=44530
     */
    BUSINESS_DISCOUNT_REJECTION: 'BUSINESS_DISCOUNT_REJECTION',
};
