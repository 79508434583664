import { useContext } from 'react';

import { SearchContext } from './SearchContext';
import { SearchContextValue } from '../types';

/** @throws When outse of `SearchProvider`. */
export function useSearchContext<F = any, D = any, Z = any>() {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error(`No "SearchContext" provided in the current context`);
  }

  return context as SearchContextValue<F, D, Z>;
}

/** @throws When outse of `SearchProvider`. */
export function useSearchDateFilterContext<DateFilter = any>() {
  const { dateFilter, setDateFilter } = useSearchContext<unknown, DateFilter>();

  return {
    filter: dateFilter,
    setFilter: setDateFilter,
  };
}
