import { useEffect, useLayoutEffect, useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';

import { matchRoutesPaths } from 'shared/components/ui/common';
import { useAppAuth } from 'providers/useAppAuth';
import { CenterLayout } from 'shared/components/layouts';
import { ROUTES } from 'shared/constants';
import {
  getAccessibleLayouts,
  useCurrentUser,
  useCurrentUserRoleAddons,
  usePushNotifications,
  useToast,
  useRoleAddons,
} from 'shared/hooks';

import { renderContent } from './routerUtils';

export type ProtectedRouteProps = RouteProps & {
  redirectTo?: string;
};

export function ProtectedRoute({
  component,
  children,
  render,
  redirectTo = ROUTES.public.login,
  ...props
}: ProtectedRouteProps) {
  const { requestPermission } = usePushNotifications();
  const { hasSession, logout } = useAppAuth();
  const { pathname } = useLocation();
  const { user, loading: userLoading } = useCurrentUser();
  const { data: userRoles, loading: userRolesLoading } = useCurrentUserRoleAddons();
  const { showWarning } = useToast();
  const { rolesAddons, isLoading: rolesLoading } = useRoleAddons();

  const loading = userLoading || userRolesLoading || rolesLoading;

  useEffect(() => {
    if (user?.userStatus === 'inactive') {
      showWarning('Your account is not active, please contact with administration');
      onLogout();
    }
  }, [user]);

  useLayoutEffect(() => {
    if (hasSession) {
      // https://github.com/jebelapp/jebel/issues/1517
      requestPermission();
    }
  }, [hasSession]);

  const onLogout = () => {
    logout();
  };

  const isRouteAllowed = useMemo(() => {
    if (!user) {
      return false;
    }

    if (!rolesAddons || rolesAddons.length === 0) {
      return true;
    }

    const routes = getAccessibleLayouts(userRoles, rolesAddons).map(item => ({
      path: item.route as string,
    }));

    if (matchRoutesPaths(pathname, routes)) {
      return true;
    }
  }, [pathname, rolesAddons, userRoles, user]);

  if (hasSession === false) {
    return <Redirect to={redirectTo} />;
  }

  if (loading) {
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  }

  if (!isRouteAllowed) {
    return <Redirect to={ROUTES.user.home.index} />;
  }

  return (
    <Route {...props} render={props => renderContent({ component, render, children, props })} />
  );
}
