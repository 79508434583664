import { Fragment, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface SpreadsheetPreloaderProps {
  columnsCount: number;
}

const ROWS_COUNT = 15;
const ROWS = Array.from({ length: ROWS_COUNT }, (_, index) => index);

export function SpreadsheetPreloader(props: SpreadsheetPreloaderProps) {
  return (
    <Fragment>
      {ROWS.map(index => (
        <SpreadsheetPreloaderRow key={index} {...props} />
      ))}
    </Fragment>
  );
}

function SpreadsheetPreloaderRow({ columnsCount }: SpreadsheetPreloaderProps) {
  return (
    <tr>
      <td colSpan={columnsCount}>
        <Skeleton variant="rect" width="100%" height="3rem" />
      </td>
    </tr>
  );
}
