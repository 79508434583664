import React from 'react';
import { css } from '@emotion/react';
import { Switch } from 'react-router-dom';

const divCSS = css`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

type RootLayoutsContainerProps = {
  layouts?: React.ReactNode;
  redirects?: React.ReactNode;
};

export const RootLayoutsContainer: React.FC<RootLayoutsContainerProps> = symbolProps => {
  return (
    <div css={divCSS}>
      <Switch>{[symbolProps.layouts, symbolProps.redirects]}</Switch>
    </div>
  );
};
