import React from 'react';
import { css } from '@emotion/react';

import { Route } from 'shared/components/ui';

const divCSS = css`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  height: 100%;
`;

type EmptyPageLayoutProps = {
  path?: any;
  exact?: boolean;
  content?: React.ReactNode;
  header?: React.ReactNode;
};

export const EmptyPageLayout: React.FC<EmptyPageLayoutProps> = symbolProps => {
  return (
    <Route path={symbolProps.path} exact={true} authAccess="any">
      <div css={divCSS}>{symbolProps.content}</div>
    </Route>
  );
};
