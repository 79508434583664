import { createFilterBuilder } from '@jebel/utils';
import { useMemo } from 'react';
import { useGraduatedUsersListPerYearQuery, UserFilter } from 'shared/graphql';

type Options = Parameters<typeof useGraduatedUsersListPerYearQuery>[0] & {
  year: string | number;
};

export function useGraduatedUsersListPerYear(options: Options) {
  const filter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>(options.variables?.filter);

    if (options.year) {
      filter.and({
        AND: [
          { graduatingYear: { gte: `${options.year}-01-01` } },
          { graduatingYear: { lt: `${options.year}-12-31` } },
        ],
      });
    }

    return filter.build();
  }, [options]);

  const { data: response, loading } = useGraduatedUsersListPerYearQuery({
    ...options,
    variables: { ...options.variables, filter },
  });

  const data = response?.users?.items ?? [];
  const count = response?.users?.count ?? 0;

  return {
    data,
    count,
    loading,
  };
}
