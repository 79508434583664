import React, { useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import type { PickerOptions } from 'filestack-js';
import { FileInputProps, FileInputValue, OriginalFileInputValue } from '@8base-react/file-input';
import _ from 'lodash';
import { Close as IconClose } from '@mui/icons-material';
import pluralize from 'pluralize';

import { fileSizeToHuman } from '@jebel/utils';

import { ONE_MEGABYTE } from 'shared/constants/files';
import { ResultFile, ResultFileValue } from 'shared/types/files';
import { SxProp } from 'shared/types/styles';

import { FileInputWrap as FileInput } from '../FileInputWrap';

interface VideoInputProps {
  label?: string;
  pickerOptions?: PickerOptions;
  fileInputProps?: FileInputProps;
  initialValue?: ResultFileValue;
  showPreview?: boolean;
  showDescription?: boolean;
  descriptionPlacement?: 'top' | 'bottom';
  maxFiles?: number;
  onChange: (data: ResultFileValue | null) => void;
}

const MAX_FILES_SIZE = ONE_MEGABYTE * 50;

export const MediaInput: React.FC<VideoInputProps> = ({
  children,
  label,
  pickerOptions,
  onChange,
  fileInputProps,
  initialValue,
  descriptionPlacement = 'bottom',
  showDescription = true,
  showPreview = true,
  maxFiles = 6,
}) => {
  const [value, setValue] = React.useState<ResultFileValue | null>(initialValue || null);

  const handleChange = React.useCallback(
    (newValue: ResultFileValue | null) => {
      setValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  const addFiles = useCallback(
    (result: ResultFileValue) => {
      const files: ResultFile[] = [];
      if (Array.isArray(value)) {
        files.push(...value);
      }

      if (value && !Array.isArray(value)) {
        files.push(value);
      }

      if (Array.isArray(result)) {
        files.push(...result);
      }

      if (result && !Array.isArray(result)) {
        files.push(result);
      }

      if (maxFiles === 1 && files.length === 2) {
        const [, last] = files;
        // Select the last element to replace the value one
        handleChange(last);
        return;
      }

      handleChange(files);
    },
    [handleChange, maxFiles, value],
  );

  const pickerContent = React.useMemo(() => {
    return (
      <React.Fragment>
        {children}
        {label && (
          <Typography variant="body1" color="secondary">
            {label}
          </Typography>
        )}
      </React.Fragment>
    );
  }, [children, label]);

  const onPickFile = React.useCallback(
    (pick: (options: PickerOptions) => void) => {
      // picker spamming his script into DOM on every pick
      document.getElementById('fs-loader-picker')?.remove();

      pick({
        fromSources: ['local_file_system'],
        maxSize: MAX_FILES_SIZE,
        accept: ['image/*', 'video/*'],
        // Prevent to upload images with a resolution higher than 1080p
        // https://github.com/jebelapp/jebel/issues/1639#issuecomment-2450783301
        imageMax: [2160, 1080],

        ...pickerOptions,
      });
    },
    [pickerOptions],
  );

  const onUploadDone = async (
    file: FileInputValue,
    originalFile?: OriginalFileInputValue | undefined,
  ): Promise<FileInputValue> => {
    if (!_.isNil(originalFile)) {
      if (_.isArray(file) && _.isArray(originalFile) && file.length === originalFile.length) {
        const files = file as ResultFile[];

        addFiles(
          files.map((data, index) => ({
            ...data,
            size: originalFile[index].size,
          })),
        );
      }

      if (!_.isArray(file) && !_.isArray(originalFile)) {
        addFiles({ ...file, size: originalFile.size } as ResultFile);
      }
    } else {
      addFiles(file as ResultFileValue);
    }

    return file;
  };

  const onRemoveFile = React.useCallback(
    (id: string) => {
      const newValue = _.isArray(value) ? value.filter(file => file.fileId !== id) : null;
      handleChange(newValue);
    },
    [value, handleChange],
  );

  const preview = React.useMemo(() => {
    if (showPreview) {
      if (!_.isNil(value)) {
        if (_.isArray(value)) {
          return (
            <React.Fragment>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Uploaded {value.length} {pluralize('file', value.length)} out of {maxFiles}
                </Typography>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                {value.map(file => renderFilePreview(file, onRemoveFile, !!showDescription))}
              </List>
            </React.Fragment>
          );
        }
        return renderFilePreview(value, onRemoveFile, !!showDescription);
      }
    }

    return null;
  }, [value, maxFiles, onRemoveFile, showDescription, showPreview]);

  const filesLeft = React.useMemo(() => {
    return maxFiles - (_.isArray(value) ? value.length : 0);
  }, [maxFiles, value]);

  const isNoFilesLeft = React.useMemo(() => {
    return filesLeft === 0;
  }, [filesLeft]);

  return (
    <Box>
      {descriptionPlacement === 'top' ? preview : null}
      <Box sx={isNoFilesLeft ? { cursor: 'not-allowed' } : {}}>
        <FileInput {...(fileInputProps || {})} maxFiles={filesLeft} onUploadDone={onUploadDone}>
          {({ pick }) => (
            <Box
              sx={isNoFilesLeft ? { opacity: 0.5, pointerEvents: 'none' } : {}}
              onClick={() => onPickFile(pick)}
            >
              {pickerContent}
            </Box>
          )}
        </FileInput>
      </Box>
      {descriptionPlacement === 'bottom' ? preview : null}
    </Box>
  );
};

const deleteButtonStyles: SxProp = theme => ({
  px: 1,
  py: 0,
  minWidth: 'auto',
  color: theme.palette.error.main,
});

type MediaType = 'video' | 'image';

const getMediaType = (mimetype: string): MediaType =>
  (mimetype.split('/')[0] as MediaType) || 'image';

const renderFilePreview = (
  fileInfo: ResultFile | any,
  onDelete: (fileId: string) => void,
  showDescription: boolean,
) => {
  const mimetypeKey = fileInfo.meta ? fileInfo.meta.mimetype : fileInfo.mimetype;
  const sizeKey = fileInfo.meta ? fileInfo.meta.size : fileInfo.size;

  const mediaType: MediaType = getMediaType(mimetypeKey || '');
  const fileSize = fileSizeToHuman(sizeKey, 'Unknown file size');

  const showFileSize = !!fileSize;

  return (
    <ListItem key={fileInfo.fileId + fileInfo.downloadUrl} sx={{ gap: 1, p: 0 }}>
      <ListItemAvatar>
        {mediaType === 'video' ? (
          <CardMedia component="video" sx={{ width: 65 }} src={fileInfo.downloadUrl || ''} />
        ) : (
          <Avatar
            src={fileInfo.downloadUrl || ''}
            alt={fileInfo.filename || ''}
            sx={{ width: 65 }}
            variant="square"
          />
        )}
      </ListItemAvatar>
      {showDescription && (
        <ListItemText
          primary={
            <Box display="flex" alignItems="center" columnGap={1}>
              <Typography
                color="black"
                sx={{
                  fontWeight: 600,
                  lineHeight: '1.25rem',
                  fontSize: 15,
                  wordBreak: 'break-all',
                }}
              >
                {fileInfo.filename}
              </Typography>
              <Button
                onClick={() => onDelete(fileInfo.fileId || '')}
                variant="text"
                sx={deleteButtonStyles}
              >
                <IconClose />
              </Button>
            </Box>
          }
          secondary={
            showFileSize && (
              <Typography
                variant="body1"
                color="black"
                sx={{ lineHeight: '1.5rem', fontSize: 13.5, fontWeight: 500 }}
              >
                {fileSize}
              </Typography>
            )
          }
        />
      )}
    </ListItem>
  );
};
