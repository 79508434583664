import { SELECT_OPTION_ALL } from 'shared/constants';
import { OrganizationFilter } from 'shared/graphql/__generated__';

export const getSupporterSearchFilter = (
  isSupporter: boolean,
  industry: string,
): OrganizationFilter => {
  return {
    advertising: { supporter: { isSupporter: { equals: isSupporter } } },
    industry: industry === SELECT_OPTION_ALL ? undefined : { equals: industry },
  };
};
