import { Fragment, ReactNode, useLayoutEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { darken, Typography } from '@material-ui/core';
import { Facebook, Google, LinkedIn } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import { shouldBePassword } from '@jebel/utils';

import { useAppAuth } from 'providers/useAppAuth';
import { Box, Button, Form, FormTextField, RouteLayout, RouterLink } from 'shared/components/ui';
import { APP_URL, ROUTES } from 'shared/constants';
import { isValidEmail } from 'shared/utils/form';
import { useQueryParams, useToast } from 'shared/hooks';

import { AUTH_PROVIDER_FACEBOOK, AUTH_PROVIDER_GOOGLE, AUTH_PROVIDER_LINKEDIN } from '../types';
import { PasswordField } from 'shared/components/form';

const formWrapperCss = css`
  display: grid;
  grid-row-gap: 20px;
`;

const formTitleCss = css`
  font-size: 26px;
  font-weight: 500;
  width: 100%;
  text-align: center;
`;

const formBlock = css`
  display: grid;
  grid-gap: 16px;
`;

const dividerWrapCss = css`
  display: flex;
  position: relative;
`;

const dividerCss = theme => css`
  ::after {
    position: absolute;
    top: 50%;
    left: calc(50% + 20px);
    content: '';
    height: 1px;
    width: calc((100% - 40px) / 2);
    border: 1px solid #dadada;
  }
  ::before {
    position: absolute;
    top: 50%;
    right: calc(50% + 20px);
    content: '';
    height: 1px;
    width: calc((100% - 40px) / 2);
    border: 1px solid #dadada;
  }
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  color: ${theme.palette.text.secondary};
  font-weight: 500;
  width: 100%;
`;

const oauthBlockCss = css`
  display: grid;
  grid-row-gap: 25px;
`;

const oauthButtonCSS = (item: OAuthButtonProps) => css`
  background-color: ${item.color};

  &:hover {
    background-color: ${darken(item.color, 0.1)};
  }
`;

const linkCss = theme => css`
  white-space: nowrap;
  color: ${theme.palette.primary.icon};
`;

const bottomLinkWrap = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface OAuthButtonProps {
  color: string;
  name: string;
  icon: ReactNode;

  onClick(): void;
}

type FormData = {
  email?: string;
  password?: string;
};

export function AuthPage() {
  const [params] = useQueryParams();

  const { hasSession, login, socialSignIn, confirmSocialSignIn } = useAppAuth();
  const { push: navigate } = useHistory();
  const { showError } = useToast();

  useLayoutEffect(() => {
    if (params.code) {
      confirmSocialSignIn(params.code);
    }
  }, [params.code]);

  useLayoutEffect(() => {
    if (hasSession) {
      redirect();
    }
  }, [hasSession]);

  const redirect = () => {
    navigate(ROUTES.user.home.index);
  };

  const onGoogleSignIn = () => {
    socialSignIn(AUTH_PROVIDER_GOOGLE);
  };

  const onFacebookSignIn = () => {
    socialSignIn(AUTH_PROVIDER_FACEBOOK);
  };

  const onLinkedInSignIn = () => {
    socialSignIn(AUTH_PROVIDER_LINKEDIN);
  };

  const oauthButtons: OAuthButtonProps[] = useMemo(() => {
    return [
      {
        color: '#0077B7',
        name: 'Login with Linkedin',
        icon: <LinkedIn />,
        onClick: onLinkedInSignIn,
      },
      {
        color: '#2C4288',
        name: 'Login with Facebook',
        icon: <Facebook />,
        onClick: onFacebookSignIn,
      },
      {
        color: '#4285F4',
        name: 'Login with Google',
        icon: <Google />,
        onClick: onGoogleSignIn,
      },
    ];
  }, [onLinkedInSignIn, onFacebookSignIn, onGoogleSignIn]);

  const onSubmit = async ({ email, password }: FormData) => {
    if (!email || !password) {
      return;
    }

    try {
      await login({ email, password });
      redirect();
    } catch (err) {
      showError('Something goes wrong when validating your credentials, please try again.');

      if (err instanceof Error) {
        showError(err.message);
        return;
      }
    }
  };

  return (
    <RouteLayout>
      <Box css={formWrapperCss}>
        <Typography
          css={formTitleCss}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          Welcome! Login here
        </Typography>

        <Form oldCss={formBlock} onSubmit={onSubmit} validateOnBlur={false}>
          {({ isSubmitting }) => (
            <Fragment>
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'Email Address',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'email', validate: isValidEmail }}
              />

              <PasswordField
                name="password"
                label="Enter your password"
                validate={shouldBePassword}
                autoComplete="password"
                required
              />

              <Button
                loading={isSubmitting}
                variant="contained"
                size="large"
                type="submit"
                color="secondary"
              >
                Login
              </Button>

              <Box css={dividerWrapCss}>
                <Typography
                  css={dividerCss}
                  variant="inherit"
                  align="inherit"
                  color="initial"
                  paragraph={false}
                >
                  OR
                </Typography>
              </Box>
              <Box css={oauthBlockCss}>
                {oauthButtons.map(item => (
                  <Fragment key={item.name}>
                    <Button
                      onClick={item.onClick}
                      variant="contained"
                      color="primary"
                      size="large"
                      css={oauthButtonCSS(item)}
                      startIcon={item.icon}
                    >
                      {item.name}
                    </Button>
                  </Fragment>
                ))}
                <Box css={bottomLinkWrap}>
                  <Typography variant="inherit" align="inherit" color="initial" paragraph={false}>
                    Forgot your password?&nbsp;
                  </Typography>
                  <RouterLink
                    css={linkCss}
                    absolute={false}
                    underline="none"
                    color="primary"
                    to={APP_URL.public.recovery}
                  >
                    Click here
                  </RouterLink>
                </Box>
                <Box css={bottomLinkWrap}>
                  <Typography variant="inherit" align="inherit" color="initial" paragraph={false}>
                    No account?&nbsp;
                  </Typography>
                  <RouterLink
                    css={linkCss}
                    absolute={false}
                    underline="none"
                    color="primary"
                    to={APP_URL.public.request}
                  >
                    Request access
                  </RouterLink>
                </Box>
              </Box>
            </Fragment>
          )}
        </Form>
      </Box>
    </RouteLayout>
  );
}
