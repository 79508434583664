import { Box, MenuItem } from '@material-ui/core';
import { css, Theme } from '@emotion/react';

import { generateGraduatingYearFormConfig } from 'shared/constants/selectOptions';
import { CreateGraduatingYearFormFields } from 'shared/types/forms';
import {
  createGraduatingYearFormSchema,
  CreateGraduatingYearFormValues,
} from 'shared/validationSchemas';
import { Form, Button, FormTextField, FormSelect } from 'shared/components/ui';

const formBlockCSS = (theme: Theme) => css`
  display: grid;
  margin-bottom: ${theme.spacing(1)}px;
`;

const rawYears = generateGraduatingYearFormConfig();
const renderYears = (existingGraduatingYears: number[]) => {
  return rawYears
    .filter(yearInfo => {
      return !existingGraduatingYears.includes(Number(yearInfo.value));
    })
    .map(item => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
};

const alumniCountInputProps = {
  step: 1,
  min: 1,
};

type CreateGraduatingYearModalProps = {
  initialValues?: Partial<CreateGraduatingYearFormValues>;
  onSubmit: (formValues: CreateGraduatingYearFormValues) => Promise<void>;
  onClose: () => void;
  existingGraduatingYears?: number[];
  isEditMode?: boolean;
  isLoading?: boolean;
};

export function CreateOrEditGraduatingYearForm({
  initialValues,
  onSubmit,
  onClose,
  existingGraduatingYears = [],
  isEditMode = false,
  isLoading = false,
}: CreateGraduatingYearModalProps) {
  return (
    <Form
      oldCss={formBlockCSS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createGraduatingYearFormSchema}
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Box display="grid" gridGap="1rem">
            <FormSelect
              selectProps={{
                children: renderYears(existingGraduatingYears),
                variant: 'outlined',
                label: 'Class Year',
                required: true,
                disabled: isEditMode || isLoading,
              }}
              fieldProps={{ name: CreateGraduatingYearFormFields.classYear }}
            />

            <FormTextField
              inputProps={{
                label: 'Total Alumni count',
                variant: 'outlined',
                type: 'number',
                inputProps: alumniCountInputProps,
                required: true,
                disabled: isLoading,
              }}
              fieldProps={{ name: CreateGraduatingYearFormFields.alumniCount }}
            />

            <Box display="flex" justifyContent="end" gridGap="0.5rem">
              <Button onClick={onClose} disabled={isSubmitting} size="medium">
                Cancel
              </Button>

              <Button
                color="primary"
                disabled={isSubmitting || isLoading || !isValid || !dirty}
                loading={isSubmitting || isLoading}
                disableElevation
                variant="contained"
                type="submit"
              >
                {isEditMode ? 'Edit' : 'Create'} Class
              </Button>
            </Box>
          </Box>
        );
      }}
    </Form>
  );
}
