import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { css } from '@emotion/react';
import { Typography, Link, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { FeedSkeleton, LoadingOverlay, Modal } from 'shared/components/ui';
import { APP_URL, FIELDS_CONFIG } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import { InfiniteScroll } from 'shared/features/infinite-scroll';

import { useCareersSearch } from '../../hooks';
import { SearchCareerFilter } from '../../types';
import { CareersList, JobSeekersList } from '../CareerDetails';
import { CareerServicesFilterModal } from '../CareerModals/CareerServicesFilterModal';

const headerTitleCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
`;

interface Props {
  selectedFilters: SearchCareerFilter;
  setFilters: Dispatch<SetStateAction<SearchCareerFilter>>;
}

export function CareerServicesContent({ selectedFilters, setFilters }: Props) {
  const [localZipQuery, setLocalZipQuery] = useState('');

  const { push: navigate } = useHistory();
  const { isFilterModalOpen, setIsFilterModalOpen } = useSearchContext();

  const {
    jobOfferItems,
    internshipsItems,
    jobOfferCount,
    internshipsCount,
    jobSeekersItems,
    jobSeekersCount,
    loading: searchLoading,
    hasMoreOffers,
    hasMoreInternships,
    hasMoreSeekers,
    fetchMoreOffers,
    fetchMoreInternships,
    fetchMoreSeekers,
  } = useCareersSearch(localZipQuery, selectedFilters?.radius);

  const showCareers =
    selectedFilters.type === FIELDS_CONFIG.type.jobs ||
    selectedFilters.type === FIELDS_CONFIG.type.all;

  const showInternships =
    selectedFilters.type === FIELDS_CONFIG.type.internships ||
    selectedFilters.type === FIELDS_CONFIG.type.all;

  const showJobSeekers =
    selectedFilters.type === FIELDS_CONFIG.type.jobSeekers ||
    selectedFilters.type === FIELDS_CONFIG.type.all;

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const loading = searchLoading;

  const onSeekingEmploymentClick = () => {
    navigate(APP_URL.user.settings.profile);
  };

  return (
    <LoadingOverlay loading={loading} overlay={<FeedSkeleton count={2} />}>
      <Modal
        dialogProps={{
          open: isFilterModalOpen,
          onClose: closeFilterModal,
          fullWidth: true,
          maxWidth: 'xs',
        }}
        titleProps={{ title: 'Filter by' }}
        isVisibleDivider
        dialogContentWidth={280}
      >
        <CareerServicesFilterModal
          onModalClose={closeFilterModal}
          setFilters={setFilters}
          selectedFilters={selectedFilters}
          setLocalZipQuery={setLocalZipQuery}
          loading={loading}
        />
      </Modal>

      {showCareers && (
        <InfiniteScroll hasMore={hasMoreOffers} fetchMore={fetchMoreOffers}>
          <Box css={headerTitleCSS}>
            <Typography>Careers ({jobOfferCount})</Typography>

            <Link color="secondary" onClick={onSeekingEmploymentClick}>
              Seeking employment?
            </Link>
          </Box>

          {jobOfferCount === 0 ? (
            <Typography align="center" color="textSecondary">
              No job offers found
            </Typography>
          ) : (
            <CareersList careersList={jobOfferItems} />
          )}
        </InfiniteScroll>
      )}

      {showInternships && (
        <InfiniteScroll hasMore={hasMoreInternships} fetchMore={fetchMoreInternships}>
          <Box css={headerTitleCSS}>
            <Typography>Internships ({internshipsCount})</Typography>
          </Box>

          {internshipsCount === 0 ? (
            <Typography align="center" color="textSecondary">
              No internships found
            </Typography>
          ) : (
            <CareersList careersList={internshipsItems} />
          )}
        </InfiniteScroll>
      )}

      {showJobSeekers && (
        <InfiniteScroll hasMore={hasMoreSeekers} fetchMore={fetchMoreSeekers}>
          <Box css={headerTitleCSS}>
            <Typography>Job Seekers ({jobSeekersCount})</Typography>
          </Box>

          {jobSeekersCount === 0 ? (
            <Typography align="center" color="textSecondary">
              No job seekers found
            </Typography>
          ) : (
            <JobSeekersList usersList={jobSeekersItems} />
          )}
        </InfiniteScroll>
      )}
    </LoadingOverlay>
  );
}
