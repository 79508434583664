import styled from '@emotion/styled';

export const GroupDetailsContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  white-space: nowrap;
`;

export const CatchLongNames = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 8rem;
`;

export const SecondaryText = styled.div`
  color: ${p => p.theme.palette.text.secondary};
`;
