import styled from '@emotion/styled';
import { Paper } from '@mui/material';

/** @deprecated Use `CardLayout` instead. */
export const PaperLayout = styled(Paper)`
  width: 100%;
  box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  background-color: ${p => p.theme.palette.background.paper};
`;
