import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { uniqBy } from 'ramda';

import { OrganizationItemFragment, UserKeyFilter } from 'shared/graphql';
import { useIntersectionWatcher, useResponsive, useSchoolConfiguration } from 'shared/hooks';
import { useInboxContext } from 'providers/InboxProvider';

import {
  Container,
  Content,
  Description,
  Divided,
  Header,
  HeaderBanner,
  Location,
  Logo,
  SupporterLogo,
  Title,
} from './ExplorerBusinessCard.styles';
import { ExplirerBusinessCardButtons } from './ExplorerBusinessCardButtons';
import { generateOrganizationRoute } from 'shared/routes';

interface Props {
  data: OrganizationItemFragment;
  /** @default "list" */
  variant?: 'list' | 'grid';

  /** @default false */
  withLike?: boolean;
  /** @default false */
  withMessage?: boolean;
  /** @default false */
  withViewProfile?: boolean;

  onClickAction?(): void;
  onShowedUp?(): void;
}

export function ExplorerBusinessCard(props: Props) {
  const { ref, haveBeenShown: isHaveBeenDisplayed } = useIntersectionWatcher<HTMLDivElement>();

  const description = props.data.description;
  const variant = props.variant ?? 'list';

  const withLike = props.withLike ?? false;
  const withMessage = props.withMessage ?? false;
  const withViewProfile = props.withViewProfile ?? false;

  const { configuration: school } = useSchoolConfiguration();
  const { push: navigate } = useHistory();
  const { isMobile } = useResponsive();
  const { onOpenInboxModal } = useInboxContext();

  const location = useMemo(() => {
    const [location] = props.data.location?.items ?? [];
    const [address] = location?.address?.items ?? [];

    const city = address?.city;
    const state = address?.state;

    if (city && state) {
      return `${city}, ${state}`;
    }

    return undefined;
  }, [props.data]);

  /** Check whether the current organization is supporting current school. */
  const isSupportingCurrentSchool = useMemo(() => {
    const supportedSchools = props.data?.schoolSupporter?.items ?? [];

    // Check an compare whether with current organization.
    // https://8base-dev.atlassian.net/browse/JEB-1587
    return supportedSchools.some(supported => supported.school?.id === school.id);
  }, [props.data, school.id]);

  const openProfile = () => {
    if (!props.data?.id) {
      return;
    }

    const url = generateOrganizationRoute(props.data.id);

    if (isSupportingCurrentSchool && props.onClickAction) {
      props.onClickAction();
    }

    navigate(url);
  };

  const openMessage = () => {
    if (!onOpenInboxModal) {
      return;
    }

    const organization = props.data;
    const managers = organization?.managers?.items ?? [];

    const users = uniqBy(
      user => user?.id,
      managers.map(manager => manager.user).concat(organization?.owner),
    );

    onOpenInboxModal({
      isOpen: true,
      options: {
        members: users as UserKeyFilter[],
        messageType: 'personal',
      },
    });
  };

  useEffect(() => {
    if (isHaveBeenDisplayed && props.onShowedUp) {
      props.onShowedUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHaveBeenDisplayed]);

  const Buttons = (
    <ExplirerBusinessCardButtons
      data={props.data}
      withLike={withLike}
      withMessage={withMessage}
      withViewProfile={withViewProfile}
      onOpenProfile={openProfile}
      onOpenMessage={openMessage}
    />
  );

  if (variant === 'grid') {
    return (
      <Container borderless={isMobile} ref={ref}>
        <Divided>
          <Logo organization={props.data} />

          <Content>
            <HeaderBanner>
              <Title organization={props.data} />

              {isSupportingCurrentSchool && <SupporterLogo school={school} />}
            </HeaderBanner>

            <Location>{location}</Location>

            {Buttons}
          </Content>
        </Divided>

        <Description>{description}</Description>
      </Container>
    );
  }

  return (
    <Container borderless={isMobile} ref={ref}>
      <Divided>
        <Logo organization={props.data} />

        <Content>
          <Header>
            <HeaderBanner>
              <Title organization={props.data} />

              {isSupportingCurrentSchool && <SupporterLogo school={school} />}
            </HeaderBanner>

            {Buttons}
          </Header>

          {location && <Location>{location}</Location>}
          <Description>{description}</Description>
        </Content>
      </Divided>
    </Container>
  );
}
