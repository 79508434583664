import { GroupsListItemFragment } from 'shared/graphql';

import {
  Container,
  Content,
  Description,
  Divided,
  Header,
  Logo,
  MembersCount,
  Title,
} from './ExplorerGroupCard.styles';
import {
  ExplorerGroupCardButtons,
  ExplorerGroupCardButtonOptions,
} from './ExplorerGroupCardButtons';
import { useResponsive } from 'shared/hooks';
import pluralize from 'pluralize';
import { useGroupMembers } from 'features/groups/hooks';
import { LoadingOverlay, Skeleton } from 'shared/components/ui';

interface Props extends ExplorerGroupCardButtonOptions {
  data: GroupsListItemFragment;

  /** @default "list" */
  variant?: 'list' | 'grid';

  onClick?(): void;
}

export function ExplorerGroupCard(props: Props) {
  const description = props.data.description;
  const variant = props.variant ?? 'list';

  const { isMobile } = useResponsive();
  const { data: members, isLoading } = useGroupMembers(props.data.id);

  const membersCount = members.length;

  const Buttons = (
    <ExplorerGroupCardButtons
      data={props.data}
      withJoin={props.withJoin}
      withMessage={props.withMessage}
      withViewProfile={props.withViewProfile}
    />
  );

  if (variant === 'grid') {
    return (
      <Container borderless={isMobile}>
        <Divided>
          <Logo group={props.data} />

          <Content>
            <Title group={props.data} />

            <LoadingOverlay loading={isLoading}>
              <MembersCount>
                {membersCount} {pluralize('member', membersCount)}
              </MembersCount>
            </LoadingOverlay>

            {Buttons}
          </Content>
        </Divided>

        <Description>{description}</Description>
      </Container>
    );
  }

  return (
    <Container borderless={isMobile}>
      <Divided>
        <Logo group={props.data} />

        <Content>
          <Header>
            <Title group={props.data} />

            {Buttons}
          </Header>

          <LoadingOverlay loading={isLoading}>
            <MembersCount>
              {membersCount} {pluralize('member', membersCount)}
            </MembersCount>
          </LoadingOverlay>

          <Description>{description}</Description>
        </Content>
      </Divided>
    </Container>
  );
}
