import { useMemo } from 'react';
import { Autocomplete, CardContent, Typography } from '@mui/material';
import { TextField } from '@material-ui/core';
import { ascend } from 'ramda';

import { SchoolConfigurationLayout } from 'shared/graphql';
import { Icon } from 'shared/components/ui';

import { MainNavigationCard } from '../MainNavigationCard/MainNavigationCard';
import { PreviewsContainer, PreviewsItem } from './MainNavigationMobileNav.styles';

interface Props {
  items: SchoolConfigurationLayout[];
  availableOptions: SchoolConfigurationLayout[];
  onListUpdate(items: SchoolConfigurationLayout[]): void;
}

const CUSTOMIZABLE_ITEMS_LENGTH = 3;

export function MainNavigationMobileNav(props: Props) {
  const menu = useMemo(() => {
    const pages = [...props.items];

    return Array.from({ length: CUSTOMIZABLE_ITEMS_LENGTH })
      .map((_item, index) => pages.find(page => page.navbarOrderNumber === index))
      .filter((value): value is SchoolConfigurationLayout => Boolean(value))
      .sort(ascend(page => page?.navbarOrderNumber as number));
  }, [props.items]);

  const onChange = (prev: SchoolConfigurationLayout | undefined, position: number) => {
    return (event: unknown, newest: SchoolConfigurationLayout | null) => {
      if (!newest) {
        return;
      }

      const sorted = props.items.map(item => {
        if (item.id === newest?.id) {
          return { ...item, navbarOrderNumber: position };
        }

        if (item.id === prev?.id) {
          return { ...item, navbarOrderNumber: newest?.navbarOrderNumber };
        }

        return item;
      });

      props.onListUpdate(sorted);
    };
  };

  return (
    <MainNavigationCard
      title="Mobile Navbar"
      subtitle="Customize the 3 central items on the mobile nav"
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {menu.map((item, index) => (
          <Autocomplete
            key={item?.id ?? index}
            value={item}
            options={props.availableOptions}
            getOptionLabel={option => option.name as string}
            isOptionEqualToValue={(option, item) => option.id === item?.id}
            onChange={onChange(item, index)}
            renderInput={params => (
              <TextField {...params} variant="outlined" label={`Item #${index + 1}`} />
            )}
          />
        ))}

        <Typography variant="body2" color="textSecondary">
          Preview
        </Typography>

        <PreviewsContainer>
          <PreviewsItem>
            <Icon name="Home" />
          </PreviewsItem>

          {menu
            .filter(item => item.icon)
            .map((item, index) => (
              <PreviewsItem key={item.id ?? index}>
                <Icon name={item.icon!} />
              </PreviewsItem>
            ))}

          <PreviewsItem>
            <Icon name="Menu" />
          </PreviewsItem>
        </PreviewsContainer>
      </CardContent>
    </MainNavigationCard>
  );
}
