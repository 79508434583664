import { lazy, Suspense } from 'react';
import { Box } from '@material-ui/core';

import { SearchProvider } from 'shared/features/search';
import { SELECT_OPTION_ALL, SUPPORTERS, USER_SORT_BY_NAME_INFO } from 'shared/constants';

import { UsersFilter } from '../types';
import { CardLayout, FeedLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const ExplorerNation = lazy(() => import('../components/ExplorerNation'));
const ExplorerNationFilterSidebar = lazy(() => import('../components/ExplorerFilterSidebar'));

const DEFAULT_FILTER_OPTION: UsersFilter = {
  currentIndustry: SELECT_OPTION_ALL,
  graduatingYear: SELECT_OPTION_ALL,
  jobSeekersOnly: false,
};

export function ExplorerNationPage() {
  return (
    <SearchProvider<UsersFilter>
      defaultFilterOption={DEFAULT_FILTER_OPTION}
      defaultSortOption={USER_SORT_BY_NAME_INFO.options[0].value}
    >
      <FeedLayout
        sidebar={
          <Box display="grid" gridGap="1rem">
            <Suspense fallback={<FeedSkeleton />}>
              <CardLayout>
                <ExplorerNationFilterSidebar />
              </CardLayout>
            </Suspense>

            <Suspense fallback={<FeedSkeleton />}>
              <CommunitySidebar items={[SUPPORTERS]} />
            </Suspense>
          </Box>
        }
      >
        <Suspense fallback={<FeedSkeleton />}>
          <ExplorerNation />
        </Suspense>
      </FeedLayout>
    </SearchProvider>
  );
}
