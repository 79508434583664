import { lazy, Suspense } from 'react';
import { FeedLayout } from 'shared/components/layouts';

import { FeedSkeleton } from 'shared/components/ui';
import { SearchProvider } from 'shared/features/search';

const OrganizationsContent = lazy(() => import('../components/OrganizationsContent'));
const OrganizationsFilter = lazy(() => import('../components/OrganizationsFilter'));

const PERSIST_DISPLAY_LIST_KEY = 'ORGANIZATION_DISPLAY_LIST';

export function OrganizationsPage() {
  return (
    <SearchProvider displayListPersistence={PERSIST_DISPLAY_LIST_KEY}>
      <FeedLayout
        sidebar={
          <Suspense fallback={<FeedSkeleton />}>
            <OrganizationsFilter />
          </Suspense>
        }
      >
        <Suspense fallback={<FeedSkeleton />}>
          <OrganizationsContent />
        </Suspense>
      </FeedLayout>
    </SearchProvider>
  );
}
