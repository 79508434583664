import { createFilterBuilder } from '@jebel/utils';
import { useMemo } from 'react';

import {
  GraduatingYearMetricFragment,
  GraduatingYearsMetricFilter,
  useGraduatingYearsRankingQuery,
} from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';

type Options = Parameters<typeof useGraduatingYearsRankingQuery>[0];

export interface GraduatingYearMetricRanked extends GraduatingYearMetricFragment {
  position: number;
}

/**
 * Custom hook to fetch and rank graduating years metrics.
 * @param {Options} [options] - Optional parameters for the query.
 * @internal DO NOT USE OUTSIDE OF THIS FOLDER.
 * @example
 * const { data, count, loading, fetchMore, refetch } = useGraduatingYearsRank({ variables: { someVariable: value } });
 */
export function useGraduatingYearsRanking(options?: Options) {
  const { data: school } = useSchoolConfiguration();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<GraduatingYearsMetricFilter>();

    if (school) {
      // https://github.com/jebelapp/jebel/issues/1575
      filter.and({ school: { equals: school.id } });
    }

    return filter.build();
  }, [school]);

  const { data: response, loading } = useGraduatingYearsRankingQuery({
    ...options,
    variables: { ...options?.variables, filter },
  });

  const count = response?.metrics?.count ?? 0;

  const data = useMemo<GraduatingYearMetricRanked[]>(() => {
    const data: GraduatingYearMetricFragment[] = response?.metrics?.items ?? [];

    return data.map((metrics, index) => ({ ...metrics, position: index + 1 }));
  }, [response]);

  return {
    data,
    count,
    loading,
  };
}
