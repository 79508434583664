import { useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { Avatar, Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { buildUrl } from 'shared/routes';
import { GroupsItemFragment } from 'shared/graphql';
import { useCurrentUserGroups, useResponsive } from 'shared/hooks';

import { APP_URL, ROUTES } from '../../../constants';
import { useGlobalState } from '../../../../providers';
import { Container, Content, ViewAll, Title } from './SidebarGroups.styles';

const groupItemCSS = (theme: Theme) => css`
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  color: inherit;
`;

const groupItemTextCSS = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
`;

const avatarCSS = (isCollapsed: boolean) => theme =>
  css`
    height: 25px;
    width: 25px;
    margin-right: ${isCollapsed ? theme.spacing(0.75) : 0}px;
  `;

export function SidebarGroups() {
  const { data: groups } = useCurrentUserGroups({ currentSchoolOnly: true });
  const { push: navigate } = useHistory();
  const { isMobile } = useResponsive();
  const { leftMenuDrawer: menu } = useGlobalState();

  const onOpenGroup = (group: GroupsItemFragment) => () => {
    if (!group?.id) {
      return;
    }

    const url = buildUrl(APP_URL.user.groups.details, { pathParams: { id: group?.id } });

    if (isMobile) {
      // Close only on mobile because sidebar on desktop is not full-screen.
      // https://8base-dev.atlassian.net/browse/JEB-1480
      menu.close();
    }

    navigate(url);
  };

  const openGroups = () => {
    if (isMobile) {
      // Close only on mobile because sidebar on desktop is not full-screen.
      // https://8base-dev.atlassian.net/browse/JEB-1480
      menu.close();
    }

    navigate(ROUTES.user.settings.groups);
  };

  const approvedGroups = useMemo(() => {
    /** https://8base-dev.atlassian.net/browse/JEB-1272 */
    return groups.filter(group => group.isApproved);
  }, [groups]);

  if (!groups.length) {
    return null;
  }

  return (
    <Container>
      <Title>My Groups</Title>

      <Content>
        {approvedGroups.slice(0, 3).map(item => (
          <Box key={item?.id} css={groupItemCSS} onClick={onOpenGroup(item)}>
            <Avatar
              srcSet={processFilestackUrlSrcSet(item.logo?.downloadUrl || '', {
                resize: {
                  width: 25,
                  height: 25,
                },
              })}
              src={item.logo?.downloadUrl || ''}
              css={avatarCSS(menu.isOpened)}
              variant="square"
            />

            {menu?.isOpened && <Typography css={groupItemTextCSS}>{item.title}</Typography>}
          </Box>
        ))}
      </Content>

      <ViewAll onClick={openGroups}>View All</ViewAll>
    </Container>
  );
}
