import { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { css } from '@emotion/react';
import pluralize from 'pluralize';

import { useExplorerSupporters } from 'features/explorer/hooks';
import { FeedSkeleton, LoadingOverlay } from 'shared/components/ui';

import { ExplorerSupportersList } from './ExplorerSupportersList';
import { SchoolAvatar } from 'shared/components/symbols';

const SUPPORTER_IMAGE_SIZE = 25;

export function ExplorerSupporters() {
  const [viewAll, setViewAll] = useState(false);

  const { data: supporters, count: supportersCount, loading } = useExplorerSupporters();

  return (
    <LoadingOverlay loading={loading} overlay={<FeedSkeleton />}>
      <Box css={headerCSS}>
        <Box css={titleContainerCSS}>
          <SchoolAvatar size={SUPPORTER_IMAGE_SIZE} />

          <Typography color="primary">
            {pluralize('Supporter', supportersCount)} ({supportersCount})
          </Typography>
        </Box>

        <Box css={viewAllBoxCSS}>
          {!viewAll && supporters.length > 0 && (
            <Button size="small" color="secondary" onClick={() => setViewAll(true)}>
              View all
            </Button>
          )}
        </Box>
      </Box>

      <ExplorerSupportersList supportersList={supporters} viewAll={viewAll} />
    </LoadingOverlay>
  );
}

const headerCSS = css`
  display: grid;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  padding: 15px;
`;

const titleContainerCSS = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const viewAllBoxCSS = css`
  display: flex;
  align-items: center;
`;
