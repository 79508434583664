import { useMemo } from 'react';

import { OrganizationFilter } from 'shared/graphql';
import { useSearchContext } from 'shared/features/search';
import { createOrganizationFilter } from 'features/search/utils';

interface Options {
  /**
   * Filter the organizations by "status".
   * @default true
   */
  onlyActive?: boolean;
}

export function useOrganizationsFilter(options?: Options) {
  const {
    searchQuery,
    zipQuery,
    filter: currentFilter,
    sortOption: sort,
    setFilter,
  } = useSearchContext<OrganizationFilter>();

  const onlyActive = options?.onlyActive ?? true;

  const filter = useMemo(() => {
    const filter = createOrganizationFilter({
      initial: currentFilter,
      search: searchQuery,
      zip: zipQuery,
      onlyActive,
    });

    return filter;
  }, [currentFilter, onlyActive, searchQuery, zipQuery]);

  return {
    filter,
    sort,
    setFilter,
  };
}
