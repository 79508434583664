import { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { Typography } from 'shared/components/ui';
import { HomeFeedCard, usePostDelete } from 'shared/features/posts';
import { HomeFeedItem } from 'shared/graphql';
import { useAdsList, WithAdvertising } from 'features/advertising';
import { useLegacyFeed } from 'features/legacy/hooks';
import { LegacyPostModalUpdate } from 'admin-features/legacy/components/LegacyPostModalUpdate';
import { ROUTES } from 'shared/constants';
import { InfiniteScroll } from 'shared/features/infinite-scroll';
import { useResponsive } from 'shared/hooks';

export function LegacyPostsList() {
  const [showPostEdit, setShowPostEdit] = useState(false);
  const [selectedPost, setSelectedPost] = useState<HomeFeedItem>();

  const matchLegacyPostsFeed = useRouteMatch(ROUTES.user.legacy.all);

  const isLegacyPostsFeed = Boolean(matchLegacyPostsFeed);

  const { onPostDelete } = usePostDelete();
  const { isMobile } = useResponsive();

  const { items: ads, loading: adsLoading } = useAdsList({
    borderless: isMobile,
  });

  const {
    data: posts,
    hasMore,
    loading: postLoading,
    refreshing,
    fetchMore,
  } = useLegacyFeed({
    withManyPinned: isLegacyPostsFeed,
    withPinnedOnTop: !isLegacyPostsFeed,
  });

  const loading = postLoading || adsLoading;

  const openEditPost = (post: HomeFeedItem) => {
    setSelectedPost(post);
    setShowPostEdit(true);
  };

  const closeEditPost = () => {
    setSelectedPost(undefined);
    setShowPostEdit(false);
  };

  const renderItem = (data: HomeFeedItem) => {
    const allowComments = Boolean(data.commentsAllowed);

    const onEdit = () => openEditPost(data);
    const onRemove = () => onPostDelete(data.id, data.postType);

    return (
      <HomeFeedCard
        key={data.id}
        data={data}
        onEdit={onEdit}
        onRemove={onRemove}
        hideComments={!allowComments}
        borderless={isMobile}
      />
    );
  };

  return (
    <Fragment>
      <InfiniteScroll fetching={refreshing} hasMore={hasMore} fetchMore={fetchMore}>
        <WithAdvertising loading={loading} items={posts.map(renderItem)} advertising={ads} />
      </InfiniteScroll>

      {posts.length === 0 && !loading && (
        <Box display="flex" justifyContent="center">
          <Typography align="center" variant="subtitle5">
            No posts found
          </Typography>
        </Box>
      )}

      <LegacyPostModalUpdate
        isOpen={showPostEdit}
        onClose={closeEditPost}
        selectedPost={selectedPost as any}
      />
    </Fragment>
  );
}
