import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import {
  DiscountCreateRequestMutation,
  DiscountCreateRequestMutationVariables,
  DiscountUpdateStatusRequestMutation,
  DiscountUpdateStatusRequestMutationVariables,
  AdminUpdateDiscountMutation,
  AdminUpdateDiscountMutationVariables,
  DiscountCreateRequestInput,
  DiscountUpdateInput,
} from 'shared/graphql/__generated__';

import {
  DISCOUNT_CREATE_REQUEST_MUTATION,
  ADMIN_DISCOUNT_UPDATE_MUTATION,
  DISCOUNT_UPDATE_STATUS_REQUEST_MUTATION,
} from '../query';

export const useDiscountInteraction = () => {
  const [createDiscount, { loading: creatingDiscount }] = useMutation<
    DiscountCreateRequestMutation,
    DiscountCreateRequestMutationVariables
  >(DISCOUNT_CREATE_REQUEST_MUTATION, {
    refetchQueries: ['DiscountsStats', 'AdminDiscountsSpreadsheetList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Discount created',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Please review carefully and try again.`,
    },
  });

  const [updateDiscount, { loading: updatingDiscount }] = useMutation<
    AdminUpdateDiscountMutation,
    AdminUpdateDiscountMutationVariables
  >(ADMIN_DISCOUNT_UPDATE_MUTATION, {
    refetchQueries: ['DiscountsStats', 'AdminDiscountsSpreadsheetList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Discount updated',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Please review carefully and try again.`,
    },
  });

  const [updateDiscountsStatuses, { loading: updatingDiscountStatuses }] = useMutation<
    DiscountUpdateStatusRequestMutation,
    DiscountUpdateStatusRequestMutationVariables
  >(DISCOUNT_UPDATE_STATUS_REQUEST_MUTATION, { refetchQueries: ['DiscountsStats'] });

  const onDiscountCreate = React.useCallback(
    async (data: DiscountCreateRequestInput) => {
      await createDiscount({ variables: { data } });
    },
    [createDiscount],
  );

  const onDiscountUpdate = React.useCallback(
    async (data: DiscountUpdateInput) => {
      if (data?.id) {
        const promises: Promise<unknown>[] = [];

        await updateDiscount({
          variables: { data },
        });

        await Promise.all(promises);
      }
    },
    [updateDiscount],
  );

  return {
    onDiscountCreate,
    onDiscountUpdate,
    updateDiscountsStatuses,
    creatingDiscount,
    updatingDiscount,
    updatingDiscountStatuses,
  };
};
