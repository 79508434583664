import { AvatarProps, Skeleton } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { FileValue } from '@8base-react/file-input';

import { ONE_MEGABYTE } from 'shared/constants/files';

import { FileInputWrap as FileInput } from '../FileInputWrap';
import { Backdrop, Container, Image } from './styles';
import { Close, Collections } from '@material-ui/icons';
import { useModalState } from 'shared/hooks';

const MAX_FILE_SIZE = ONE_MEGABYTE * 10;

export interface AvatarImageInputProps extends Omit<AvatarProps, 'onChange'> {
  onChange: (value: FileValue | null) => void;
  loading?: boolean;
}

export function AvatarImageInput({ onChange, loading, ...props }: AvatarImageInputProps) {
  const { show, open, close } = useModalState();

  const onPickImage = (pick: (options: {}) => Promise<unknown>) => {
    pick({
      accept: 'image/*',
      fromSources: ['local_file_system'],
      maxSize: MAX_FILE_SIZE,
    });
  };

  const handlePick = (resultFile: FileValue | FileValue[]) => {
    if (!resultFile || Array.isArray(resultFile)) {
      return;
    }

    onChange(resultFile);
  };

  const handleRemove = () => {
    onChange(null);
  };

  if (loading) {
    return (
      <Skeleton variant="circular">
        <Image {...props}>
          <FileUpload />
        </Image>
      </Skeleton>
    );
  }

  return (
    <FileInput maxFiles={1} onChange={handlePick}>
      {({ pick }) => {
        return (
          <Container onMouseOver={open} onMouseLeave={close}>
            {show ? (
              props.src ? (
                <Backdrop onClick={handleRemove}>
                  <Close />
                </Backdrop>
              ) : (
                <Backdrop onClick={() => onPickImage(pick)}>
                  <FileUpload />
                </Backdrop>
              )
            ) : null}

            <Image {...props}>{props.children ?? <Collections />}</Image>
          </Container>
        );
      }}
    </FileInput>
  );
}
