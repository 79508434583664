import { useMemo } from 'react';

import { POST_REPORT_STATUS } from '@jebel/constants';
import {
  extractHomeFeedItemMedia,
  fileManyRelations,
  formatISO8601Date,
  formatSQLDate,
} from '@jebel/utils';

import {
  HomeFeedPostsListItemFragment as HomeFeedPost,
  LegacyFeedPostsListItemFragment as LegacyFeedPost,
  LegacyFeedPostUpdateInput,
  Maybe,
  SchoolNewsPostsListItemFragment as SchoolNewsPost,
} from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';
import { PostModalCreate, PostModalCreateForm } from 'shared/features/posts';
import { useLegacyFeedPostUpdate } from 'features/legacy/hooks/useLegacyFeedPostUpdate';

type FeedPost = HomeFeedPost | LegacyFeedPost | SchoolNewsPost;

type SelectedPost = FeedPost & { postType?: Maybe<string> };

interface Props {
  selectedPost: SelectedPost | undefined;

  isOpen: boolean;
  onClose(): void;
}

export function LegacyPostModalUpdate({ isOpen, onClose, selectedPost }: Props) {
  const { data: school } = useSchoolConfiguration();
  const { onPostUpdate: updateLegacyFeedPost } = useLegacyFeedPostUpdate();

  const handleSubmit = async (data: PostModalCreateForm) => {
    const trimmedText = data.text && data.text.trim();

    const payload: LegacyFeedPostUpdateInput = {
      id: selectedPost?.id as string,
      text: trimmedText as string,
      isPinned: Boolean(data.isPinned),
      commentsAllowed: data.commentsAllowed,
      media: fileManyRelations(data?.media, initial.media),
      reportStatus: POST_REPORT_STATUS.pendingReview,
    };

    if (data.legacyDate) {
      payload.legacyDate = formatSQLDate(data.legacyDate);
    }

    if (data.postDate) {
      payload.postDate = formatISO8601Date(data.postDate);
    }

    await updateLegacyFeedPost(payload);
    onClose();
  };

  const initial = useMemo<PostModalCreateForm>(() => {
    const media = extractHomeFeedItemMedia(selectedPost?.media);
    const postDate = (selectedPost as LegacyFeedPost)?.postDate;

    return {
      commentsAllowed: selectedPost?.commentsAllowed,
      isPinned: Boolean(selectedPost?.isPinned),
      text: selectedPost?.text,
      media: media?.items ?? [],
      legacyDate: (selectedPost as LegacyFeedPost)?.legacyDate,
      postDate,
      isScheduledPost: Boolean(postDate),
    };
  }, [selectedPost]);

  return (
    <PostModalCreate
      isEdit
      withLegacyDate
      withSchedule
      initialValues={initial}
      onPostCreate={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
      withModeration={school?.moderateLegacyPosts ?? false}
    />
  );
}
