import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';

export const Container = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  z-index: 2;
`;

export const Image = styled(Avatar)``;
