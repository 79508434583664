import { lazy, Suspense } from 'react';
import { Box } from '@material-ui/core';

import { FeedLayout } from 'shared/components/layouts';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { SearchProvider } from 'shared/features/search';

import { GroupsPostsFilter } from '../components/GroupsPostsFilter';
import { JoinedGroupList } from '../components/JoinedGroupList';
import { FeedSkeleton } from 'shared/components/ui';

const GroupDetailsHeader = lazy(() => import('../components/GroupDetailsHeader'));
const GroupDetailsContent = lazy(() => import('../components/GroupDetailsContent'));

export function GroupDetailsPage() {
  return (
    <SearchProvider>
      <FeedLayout
        sidebar={
          <Box display="flex" flexDirection="column" gridGap="1rem">
            <GroupsPostsFilter />
            <CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />
            <JoinedGroupList />
          </Box>
        }
      >
        <Box display="grid" gridGap="1rem">
          <Suspense fallback={<FeedSkeleton count={1} />}>
            <GroupDetailsHeader />
          </Suspense>

          <Suspense fallback={<FeedSkeleton />}>
            <GroupDetailsContent />
          </Suspense>
        </Box>
      </FeedLayout>
    </SearchProvider>
  );
}
