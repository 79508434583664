import { css } from '@emotion/react';
import { Fragment, useCallback } from 'react';

import { Box, Modal } from 'shared/components/ui';
import { Maybe, JobOfferPresentationFragment } from 'shared/graphql';
import { ExplorerJobPostCard } from 'features/explorer/components';
import { useModalState } from 'shared/hooks';

import { EditPostModal, JobDetailsModal } from '../CareerModals';

const jobsContainerCSS = css`
  display: grid;
  gap: 1rem;
`;

interface Props {
  careersList?: Maybe<JobOfferPresentationFragment[]>;
}

export function CareersList(props: Props) {
  const offers = props.careersList ?? [];

  const {
    show: showDetailsModal,
    open: openDetailsModal,
    close: closeDetailsModal,
    params: detailsOffer,
  } = useModalState<JobOfferPresentationFragment>();

  const {
    show: showEditModal,
    open: openEditModal,
    close: closeEditModal,
    params: editableOffer,
  } = useModalState<JobOfferPresentationFragment>();

  const openDetails = useCallback(
    (offer: JobOfferPresentationFragment) => () => {
      openDetailsModal(offer);
    },
    [openDetailsModal],
  );

  const openEdit = useCallback(
    (offer: JobOfferPresentationFragment) => () => {
      openEditModal(offer);
    },
    [openEditModal],
  );

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: showEditModal,
          onClose: closeEditModal,
          fullWidth: true,
        }}
        titleProps={{ title: 'Edit Job Post' }}
      >
        <EditPostModal onModalClose={closeEditModal} jobOfferInfo={editableOffer} />
      </Modal>

      <JobDetailsModal
        open={showDetailsModal}
        onClose={closeDetailsModal}
        job={detailsOffer}
        authorId={detailsOffer?.createdBy?.id}
      />

      <Box css={jobsContainerCSS}>
        {offers.map(offer => (
          <ExplorerJobPostCard
            key={offer?.id}
            data={offer}
            onOpenDetails={openDetails(offer)}
            onOpenEdit={openEdit(offer)}
            withEdit
            withMessage
            withSave
          />
        ))}
      </Box>
    </Fragment>
  );
}
