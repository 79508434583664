import { Formik } from 'formik';

import { EventZipFilter } from 'features/events/types';
import { Button } from 'shared/components/ui';
import { useEventSearchContext } from 'features/events/hooks';
import { CardLayout } from 'shared/components/layouts';

import { EventsFilterForm } from '../EventsFilterForm';
import { Container } from './EventFilterSidebar.styles';

export function EventFilterSidebar() {
  const { zipFilter, setZipFilter } = useEventSearchContext();

  const handleSubmit = (filters: EventZipFilter) => {
    setZipFilter(filters);
  };

  return (
    <CardLayout>
      <Formik initialValues={zipFilter} onSubmit={handleSubmit}>
        {form => (
          <Container>
            <EventsFilterForm />

            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              onClick={form.submitForm}
            >
              APPLY
            </Button>
          </Container>
        )}
      </Formik>
    </CardLayout>
  );
}
