import { useLegacyFeedPostCreateMutation, LegacyFeedPostCreateInput } from 'shared/graphql';
import { useCurrentUser, useCurrentUserPermissions, useSchoolConfiguration } from 'shared/hooks';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';

export function useLegacyFeedPostCreate() {
  const { userId } = useCurrentUser();
  const { data: school } = useSchoolConfiguration();
  const { isSchoolAdmin } = useCurrentUserPermissions();

  const [mutate, { loading }] = useLegacyFeedPostCreateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'HomeFeedItems',
      'LegacyTimeStamps',
      'LegacyTimelineYears',
      'LegacyTimelineMonths',
      'LegacyTimelinePosts',
    ],
    context: {
      [SNACKBAR_ERROR_MESSAGE]: 'Something went wrong creating your post, please try again.',
      [SNACKBAR_SUCCESS_MESSAGE]: school?.moderateLegacyPosts
        ? `Your post will be moderated, we'll let you know when it's available on the feed.`
        : `Your post has been created successfully.`,
    },
  });

  const create = async (
    data: Omit<LegacyFeedPostCreateInput, 'author' | 'school'>,
    isScheduled = false,
  ) => {
    if (!userId || !isSchoolAdmin) {
      return;
    }

    const payload: LegacyFeedPostCreateInput = {
      author: { connect: { id: userId } },
      school: { connect: { id: school?.id } },

      ...data,
    };

    const response = await mutate({ variables: { data: payload } });
    return response;
  };

  return {
    create,
    loading,
  };
}
