import { useMemo } from 'react';

import { JobOffer, Maybe, OrganizationInfoFragment } from 'shared/graphql';

import { useCampaignAdsList } from './useAdsListQuery';
import { FeedAd } from 'shared/features/posts';

interface Options {
  /**
   * Remove the border of the card.
   * @ticket https://github.com/jebelapp/jebel/issues/1525
   * @default false
   */
  borderless?: boolean;

  onContactModalOpen?(data: { jobOffer?: JobOffer; organization?: OrganizationInfoFragment }): void;
}

export function useAdsList(props?: Options) {
  const { data, loading } = useCampaignAdsList();

  const onClickName = async (organization: Maybe<OrganizationInfoFragment> | undefined) => {
    if (!organization) {
      return;
    }

    props?.onContactModalOpen?.({ organization });
  };

  const items = useMemo((): JSX.Element[] => {
    return data.map(ad => (
      <FeedAd
        key={ad.id as string}
        data={ad}
        borderless={props?.borderless}
        onClick={() => onClickName(ad.sampaign?.advertising?.organization)}
      />
    ));
  }, [data, props?.borderless]);

  return {
    items,
    loading,
  };
}
