import { lazy, Suspense, useMemo } from 'react';
import { EChartsOption as ChartOptions } from 'echarts';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@emotion/react';

import { Container } from './BarChart.styles';
import { BarChartProps } from './BarChart.types';
import { createBarChartOptions } from './createBarChartOptions';

const Chart = lazy(() => import('echarts-for-react'));

export function BarChart(props: BarChartProps) {
  const theme = useTheme();

  const options = useMemo<ChartOptions>(() => {
    return createBarChartOptions(props, theme);
  }, [props, theme]);

  return (
    <Container>
      <Suspense fallback={<Skeleton width="100%" height="5rem" />}>
        <Chart option={options} notMerge lazyUpdate />
      </Suspense>
    </Container>
  );
}
