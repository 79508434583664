import { PropsWithChildren, ReactNode } from 'react';
import { Avatar, Box, Link } from '@material-ui/core';
import { css, Theme } from '@emotion/react';

import { Typography } from 'shared/components/ui';

const containerCSS =
  (withDivider = false) =>
  (theme: Theme) =>
    css`
      --container-gap: 1rem;
      --container-padding: 1.5rem;

      display: grid;
      gap: var(--container-gap);
      padding: var(--container-padding);

      ${withDivider &&
      css`
        &:not(:last-of-type) {
          border-bottom: 1px solid ${theme.palette.divider};
        }
      `}

      ${theme.breakpoints.down('md')} {
        --container-gap: 0.75rem;
        --container-padding: 1rem;
      }
    `;

const headerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

const iconContainerCSS = (theme: Theme) => css`
  --icon-container-size: 2rem;

  width: var(--icon-container-size);
  height: var(--icon-container-size);
  font-size: calc(var(--icon-container-size) * 0.5);
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.secondary.contrastText};
`;

interface DiscountsContainerProps {
  withDivider?: boolean;
  title?: string;
  toViewAll?: string;
  icon?: ReactNode;
  leftBox?: ReactNode;
}

export function ModuleContainer({
  children,
  withDivider = true,
  title,
  toViewAll,
  icon,
  leftBox,
}: PropsWithChildren<DiscountsContainerProps>) {
  return (
    <Box css={containerCSS(withDivider)}>
      <Box css={headerCSS}>
        <Box display="flex" alignItems="center" gridGap="0.5rem">
          {leftBox || (
            <Avatar variant="circular" css={iconContainerCSS}>
              {icon}
            </Avatar>
          )}

          <Typography color="primary">{title}</Typography>
        </Box>

        {toViewAll && (
          <Link color="secondary" variant="body2" href={toViewAll}>
            View all
          </Link>
        )}
      </Box>

      {children}
    </Box>
  );
}
