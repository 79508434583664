import { EVENT_STATUSES } from '@jebel/constants';

import { EventCreateInput, useEventCreateMutation } from 'shared/graphql';
import { useCurrentUser, useSchoolConfiguration, useToast } from 'shared/hooks';

export function useEventCreate() {
  const { userId, isAdmin } = useCurrentUser();
  const { showSuccess } = useToast();
  const { data: school } = useSchoolConfiguration();

  const [mutate, { loading, data, ...rest }] = useEventCreateMutation({
    refetchQueries: ['EventSearch', 'EventsCount', 'EventsList', 'EventsListCount'],
  });

  const onEventCreate = async (params: EventCreateInput) => {
    params.status = EVENT_STATUSES.pendingApproval;
    params.isApproved = false;

    params.author = {
      connect: {
        id: userId,
      },
    };

    params.school = {
      connect: {
        id: school?.id,
      },
    };

    if (isAdmin) {
      params.isApproved = true;
      params.status = EVENT_STATUSES.active;
    }

    const response = await mutate({ variables: { data: params } });

    const data = response.data?.eventCreate;

    if (data?.status === EVENT_STATUSES.pendingApproval) {
      showSuccess(
        'Success: Your Event Request has been submitted and is currently under review. You will be notified once the review is completed.',
      );

      return data;
    }

    if (data?.status === EVENT_STATUSES.active) {
      showSuccess('Success: Your Event has been created and published.');
      return data;
    }

    return data;
  };

  return { onEventCreate, loading, data, ...rest };
}
