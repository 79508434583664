import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { CenterLayout, RouteLayout } from 'shared/components/layouts';
import { useResponsive } from 'shared/hooks';

import {
  ExplorerNationProfileContent,
  ExplorerNationProfileSidebar,
} from '../components/ExplorerNationProfile';
import { useUserPublicProfile } from 'features/settings/hooks';

export function ExplorerNationProfilePage() {
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useResponsive();

  const { user, joinedGroups, professionalEducation, professionalExperience, loading } =
    useUserPublicProfile({ id });

  if (loading || !user) {
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  }

  return (
    <RouteLayout
      withMaxWidth={!isMobile}
      maxWidth="75%"
      content={
        <ExplorerNationProfileContent
          user={user}
          joinedGroups={joinedGroups}
          professionalEducation={professionalEducation}
          professionalExperience={professionalExperience}
        />
      }
      sidebar={
        <ExplorerNationProfileSidebar
          organizations={user?.ownedOrganizations?.items}
          groups={joinedGroups}
        />
      }
    />
  );
}
