import { Fragment, useState } from 'react';
import { Box } from '@mui/material';
import { Divider } from '@material-ui/core';

import { SettingsContentLayout } from 'shared/components/layouts';
import { Modal } from 'shared/components/ui';
import { SearchControl } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';
import { GroupsListItemFragment } from 'shared/graphql';
import { useResponsive } from 'shared/hooks';

import { GroupsContent } from '../components/SettingsGroups';
import { GroupDetailsModal } from '../components/SettingsGroups/GroupDetailsModal';

export const GroupsSettingPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<GroupsListItemFragment>();
  const [isDetailsModalOpened, setIsDetailsModalOpened] = useState<boolean>(false);

  const { isMobile } = useResponsive();

  const onShowGroupDetails = (group: GroupsListItemFragment) => {
    setIsDetailsModalOpened(true);
    setSelectedGroup(group);
  };

  const onShowGroupDetailsClose = () => {
    setIsDetailsModalOpened(false);
  };

  return (
    <Fragment>
      <SettingsContentLayout
        content={
          <Fragment>
            <Divider />
            <Box display="flex" justifyContent="space-between" py={3.125} px={3.75}>
              <Box flexGrow={1}>
                <SearchControl
                  withTextField
                  textFieldProps={{ placeholder: 'Search Groups' }}
                  withLayoutControl={!isMobile}
                  elementsWidth={isMobile ? [] : ['5fr', '1fr']}
                />
              </Box>
            </Box>

            <Divider />

            <GroupsContent onShowDetails={onShowGroupDetails} />
          </Fragment>
        }
        title="Groups"
      />

      <SpreadsheetProvider>
        <Modal
          dialogProps={{
            open: isDetailsModalOpened,
            onClose: onShowGroupDetailsClose,
            fullWidth: true,
            disablePortal: true,
          }}
          titleProps={{ title: 'Group Details' }}
          isVisibleDivider
        >
          <GroupDetailsModal group={selectedGroup} onModalClose={onShowGroupDetailsClose} />
        </Modal>
      </SpreadsheetProvider>
    </Fragment>
  );
};
