import React, { Fragment } from 'react';
import { SerializedStyles } from '@emotion/react';
import { ListItem } from '@material-ui/core';

import { Tooltip } from 'shared/components/ui';

type ListItemSymbolProps = {
  classes?: Record<string, any>;
  style?: Record<string, any>;
  css?: SerializedStyles;
  className?: string;
  onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseOver?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseOut?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseDown?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseUp?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseEnter?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onMouseLeave?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onWheel?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onContextMenu?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onAuxClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  key?: number;
  children?: React.ReactNode;
  tooltipTitle?: string;
  tooltipPlacement?: any;
  button?: any;
  selected?: boolean;
  alignItems?: any;
  dense?: boolean;
  disabled?: boolean;
  disableGutters?: boolean;
  divider?: boolean;
  startIcon?: React.ReactNode;
  listItemText?: React.ReactNode;
  text?: string;
  autoFocus?: any;
};

export const ListItemSymbol: React.FC<ListItemSymbolProps> = symbolProps => {
  return (
    <Fragment>
      {!symbolProps.tooltipTitle && (
        <ListItem
          classes={symbolProps?.classes}
          style={symbolProps?.style}
          css={symbolProps?.css}
          className={symbolProps?.className}
          onClick={symbolProps?.onClick}
          onMouseOver={symbolProps?.onMouseOver}
          onMouseOut={symbolProps?.onMouseOut}
          onMouseDown={symbolProps?.onMouseDown}
          onMouseUp={symbolProps?.onMouseUp}
          onMouseEnter={symbolProps?.onMouseEnter}
          onMouseLeave={symbolProps?.onMouseLeave}
          onWheel={symbolProps?.onWheel}
          onContextMenu={symbolProps?.onContextMenu}
          onAuxClick={symbolProps?.onAuxClick}
          alignItems={symbolProps.alignItems}
          autoFocus={symbolProps?.autoFocus}
          button={symbolProps?.button}
          dense={symbolProps.dense}
          disabled={symbolProps.disabled}
          disableGutters={symbolProps.disableGutters}
          divider={symbolProps.divider}
          selected={symbolProps.selected}
        >
          {symbolProps.startIcon && <Fragment>{symbolProps.startIcon}</Fragment>}
          <Fragment>{symbolProps.listItemText}</Fragment>
        </ListItem>
      )}
      {symbolProps.tooltipTitle && (
        <Tooltip placement={symbolProps.tooltipPlacement} title={symbolProps.tooltipTitle}>
          <ListItem
            classes={symbolProps?.classes}
            style={symbolProps?.style}
            css={symbolProps?.css}
            className={symbolProps?.className}
            onClick={symbolProps?.onClick}
            onMouseOver={symbolProps?.onMouseOver}
            onMouseOut={symbolProps?.onMouseOut}
            onMouseDown={symbolProps?.onMouseDown}
            onMouseUp={symbolProps?.onMouseUp}
            onMouseEnter={symbolProps?.onMouseEnter}
            onMouseLeave={symbolProps?.onMouseLeave}
            onWheel={symbolProps?.onWheel}
            onContextMenu={symbolProps?.onContextMenu}
            onAuxClick={symbolProps?.onAuxClick}
            alignItems={symbolProps.alignItems}
            autoFocus={symbolProps.autoFocus}
            button={symbolProps.button}
            dense={symbolProps.dense}
            disabled={symbolProps.disabled}
            disableGutters={symbolProps.disableGutters}
            divider={symbolProps.divider}
            selected={symbolProps.selected}
          >
            {symbolProps.startIcon && <Fragment>{symbolProps.startIcon}</Fragment>}
            <Fragment>{symbolProps.listItemText}</Fragment>
          </ListItem>
        </Tooltip>
      )}
    </Fragment>
  );
};
