import { useMemo } from 'react';
import { Business } from '@material-ui/icons';

import { OrganizationItemFragment } from 'shared/graphql';
import { generateOrganizationRoute } from 'shared/routes';

import { AuthorAvatar, AuthorAvatarProps } from './AuthorAvatar';

interface Props extends Omit<AuthorAvatarProps, 'name' | 'source' | 'route'> {
  organization: OrganizationItemFragment | undefined | null;
}

export function OrganizationAvatar({ organization, ...props }: Props) {
  const source = organization?.logo?.downloadUrl ?? undefined;
  const name = organization?.name;

  const route = useMemo(() => {
    return generateOrganizationRoute(organization?.id);
  }, [organization]);

  return (
    <AuthorAvatar
      {...props}
      source={source}
      name={name}
      route={route}
      disabled={!organization || props.disabled}
    >
      <Business fontSize="inherit" />
    </AuthorAvatar>
  );
}
