export const STRIPE_CHARGE_REASON = {
    FOR_ADS: 'Serve Ads',
    FOR_SCHOOL_SUPPORTS: 'School Supports',
    FOR_SPECIAL_OFFERS: 'Special Offers',
    FOR_FIRST_PAYMENT_METHOD: 'Add First Payment Method',
    FOR_NEW_PAYMENT_METHOD: 'New Payment Method',
    FOR_DEBT_BALANCE: 'Debt Balance',
};
/** @deprecated Use `STRIPE_CHARGE_REASON` instead. */
export const StripeReason = {
    forAds: STRIPE_CHARGE_REASON.FOR_ADS,
    forSchoolSupporters: STRIPE_CHARGE_REASON.FOR_SCHOOL_SUPPORTS,
    forSpecialOffers: STRIPE_CHARGE_REASON.FOR_SPECIAL_OFFERS,
    forNewPaymentMethod: STRIPE_CHARGE_REASON.FOR_NEW_PAYMENT_METHOD,
    forFirstPaymentMethod: STRIPE_CHARGE_REASON.FOR_FIRST_PAYMENT_METHOD,
    forDebtBilling: STRIPE_CHARGE_REASON.FOR_DEBT_BALANCE,
};
