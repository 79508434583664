import styled from '@emotion/styled';
import { Paper, Popover as PopoverBase } from '@material-ui/core';

// Popover

export const Popover = styled(PopoverBase)``;

// Content

export const Content = styled(Paper)``;
