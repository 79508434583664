import { useCurrentUser } from './useCurrentUser';
import { useUserJoinedGroups, UseUserJoinedGroupsOptions } from './useUserJoinedGroups';

type Options = Omit<UseUserJoinedGroupsOptions, 'user'>;

/** List all the groups that belongs to the current user. */
export function useCurrentUserGroups(options?: Options) {
  const { user } = useCurrentUser();

  const { data, count, loading, refetch, fetchMore } = useUserJoinedGroups({
    ...options,
    user: user!,
  });

  return {
    data,
    count,
    loading,
    refetch,
    fetchMore,
  };
}
