import {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';

import { usePreventBrowserClose } from './usePreventBrowserClose';
import { useToast } from './useToast';

const INITIATE_MESSAGE = `Please do not close this tab until the download process has been completed.`;
const ERROR_MESSAGE = `Seems there was an error while downloading the file. Please check your internet connection and try again.`;
const PREVENT_MESSAGE = `Please do not close this tab while the download is in progress.`;

export type UseDownloadLazyQueryDocument<D, V> = DocumentNode | TypedDocumentNode<D, V>;

interface Options<D, T> {
  filename?: string;
  displayName?: string;

  transform?(response: D): T[];
}

export type UseDownloadLazyQueryOptions<D, V extends OperationVariables, T> = LazyQueryHookOptions<
  D,
  V
> &
  Options<D, T>;

export function useDownloadLazyQuery<D = any, V extends OperationVariables = any, T = unknown>(
  query: UseDownloadLazyQueryDocument<D, V>,
  options?: UseDownloadLazyQueryOptions<D, V, T>,
) {
  const [execute, params] = useLazyQuery(query, options);

  const { showMessage, showError } = useToast();

  usePreventBrowserClose({
    prevent: params.loading,
    preventMessage: PREVENT_MESSAGE,
  });

  const generate = async () => {
    showMessage(INITIATE_MESSAGE);

    try {
      const response = await execute(options);

      if (response.error) {
        throw response.error;
      }

      return options?.transform?.(response.data as D) ?? response.data ?? [];
    } catch (err) {
      showError(ERROR_MESSAGE);
      throw err;
    }
  };

  return [generate, params] as const;
}
