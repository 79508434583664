import { Box, Typography } from '@material-ui/core';
import { css } from '@emotion/react';

import { InfiniteScroll } from 'shared/features/infinite-scroll';
import { ExplorerUserList } from './ExplorerUserList';
import pluralize from 'pluralize';
import { useExplorerUser } from 'features/explorer/hooks';
import { FeedSkeleton, LoadingOverlay } from 'shared/components/ui';

export function ExplorerUsers() {
  const { data: users, count: usersCount, loading, hasMore, fetchMore } = useExplorerUser();

  return (
    <LoadingOverlay loading={loading} overlay={<FeedSkeleton />}>
      <Box css={headerCSS}>
        <Typography color="primary">
          {pluralize('People', usersCount)} ({usersCount})
        </Typography>
      </Box>

      <InfiniteScroll hasMore={hasMore} fetchMore={fetchMore}>
        <ExplorerUserList usersList={users} withAds />
      </InfiniteScroll>
    </LoadingOverlay>
  );
}

const headerCSS = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
`;
