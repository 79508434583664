import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import {
  ThemeProvider as MaterialProvider,
  createMuiTheme as createTheme,
  ThemeOptions,
  darken,
  lighten,
} from '@material-ui/core';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';

import { PRIMARY_MAIN, SECONDARY_MAIN, TEXT_DARK_COLOR, customPalette } from 'theme';
import { SchoolConfigurationColorScheme } from 'shared/graphql';

import { CustomThemeContext } from './CustomThemeContext';

export function CustomThemeProvider(props: PropsWithChildren<unknown>) {
  const [options, setOptions] = useState<ThemeOptions>(customPalette as ThemeOptions);

  const onChangeTheme = useCallback(
    (colorSchema: SchoolConfigurationColorScheme) => {
      if (!colorSchema) {
        return;
      }

      setOptions(prev => {
        const primaryMain = colorSchema?.primary || PRIMARY_MAIN;
        const primaryDark = darken(primaryMain, 0.05);
        const primaryLight = colorSchema?.accent2 ?? lighten(primaryMain, 0.05);
        const primarySidebar = colorSchema?.sidebar || primaryMain;
        const primarySidebarFont = colorSchema?.sidebarFont || TEXT_DARK_COLOR;

        const secondaryMain = colorSchema?.secondary || SECONDARY_MAIN;
        const secondaryDark = darken(secondaryMain, 0.05);
        const secondaryLight = colorSchema?.accent1 ?? lighten(secondaryMain, 0.05);

        const options: ThemeOptions = {
          ...prev,

          palette: {
            ...prev.palette,

            primary: {
              ...prev.palette?.primary,

              main: primaryMain,
              dark: primaryDark,
              light: primaryLight,
              side: primarySidebar,
              sideFont: primarySidebarFont,
            },

            secondary: {
              ...prev.palette?.secondary,

              main: secondaryMain,
              dark: secondaryDark,
              light: secondaryLight,
            },
          },
        };

        return options;
      });
    },

    [setOptions],
  );

  const theme = useMemo(() => {
    return createTheme(options);
  }, [options]);

  return (
    <CustomThemeContext.Provider value={{ onChangeTheme }}>
      <MaterialProvider theme={theme}>
        <MUIThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>{props.children}</EmotionThemeProvider>
        </MUIThemeProvider>
      </MaterialProvider>
    </CustomThemeContext.Provider>
  );
}
