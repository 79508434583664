import { Switch, Route } from 'react-router-dom';
import { Avatar, Box, Paper, Typography } from '@material-ui/core';
import { School } from '@material-ui/icons';
import { css, Theme } from '@emotion/react';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { guestRoutes } from 'routes';
import { RouteLayout } from 'shared/components/layouts';
import { useResponsive, useSchoolConfiguration } from 'shared/hooks';
import { JEBEL_ISOLOGO72 } from 'assets/images';
import { Skeleton } from 'shared/components/ui';

const FORM_MIN_WIDTH = 500;

const SCHOOL_LOGO_HEIGHT = 96;

const pageCSS = (theme: Theme) => css`
  width: 100%;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 2rem;

  ${theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

const containerCSS = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 2rem;

  ${theme.breakpoints.down('xs')} {
    max-width: 100%;
    padding: 1rem;
    background: #fff;
  }
`;

const formCss = theme => css`
  padding: ${theme.spacing(5)}px;
  min-width: ${FORM_MIN_WIDTH}px;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down('xs')} {
    padding: 0;
    box-shadow: none;
    min-width: 100%;
  }
`;

const formInnerCss = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(2.5)}px;
  width: 100%;
`;

const containImageSize = `
  img {
    object-fit: contain;
  }
`;

const schoolBannerCSS = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const schoolLogoCSS = css`
  width: 100%;
  height: ${SCHOOL_LOGO_HEIGHT}px;

  ${containImageSize}
`;

const poweredByContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const poweredByLogoCSS = css``;

export function OnboardingPagesRouter() {
  const { isMobile } = useResponsive();
  const { data: school, loading } = useSchoolConfiguration();

  const logoUrl = school?.images?.emailLogo?.downloadUrl ?? undefined;
  const splashImageUrl = school?.images?.splashImage?.downloadUrl ?? undefined;

  return (
    <RouteLayout
      content={
        <Box css={pageCSS}>
          <Box css={containerCSS}>
            <Paper css={formCss}>
              <Box css={formInnerCss}>
                <Box display="grid" alignItems="center" justifyItems="center">
                  <Skeleton width="100%" variant="rect" loading={loading}>
                    <Avatar
                      css={schoolLogoCSS}
                      variant="square"
                      src={processFilestackUrlSrcSet(logoUrl)}
                    >
                      <School fontSize="inherit" />
                    </Avatar>
                  </Skeleton>
                </Box>
                <Switch>
                  {guestRoutes.map(
                    ({ routeComponent: RouteComponent = Route, component, path, ...rest }) => (
                      <RouteComponent
                        key={Array.isArray(path) ? path[0] : path}
                        path={path}
                        component={component}
                        {...rest}
                      />
                    ),
                  )}
                </Switch>
              </Box>
            </Paper>

            <Box css={poweredByContainerCSS}>
              <Typography align="center" color="textSecondary" variant="body2">
                POWERED BY
              </Typography>

              <picture>
                <img width={118} height={36} css={poweredByLogoCSS} src={JEBEL_ISOLOGO72} />
              </picture>
            </Box>
          </Box>

          {!isMobile && (
            <Skeleton width="100%" variant="rect" loading={loading}>
              <Avatar
                css={schoolBannerCSS}
                src={processFilestackUrlSrcSet(splashImageUrl)}
                variant="rounded"
              >
                <School fontSize="inherit" />
              </Avatar>
            </Skeleton>
          )}
        </Box>
      }
    />
  );
}
