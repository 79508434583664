import { useCallback, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { Box, Button, MenuItem } from '@material-ui/core';

import {
  ORGANIZATION_FIELD_CITY,
  ORGANIZATION_FIELD_DESCRIPTION,
  ORGANIZATION_FIELD_INDUSTRY,
  ORGANIZATION_FIELD_STATE,
  ORGANIZATION_FIELD_ZIP,
} from 'features/organizations/constants';
import { Icon, Select, TextField, Typography } from 'shared/components/ui';
import { DISCOUNTS_OPTIONS, SELECT_OPTION_ALL, US_STATES_OPTIONS } from 'shared/constants';
import { PaperLayout } from 'shared/components/layouts';
import { useSearchContext } from 'shared/features/search';
import { isEmptyObject } from 'shared/utils/object';
import { useResponsive } from 'shared/hooks';
import { useOrganizationsFilter } from 'features/organizations/hooks';

const filtersContainerCss = css`
  display: grid;
  padding: 2rem;
  gap: 1rem;
`;

const filterTextCss = theme => css`
  margin-left: ${theme.spacing(0.75)}px;
  font-size: ${theme.typography.fontSize + 4}px;
  font-weight: ${theme.typography.fontWeightMedium};
  line-height: 25px;
`;

const formContainerCss = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

const textInputCss = css`
  grid-column: 1/-1;
`;

const cancelButtonCss = (theme: Theme) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  color: ${theme.palette.text.secondary};
`;

/**
 * Transforms a value for use in a "contains" filter.
 *
 * @param {string | number | null | undefined} val - The value to be transformed.
 * @returns {Object} An object with a "contains" property based on the input value.
 * @property {string} [contains] - The "contains" property with the transformed value. It's used to check if some field contains a searched value.
 */
const containsTransform = val => {
  /**
   * If the value is the constant SELECT_OPTION_ALL, return an object with an empty "contains" property.
   *
   * @returns {Object} An object with an empty "contains" property.
   */
  if (val === SELECT_OPTION_ALL) {
    return {
      contains: '',
    };
  }

  /**
   * If the value is truthy, return an object with a "contains" property containing the value.
   *
   * @returns {Object} An object with a "contains" property containing the value.
   */
  if (val) {
    return {
      contains: val,
    };
  }

  /**
   * If the value is falsy, return an empty object.
   *
   * @returns {Object} An empty object.
   */
  return {};
};

/**
 * Check if a object contains any of the location keys: city, zip or state.
 * @returns {Object} An object containing processed information.
 * @property {boolean} hasKeys - Indicates if there are any location keys.
 * @property {Array} keys - The array of location keys.
 * @property {Array} values - The array of corresponding values.
 */
function getLocationKeys(obj): { hasKeys: boolean; keys: string[]; values: unknown[] } {
  const addressObj = obj?.location?.some?.address?.some;

  if (addressObj) {
    const keys = Object.keys(addressObj);
    const values = Object.values(addressObj);
    const locationKeys = keys.filter(key =>
      [ORGANIZATION_FIELD_CITY, ORGANIZATION_FIELD_STATE, ORGANIZATION_FIELD_ZIP].includes(key),
    );

    return {
      hasKeys: locationKeys.length > 0,
      keys: locationKeys,
      values,
    };
  }

  return {
    hasKeys: false,
    keys: [],
    values: [],
  };
}

/**
 * If two objects share the same location keys (city, zip or state), they will be combined following the location structure in only one object.
 */
function combineFilters(obj1, obj2) {
  const {
    hasKeys: firstHasKey,
    keys: firstKeys,
    values: firstLocationValues,
  } = getLocationKeys(obj1);
  const {
    hasKeys: secondHasKey,
    keys: secondKeys,
    values: secondLocationValues,
  } = getLocationKeys(obj2);

  if (firstHasKey === secondHasKey) {
    const locationKeys = [...firstKeys, ...secondKeys];
    const locationValues = [...firstLocationValues, ...secondLocationValues];

    const locationObject = {
      location: {
        some: {
          address: {
            some: {},
          },
        },
      },
    };

    locationKeys.forEach((key, index) => {
      locationObject.location.some.address.some[key] = locationValues[index];
    });

    return { ...obj1, ...obj2, ...locationObject };
  }

  return { ...obj1, ...obj2 };
}

export function OrganizationsFilter() {
  const { isTablet } = useResponsive();
  const { filter: recentFilters, setFilter: onChangeOrganizationFilters } =
    useOrganizationsFilter();

  const [filters, setFilters] = useState({
    description: recentFilters?.description?.contains || undefined,
    city: recentFilters?.location?.some?.address?.some?.city?.contains || undefined,
    zip: recentFilters?.location?.some?.address?.some?.zip?.contains || undefined,
    industry: !recentFilters?.industry?.contains
      ? SELECT_OPTION_ALL
      : recentFilters?.industry?.contains,
    state: isEmptyObject(recentFilters?.location?.some?.address?.some?.state)
      ? SELECT_OPTION_ALL
      : recentFilters?.location?.some?.address?.some?.state?.contains,
  });

  const { setIsFilterModalOpen, filter } = useSearchContext();

  const onChangeFilters = useCallback(
    (fieldType: string) => event => {
      const { value } = event.target;

      const locationFields = [
        ORGANIZATION_FIELD_CITY,
        ORGANIZATION_FIELD_STATE,
        ORGANIZATION_FIELD_ZIP,
      ];

      const isLocationAddress = locationFields.includes(fieldType);

      const locationValueContains = {
        location: {
          some: {
            address: {
              some: { [fieldType]: containsTransform(value) },
            },
          },
        },
      };

      const filterValue = { [fieldType]: value };
      const filterValueContains = isLocationAddress
        ? locationValueContains
        : { [fieldType]: containsTransform(value) };

      const newFilter = combineFilters(filter, filterValueContains);

      if (!isTablet) {
        if (fieldType === ORGANIZATION_FIELD_STATE || fieldType === ORGANIZATION_FIELD_INDUSTRY) {
          setFilters(prev => ({
            ...prev,
            ...{ [fieldType]: value },
          }));
        }
        onChangeOrganizationFilters({ ...newFilter });
      } else if (value === SELECT_OPTION_ALL) {
        setFilters(prev => ({ ...prev, ...{ [fieldType]: SELECT_OPTION_ALL } }));
      } else {
        setFilters(prev => ({ ...prev, ...filterValue }));
      }
    },
    [isTablet, onChangeOrganizationFilters, filter],
  );

  const onApplyFilters = useCallback(() => {
    const resultFilters = {
      description: containsTransform(filters?.description),
      industry: containsTransform(
        filters?.industry === SELECT_OPTION_ALL ? undefined : filters?.industry,
      ),
      location: {
        some: {
          address: {
            some: {
              city: containsTransform(filters?.city),
              zip: containsTransform(filters?.zip),
              state: containsTransform(
                filters.state === SELECT_OPTION_ALL ? undefined : filters.state,
              ),
            },
          },
        },
      },
    };

    onChangeOrganizationFilters(resultFilters);
    setIsFilterModalOpen(false);
  }, [
    filters.city,
    filters.description,
    filters.industry,
    filters.state,
    filters.zip,
    onChangeOrganizationFilters,
    setIsFilterModalOpen,
  ]);

  return (
    <PaperLayout css={filtersContainerCss}>
      <Box display="flex" alignItems="center">
        <Icon name="FilterAlt" />
        <Typography css={filterTextCss} variant="subtitle5">
          Filter by
        </Typography>
      </Box>
      <Box css={formContainerCss}>
        <Select
          css={textInputCss}
          variant="outlined"
          label="INDUSTRY"
          onChange={onChangeFilters(ORGANIZATION_FIELD_INDUSTRY)}
          value={filters?.industry}
          defaultValue={SELECT_OPTION_ALL}
        >
          {DISCOUNTS_OPTIONS.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          onChange={onChangeFilters(ORGANIZATION_FIELD_CITY)}
          placeholder="CITY"
          css={textInputCss}
          variant="outlined"
          defaultValue={recentFilters?.location?.some?.address?.some?.city?.contains || ''}
        />
        <Select
          variant="outlined"
          label="STATE"
          onChange={onChangeFilters(ORGANIZATION_FIELD_STATE)}
          value={filters?.state}
          defaultValue={SELECT_OPTION_ALL}
        >
          {US_STATES_OPTIONS.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          onChange={onChangeFilters(ORGANIZATION_FIELD_ZIP)}
          label="ZIP Code"
          variant="outlined"
          defaultValue={recentFilters?.location?.some?.address?.some?.zip?.contains || ''}
        />
        <TextField
          onChange={onChangeFilters(ORGANIZATION_FIELD_DESCRIPTION)}
          css={textInputCss}
          label="KEYWORDS"
          variant="outlined"
          defaultValue={recentFilters?.description?.contains || ''}
        />

        {isTablet && (
          <Box display="flex" gridGap="0.5rem">
            <Button onClick={onApplyFilters} color="primary" variant="contained" disableElevation>
              Apply
            </Button>

            <Button variant="text" onClick={() => setIsFilterModalOpen(false)}>
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </PaperLayout>
  );
}

export default OrganizationsFilter;
