import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { IconButton, Box, makeStyles, Theme } from '@material-ui/core';
import { ApolloQueryResult } from '@apollo/client';

import { useInboxMessageUpdate } from 'features/inbox/hooks/useInboxMessageUpdate';
import { Button, Icon, Typography, Modal, MediaGallery } from 'shared/components/ui';
import {
  InboxMessageFragment,
  InboxMessagesCountQuery,
  InboxMessagesListQuery,
  InboxUserFragment,
} from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { UserAvatar, UserNameLink } from 'shared/components/symbols';

import { AVATAR_SIZE, TRASH_ROUTE } from '../../constants';
import { getMessageTimeStamp } from '../../utils';

const avatarCSS = css`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

const timeStampCss = css`
  white-space: nowrap;
  font-size: 14px;
`;

const deleteButtonCss = theme => css`
  margin-left: ${theme.spacing(2)}px;
`;

const containerCSS = (theme: Theme) => css`
  padding: 1rem 1.5rem;

  &:not(:first-child) {
    border-top: solid 1px ${theme.palette.divider};
  }
`;

type InboxMessagesItemProps = InboxMessageFragment & {
  users: InboxUserFragment[];
  refetch: () => Promise<
    [ApolloQueryResult<InboxMessagesListQuery>, ApolloQueryResult<InboxMessagesCountQuery>]
  >;
  inboxId?: string | undefined | null;
  currentRoute: string;
  subject?: string;
};

const useStyles = makeStyles<Theme>(theme => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        margin: '24px 8px',
        borderRadius: 0,
      },

      '& .MuiDialog-container .MuiDialogTitle-root': {
        fontSize: '18px',
      },
    },
  },
}));

export const InboxMessagesItem = ({
  id,
  author: rawAuthor,
  users,
  subject,
  createdAt,
  text,
  media,
  refetch,
  inboxId,
  currentRoute,
  messageMedia,
}: InboxMessagesItemProps) => {
  const classes = useStyles();
  const [isOpenDeleteModal, setIsOpenModal] = useState(false);
  const { onOpenDeleteModal, onCloseDeleteModal } = useMemo(() => {
    return {
      onOpenDeleteModal: () => setIsOpenModal(true),
      onCloseDeleteModal: () => setIsOpenModal(false),
    };
  }, []);

  const { userId } = useCurrentUser();
  const {
    updateMessage,
    mutationData: { loading: updatingMessage },
  } = useInboxMessageUpdate(true, inboxId);

  const author = useMemo(
    () => users.find(user => user.id === rawAuthor?.id),
    [rawAuthor?.id, users],
  );

  const onConfirmDelete = useCallback(async () => {
    if (currentRoute === TRASH_ROUTE) {
      await updateMessage({ id, isHide: { connect: [{ id: userId }] } });
      return;
    }

    await updateMessage({ id, deletedBy: { connect: [{ id: userId }] } });
    onCloseDeleteModal();
    await refetch();
  }, [currentRoute, id, onCloseDeleteModal, refetch, updateMessage, userId]);

  return (
    <div css={containerCSS}>
      <Modal
        isVisibleDivider
        titleProps={{ title: 'Delete message - Confirmation' }}
        dialogProps={{
          open: isOpenDeleteModal,
          onClose: onCloseDeleteModal,
          fullWidth: true,
          className: classes.dialog,
        }}
      >
        <Typography variant="subtitle4" color="highlight">
          Are you sure you want to delete this message?
        </Typography>
        <Typography variant="subtitle5" color="textSecondary">
          This action can’t be undone.
        </Typography>
        <Box display="flex" justifyContent="flex-end" my={1}>
          <Button onClick={onCloseDeleteModal} size="medium">
            GO BACK
          </Button>
          <Button
            css={deleteButtonCss}
            onClick={onConfirmDelete}
            size="medium"
            variant="contained"
            loading={updatingMessage}
            color="primary"
          >
            YES, DELETE MESSAGE
          </Button>
        </Box>
      </Modal>
      <Box
        display="flex"
        flexGrow={1}
        minWidth={0}
        alignItems="center"
        css={css`
          > *:not(:last-child) {
            margin-right: 8px;
          }
        `}
      >
        <UserAvatar css={avatarCSS} user={author} />

        <Typography withEllipsis variant="body2">
          <span>By </span>
          <strong>
            <UserNameLink user={author} />
          </strong>
        </Typography>

        <Typography color="textSecondary" css={timeStampCss}>
          {getMessageTimeStamp(createdAt)}
        </Typography>

        <Box flexGrow={1} display="flex" justifyContent="flex-end" minWidth={0}>
          <IconButton disabled={!id} onClick={onOpenDeleteModal} size="small">
            <Icon name="DeleteForever" />
          </IconButton>
        </Box>
      </Box>

      <Typography
        variant="body1"
        css={css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-line;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        `}
      >
        {text}
      </Typography>

      <MediaGallery media={messageMedia?.media?.items ?? media?.items ?? []} />
    </div>
  );
};
