import { useMemo } from 'react';

import { useGroupsQuery, GroupDetailsFragment, UserKeyFilter } from 'shared/graphql';

import { createUserGroupsFilter } from 'features/search/utils';
import { useSchoolConfiguration } from './useSchoolConfiguration';

export type UseUserJoinedGroupsOptions = Parameters<typeof useGroupsQuery>[0] & {
  user: UserKeyFilter;

  /**
   * If `true`, only the groups that belong to the current school will be returned.
   * @default false
   */
  currentSchoolOnly?: boolean;
};

/**
 * Custom hook to fetch user joined groups with optional filtering based on the current school.
 *
 * @param {UseUserJoinedGroupsOptions} options - Configuration options for the hook.
 * @returns An object containing the user joined groups, loading state, and refetch function.
 */
export function useUserJoinedGroups(options: UseUserJoinedGroupsOptions) {
  const { data: school } = useSchoolConfiguration();

  const currentSchoolOnly = options.currentSchoolOnly ?? false;

  const filter = useMemo(() => {
    return createUserGroupsFilter({
      initial: options?.variables?.filter,
      user: options.user,
      school: currentSchoolOnly ? school : undefined,
    });
  }, [currentSchoolOnly, school, options.user, options.variables?.filter]);

  const {
    data: response,
    loading,
    refetch,
    fetchMore,
  } = useGroupsQuery({
    ...options,

    skip: !options.user || !school,
    variables: { ...options?.variables, filter },
  });

  const data: GroupDetailsFragment[] = response?.groupsList?.items ?? [];
  const count = response?.groupsList?.count ?? 0;

  return {
    data,
    count,
    loading,
    refetch,
    fetchMore,
  };
}
