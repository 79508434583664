import { gql } from '@apollo/client';
import { useCallback } from 'react';

import {
  SchoolConfigurationUpdateInput,
  File,
  useSchoolConfigurationUpdateMutation,
  SchoolConfigurationImage,
  SchoolConfigurationColorScheme,
} from 'shared/graphql';

import { useSchoolConfiguration } from './useSchoolConfiguration';

export const SCHOOL_CONFIGURATION_UPDATE_MUTATION = gql`
  mutation SchoolConfigurationUpdate($data: SchoolConfigurationUpdateInput!) {
    schoolConfigurationUpdate(data: $data) {
      id
    }
  }
`;

enum SchoolConfigurationAction {
  create = 'create',
  update = 'update',
}

enum SchoolConfigurationSettingsType {
  colorSchema = 'colorSchema',
  images = 'images',
}

enum ImageType {
  emailLogo = 'emailLogo',
  splashImage = 'splashImage',
  emailBanner = 'emailBanner',
}

interface SchoolUpdateData
  extends Omit<SchoolConfigurationUpdateInput, 'id' | 'colorSchema' | 'images'> {
  images?: SchoolConfigurationImage;
  colorSchema?: SchoolConfigurationColorScheme;
}

const getImageDataFromValue = (imageValue: File) => ({
  fileId: imageValue.fileId,
  filename: imageValue.filename,
  public: imageValue.public,
});

export function useSchoolConfigurationUpdate() {
  const { data: school } = useSchoolConfiguration();

  const [mutation, { loading }] = useSchoolConfigurationUpdateMutation({
    refetchQueries: ['SchoolConfiguration'],
    awaitRefetchQueries: true,
  });

  const getPreparedImageData = useCallback(
    (imageType: ImageType, imageItem: File | null | undefined) => {
      const currentImages = school?.images || {};

      if (currentImages?.[imageType]) {
        if (!imageItem) {
          return {
            [imageType]: {
              disconnect: {
                fileId: currentImages?.[imageType]?.fileId || '',
              },
            },
          };
        }

        return {
          [imageType]: {
            update: getImageDataFromValue(imageItem),
          },
        };
      }

      if (imageItem) {
        return {
          [imageType]: {
            create: getImageDataFromValue(imageItem),
          },
        };
      }

      return {};
    },
    [school?.images],
  );

  const getMutationAction = useCallback(
    (settingsType: SchoolConfigurationSettingsType): SchoolConfigurationAction => {
      const configurationHasCurrentSettings = school?.[settingsType];

      if (configurationHasCurrentSettings) {
        return SchoolConfigurationAction.update;
      }

      return SchoolConfigurationAction.create;
    },
    [school],
  );

  const mutate = async (schoolConfiguration: SchoolUpdateData) => {
    if (!school || !school.id) {
      return;
    }

    const imagesAction = getMutationAction(SchoolConfigurationSettingsType.images);
    const colorSchemaAction = getMutationAction(SchoolConfigurationSettingsType.colorSchema);
    const imagesEntries = Object.entries(schoolConfiguration.images || {});

    const preparedImagesDataList = imagesEntries.map(([imageType, imageItem]) =>
      getPreparedImageData(imageType as ImageType, imageItem as File),
    );

    const preparedImagesData = Object.assign({}, ...preparedImagesDataList);

    await mutation({
      variables: {
        data: {
          ...schoolConfiguration,

          id: school.id,

          images: {
            [imagesAction]: {
              ...preparedImagesData,
            },
          },

          colorSchema: {
            [colorSchemaAction]: schoolConfiguration.colorSchema,
          },
        },
      },
    });
  };

  return {
    mutate,
    /** @deprecated Use `mutate` instead. */
    schoolConfigurationUpdate: mutate,
    loading,
  };
}
