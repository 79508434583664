import { UserPublicProfileFragment, useUserPublicProfileQuery } from 'shared/graphql';

import { useUserJoinedGroups } from 'shared/hooks';

interface Options {
  id: string | undefined | null;
}

/**
 * Custom hook to fetch the public profile information of a user.
 * @param id - The ID of the user.
 * @returns An object containing the public profile information, loading state, and error state.
 */
export function useUserPublicProfile({ id }: Options) {
  const {
    data: response,
    loading: userPublicProfileLoading,
    error,
  } = useUserPublicProfileQuery({
    skip: !id,
    variables: { id: id as string },
  });

  const { data: joinedGroups, loading: joinedGroupsLoading } = useUserJoinedGroups({
    user: { id },
    currentSchoolOnly: true,
  });

  const user = response?.user as UserPublicProfileFragment | undefined;
  const professionalExperience = response?.professionalExperience?.items ?? [];
  const professionalEducation = response?.professionalEducation?.items ?? [];

  const loading = userPublicProfileLoading || joinedGroupsLoading;

  return {
    user,
    joinedGroups,
    professionalExperience,
    professionalEducation,
    loading,
    /** @deprecated Use `user`, `professionalExperience` or `professionalEducation` instead. */
    publicProfileInfo: response,
    /** @deprecated Use `loading` instead. */
    publicInfoLoading: loading,
    error,
  };
}
