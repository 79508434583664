import { lazy, Suspense } from 'react';

import { FeedLayout } from 'shared/components/layouts';
import { SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { SearchProvider } from 'shared/features/search';
import { FeedSkeleton } from 'shared/components/ui';

import { SCORECARD_SORT_INFO_DEFAULT_OPTION } from '../constants';
import { ClassScorecardHeader } from '../components/ClassScorecardHeader';

const ClassScorecardContent = lazy(() => import('../components/ClassScorecardContent'));

export function ClassScorecardPage() {
  return (
    <SearchProvider defaultSortOption={SCORECARD_SORT_INFO_DEFAULT_OPTION}>
      <FeedLayout sidebar={<CommunitySidebar items={[SUPPORTERS]} />}>
        <ClassScorecardHeader />

        <Suspense fallback={<FeedSkeleton />}>
          <ClassScorecardContent />
        </Suspense>

        {/* <ClassesList /> */}
      </FeedLayout>
    </SearchProvider>
  );
}
