import React, { Fragment } from 'react';
import { css, useTheme } from '@emotion/react';
import { Typography, Switch } from '@material-ui/core';

import { useGlobalState } from 'providers';
import { DialogSymbol } from 'shared/components/symbols';
import { Box, Form, FormTextField, Icon, Button } from 'shared/components/ui';

import { SwitchToggle } from '../ui/SwitchToggle';

const container508CSS = css`
  padding-top: 15px;
  padding-bottom: 15px;
`;

const formBlock16CSS = css`
  display: grid;
  grid-row-gap: 20px;
`;

const container509CSS = css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-gap: 35px;
`;

const container510CSS = css`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  justify-content: start;
`;

const container511CSS = css`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  justify-content: start;
`;

const container512CSS = css`
  background-color: #f6f8fa;
  border-style: dashed;
  border-width: 1px;
  border-color: #bbbbbb;
  height: 150px;
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;
  align-content: center;
`;

const container513CSS = css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  grid-column-gap: 20px;
`;

export const EditPostModal: React.FC = () => {
  const theme: any = useTheme();
  const { editPost } = useGlobalState();

  return (
    <DialogSymbol
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        onClose: _ => {
          editPost.close();
        },
        open: editPost.isOpened,
      }}
      titleProps={{ title: 'Edit Post' }}
    >
      <Box css={container508CSS}>
        <Form
          oldCss={formBlock16CSS}
          onSubmit={async (data, actions) => {
            alert(JSON.stringify(data));
          }}
        >
          {formProps => (
            <Fragment>
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'Message',
                  multiline: true,
                  rows: '7',
                  rowsMax: '7',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'textInput1' }}
              />
              <Box css={container509CSS}>
                <Box css={container510CSS}>
                  <Typography
                    css={css`
                      color: ${theme.palette.primary.dark};
                      font-weight: 500;
                    `}
                    variant="inherit"
                    align="inherit"
                    color="initial"
                    paragraph={false}
                  >
                    Allow comments
                  </Typography>
                  <SwitchToggle checked={false} color="primary" size="medium" />
                </Box>
                <Box css={container511CSS}>
                  <Typography
                    css={css`
                      color: ${theme.palette.primary.dark};
                      font-weight: 500;
                    `}
                    variant="inherit"
                    align="inherit"
                    color="initial"
                    paragraph={false}
                  >
                    Pin content
                  </Typography>
                  <SwitchToggle checked={true} color="primary" size="medium" />
                </Box>
              </Box>
              <Box css={container512CSS}>
                <Icon
                  css={css`
                    font-size: 35px;
                    fill: ${theme.palette.primary.light};
                  `}
                  name="CloudUpload"
                  variant="filled"
                  color="inherit"
                  viewBox="0 0 24 24"
                />
                <Typography
                  css={css`
                    font-size: 16px;
                    font-weight: 500;
                    color: ${theme.palette.primary.light};
                  `}
                  variant="inherit"
                  align="inherit"
                  color="initial"
                  paragraph={false}
                >
                  Upload pictures or videos here
                </Typography>
              </Box>
              <Box css={container513CSS}>
                <Button
                  css={css`
                    letter-spacing: 1px;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                    line-height: 17px;
                    color: ${theme.palette.text.secondary};
                  `}
                  onClick={event => {
                    editPost.close();
                  }}
                  color="primary"
                  disabled={false}
                  loading={false}
                  size="medium"
                  variant="text"
                >
                  Cancel
                </Button>
                <Button
                  css={css`
                    letter-spacing: 1px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    padding-left: 15px;
                    padding-right: 15px;
                    line-height: 17px;
                    font-size: 14px;
                    background-color: ${theme.palette.primary.dark};
                  `}
                  onClick={event => {
                    editPost.close();
                  }}
                  color="primary"
                  disableElevation={true}
                  variant="contained"
                  type="submit"
                >
                  Update Post
                </Button>
              </Box>
            </Fragment>
          )}
        </Form>
      </Box>
    </DialogSymbol>
  );
};
