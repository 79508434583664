import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { Delete } from '@material-ui/icons';

import { Button, Modal, Typography } from 'shared/components/ui';
import {
  InboxMessageCreateInput,
  InboxUpdateMutation,
  InboxUpdateMutationVariables,
  Inbox,
  User,
  useInboxMessageMediaCreateMutation,
  FileKeyFilter,
} from 'shared/graphql';
import { INBOX_TYPES, TRASH_ROUTE } from 'features/inbox/constants';
import { APP_URL, SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { INBOX_UPDATE_MUTATION } from 'features/inbox/queries';
import { useCurrentUser, useModalState } from 'shared/hooks';
import { InboxCreateFormData, useInboxChatsList } from 'features/inbox/hooks/useInboxChatsList';
import { CommentForm, CommentFormValues } from 'shared/features/posts';

import { useInboxMessagesPost } from '../../hooks/useInboxMessagesPost';

const MIN_INPUT_SIZE = 30;
const MAX_INPUT_SIZE = 200;

const useStyles = makeStyles<Theme>(theme => ({
  input: {
    minHeight: MIN_INPUT_SIZE,
    maxHeight: MAX_INPUT_SIZE,
  },
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        margin: '24px 8px',
        borderRadius: 0,
      },

      '& .MuiDialog-container .MuiDialogTitle-root': {
        fontSize: '18px',
      },
    },
  },
}));

const containerCSS = (theme: Theme) => css`
  padding: 1rem 1.5rem;
  border-top: solid 1px ${theme.palette.divider};
`;

export type InboxMessagesButtonFormProps = {
  inbox: Inbox | null | undefined | any;
  currentRoute: string;
  users?: string[] | undefined;
  author?: null | User;

  onMessageCreate?(message: InboxMessageCreateInput): void;
};

export const InboxMessagesButtonForm = ({
  inbox,
  currentRoute,
  users,
  author,
  onMessageCreate: onSendMessage,
}: InboxMessagesButtonFormProps) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { userId } = useCurrentUser();
  const { onCreateInbox } = useInboxChatsList();

  const [onCreateInboxImage] = useInboxMessageMediaCreateMutation();

  const isAdminRoute = useMemo(
    () => (location.pathname as string).split('/')[1] === 'admin',
    [location],
  );

  const [updateInbox, { loading: deleteInboxLoading }] = useMutation<
    InboxUpdateMutation,
    InboxUpdateMutationVariables
  >(INBOX_UPDATE_MUTATION, {
    refetchQueries: ['InboxChatsList', 'InboxMessagesList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Your message has been successfully deleted!',
      [SNACKBAR_ERROR_MESSAGE]: `Error: The request is invalid. Try again.`,
    },
  });

  const {
    show: isOpenDeleteModal,
    open: onOpenDeleteModal,
    close: onCloseDeleteModal,
  } = useModalState();

  const { createMessage } = useInboxMessagesPost();

  const onInboxMessagesButtonPost = useCallback(
    (values: CommentFormValues, form: FormikHelpers<CommentFormValues>) => {
      const text = values.message ?? '';
      const media = values.media ?? [];

      const data: InboxMessageCreateInput = {
        text,
        reply: !isAdminRoute,
        inbox: {
          connect: {
            id: inbox?.id,
          },
        },
      };

      if (media.length > 0) {
        const mediaCreate = media.map<FileKeyFilter>(file => ({ fileId: file.fileId }));

        data.messageMedia = { create: { media: { create: mediaCreate } } };
      }

      if (userId) {
        data.author = { connect: { id: userId } };
      }

      onSendMessage?.(data);
      form.resetForm();

      createMessage(data, users);
    },
    [isAdminRoute, inbox, userId, onSendMessage, createMessage, users],
  );

  const onConfirmDelete = useCallback(async () => {
    if (deleteInboxLoading) {
      return;
    }

    await updateInbox({
      variables: { data: { id: inbox.ix, isHide: { connect: [{ id: userId }] } } },
    });

    onCloseDeleteModal();
  }, [deleteInboxLoading, inbox, onCloseDeleteModal, updateInbox, userId]);

  const onReplyToAuthor = useCallback(
    async (values: CommentFormValues, form: FormikHelpers<CommentFormValues>) => {
      let imagesId: string | undefined;

      const payload: InboxCreateFormData = {
        subject: inbox?.subject || '',
        text: values?.message ?? '',
        userIds: [author?.id as string, userId as string],
        type: INBOX_TYPES.personal,
      };

      const media = values?.media ?? [];

      if (media.length > 0) {
        const { data } = await onCreateInboxImage({
          variables: {
            data: {
              media: { create: media.map(file => ({ fileId: file.fileId })) as any },
            },
          },
        });

        imagesId = data?.inboxMessageMediaCreate.id as string;
      }

      if (imagesId) {
        payload.media = imagesId;
      }

      await onCreateInbox(payload);
      form.resetForm();

      return history.push(APP_URL[isAdminRoute ? 'admin' : 'user'].mail.sent);
    },
    [author?.id, history, inbox?.subject, isAdminRoute, onCreateInbox, onCreateInboxImage, userId],
  );

  const submitHandler = useMemo(() => {
    if (author?.id === userId || inbox?.type === INBOX_TYPES.personal) {
      return onInboxMessagesButtonPost;
    }

    return onReplyToAuthor;
  }, [author?.id, inbox?.type, onInboxMessagesButtonPost, onReplyToAuthor, userId]);

  return (
    <div css={containerCSS}>
      <Modal
        isVisibleDivider
        titleProps={{ title: 'Delete message - Confirmation' }}
        dialogProps={{
          open: isOpenDeleteModal,
          onClose: onCloseDeleteModal,
          fullWidth: true,
          className: classes.dialog,
        }}
      >
        <Typography variant="subtitle4" color="highlight">
          Are you sure you want to delete this message?
        </Typography>
        <Typography variant="subtitle5" color="textSecondary">
          This action can’t be undone.
        </Typography>

        <Box display="flex" justifyContent="flex-end" my={1} gridGap="0.5rem">
          <Button onClick={onCloseDeleteModal}>Go Back</Button>

          <Button
            color="primary"
            variant="contained"
            loading={deleteInboxLoading}
            onClick={onConfirmDelete}
          >
            Yes, delete message
          </Button>
        </Box>
      </Modal>

      <CommentForm
        onSubmit={submitHandler}
        addons={
          currentRoute === TRASH_ROUTE && (
            <Tooltip title="Delete this inbox">
              <IconButton
                aria-label="Delete"
                color="primary"
                onClick={onOpenDeleteModal}
                disabled={deleteInboxLoading}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          )
        }
      />
    </div>
  );
};
