import { lazy, Suspense } from 'react';

import { FeedLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { SearchProvider } from 'shared/features/search';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const CollectiveIntelligenceContent = lazy(() => import('../components/CollectiveIntelligence'));

export function CollectiveIntelligencePage() {
  return (
    <SearchProvider>
      <FeedLayout
        sidebar={
          <Suspense fallback={<FeedSkeleton />}>
            <CommunitySidebar items={[DISCOUNTS, SUPPORTERS]} />
          </Suspense>
        }
      >
        <Suspense fallback={<FeedSkeleton />}>
          <CollectiveIntelligenceContent />
        </Suspense>
      </FeedLayout>
    </SearchProvider>
  );
}
