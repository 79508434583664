import { useMinimalUsersListQuery } from 'shared/graphql';

type Options = Parameters<typeof useMinimalUsersListQuery>[0];

export function useMinimalUserList(options?: Options) {
  const { data: response, loading, refetch, fetchMore } = useMinimalUsersListQuery(options);

  const data = response?.users.items ?? [];
  const count = response?.users.count ?? 0;

  return {
    data,
    count,

    loading,

    refetch,
    fetchMore,
  };
}
