import React from 'react';

import { CreateGraduatingYearFormValues } from 'shared/validationSchemas';
import { useGraduatingYearsSpreadsheetInfo } from 'shared/hooks';
import { Modal } from 'shared/components/ui';

import { CreateOrEditGraduatingYearForm } from './CreateOrEditGraduatingYearForm';

type CreateGraduatingYearModalProps = {
  classId: string;
  isOpen: boolean;
  onModalClose: () => void;
  onEdit: () => void | Promise<void>;
};

export const EditGraduatingYearModal: React.FC<CreateGraduatingYearModalProps> = ({
  classId,
  isOpen,
  onModalClose,
  onEdit,
}) => {
  const { tableData, tableLoading, editGraduatingYear } = useGraduatingYearsSpreadsheetInfo();

  const classInfo = React.useMemo(() => {
    return (tableData?.metrics.items || []).find(graduatingYear => graduatingYear.id === classId);
  }, [classId, tableData?.metrics.items]);

  const initialValues = React.useMemo((): Partial<CreateGraduatingYearFormValues> => {
    return {
      classYear: classInfo?.year || undefined,
      alumniCount: classInfo?.expectedUsersCount
        ? Number(classInfo?.expectedUsersCount)
        : undefined,
    };
  }, [classInfo]);

  const onSubmit = React.useCallback(
    async (formData: CreateGraduatingYearFormValues) => {
      await editGraduatingYear({
        variables: {
          id: classId,
          data: {
            graduateCount: formData.alumniCount,
            year: Number(formData.classYear),
          },
        },
      });

      onEdit();
      onModalClose();
    },
    [classId, editGraduatingYear, onEdit, onModalClose],
  );

  return (
    <Modal
      dialogProps={{ maxWidth: 'xs', fullWidth: true, open: isOpen, onClose: onModalClose }}
      titleProps={{ title: 'Edit Class' }}
    >
      <CreateOrEditGraduatingYearForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onClose={onModalClose}
        isEditMode
        isLoading={tableLoading}
      />
    </Modal>
  );
};
