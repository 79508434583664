import { createFilterBuilder } from '@jebel/utils';
import { uniqBy } from 'ramda';
import { useMemo } from 'react';

import { PAGE_SIZE } from 'shared/constants';
import {
  GraduatingYearMetricFragment,
  GraduatingYearsMetricFilter,
  GraduatingYearsMetricsQuery,
  useGraduatingYearsMetricsQuery,
} from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';
import { GraduatingYearMetricRanked, useGraduatingYearsRanking } from './useGraduatingYearsRanking';

type Options = Parameters<typeof useGraduatingYearsMetricsQuery>[0];

export function useGraduatingYears(options?: Options) {
  const { data: school } = useSchoolConfiguration();
  const { data: ranking } = useGraduatingYearsRanking();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<GraduatingYearsMetricFilter>();

    if (school) {
      // https://github.com/jebelapp/jebel/issues/1575
      filter.and({ school: { equals: school.id } });
    }

    return filter.build();
  }, [school]);

  const {
    data: response,
    loading,
    refetch,
    fetchMore,
  } = useGraduatingYearsMetricsQuery({
    ...options,

    variables: {
      ...options?.variables,

      first: PAGE_SIZE,
      filter,
    },
  });

  const count = response?.metrics?.count ?? 0;

  const data = useMemo(() => {
    const data: GraduatingYearMetricFragment[] = response?.metrics?.items ?? [];

    return data.map<GraduatingYearMetricRanked>(graduatingYear => {
      const rank = ranking.find(rank => rank.id === graduatingYear.id);
      // If the rank is not found, the position is the last one.
      const position = rank?.position ?? data.length;

      return { ...graduatingYear, position };
    });
  }, [ranking, response]);

  return {
    data,
    count,
    loading,

    fetchMore,
    refetch,
  };
}

export function useGraduatingYearsPaginated(options?: Options) {
  const { data, count, loading, fetchMore } = useGraduatingYears(options);

  const next = async () => {
    await fetchMore({
      variables: {
        first: PAGE_SIZE,
        skip: data.length,
      },

      updateQuery(prev, { fetchMoreResult: curr }) {
        const oldest = prev?.metrics.items ?? [];
        const newest = curr?.metrics?.items ?? [];

        const count = curr?.metrics.count ?? 0;
        /** Merge between `oldest` items and `newest` reduced by ID. */
        const items = uniqBy(organization => organization.id, [...oldest, ...newest]);

        const data: GraduatingYearsMetricsQuery = {
          ...curr,

          metrics: {
            ...prev.metrics,

            count,
            items,
          },
        };

        return data;
      },
    });
  };

  return {
    data,
    count,

    loading: loading && data.length === 0,
    refreshing: loading && data.length > 0,
    hasMore: data.length < count,

    next,
    fetchMore: next,
  };
}
