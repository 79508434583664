import { ApolloError } from '@apollo/client';
import {
  UserFilter,
  useResendSchoolApprovalMutation,
  useUsersListCountLazyQuery,
} from 'shared/graphql';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

const SENDING_MEMBERS_INVITATION_MESSAGE_KEY = 'SENDING_MEMBERS_INVITATION_MESSAGE';

type Options = Parameters<typeof useResendSchoolApprovalMutation>[0];

/**
 * Custom hook to re-send member's invitations into a school.
 *
 * This hook provides a function to re-send member's invitations by their user keys and
 * handles the success and error notifications accordingly.
 *
 * @returns An object containing:
 * - `mutate`: A function to re-send member's invitations.
 * - `loading`: A boolean indicating if the mutation is in progress.
 *
 * @example
 * const { mutate, loading } = useResendMembersInvitation();
 *
 * const handleResendMemberInvitations = async () => {
 *   await mutate({ id: { equals: 'user1' } });
 * };
 */
export function useResendMembersInvitation(options?: Options) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();
  const { data: school } = useSchoolConfiguration();

  const [mutation, { loading }] = useResendSchoolApprovalMutation(options);
  const [fetchUsers] = useUsersListCountLazyQuery();

  const mutate = async (filter: UserFilter) => {
    if (!school) {
      return;
    }

    try {
      const responseUsers = await fetchUsers({ variables: { filter } });
      const count = responseUsers.data?.users?.count ?? 0;

      if (!count) {
        showError('No users found with the provided filter.');
        return;
      }

      showMessage(`Requesting re-send for invitations of ${count} member(s).`, {
        id: SENDING_MEMBERS_INVITATION_MESSAGE_KEY,
      });

      await mutation({
        variables: {
          filter,
          school: { id: school.id },
        },
      });

      showSuccess(
        'Re-send invitations request submitted successfully. The process may take some time, and the user(s) will be notified upon completion.',
      );
    } catch (err) {
      recordError(err);

      if (err instanceof ApolloError) {
        const [issue, ...issues] = err.graphQLErrors ?? [];

        let message = issue.message;

        if (issues.length) {
          message += ` (${issues.length} issues more)`;
        }

        showError(message, { reportable: false });
        return;
      }

      if (err instanceof Error) {
        showError(err.message);
      }
    } finally {
      dismiss(SENDING_MEMBERS_INVITATION_MESSAGE_KEY);
    }
  };

  return {
    mutate,
    loading,
  };
}
