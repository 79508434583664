/* eslint-disable consistent-return */
import { ApolloError } from '@apollo/client';
import { DateTime, DurationLikeObject } from 'luxon';

import { DISCOUNT_TYPES } from '@jebel/constants';
import { composeFieldValidators, formatPhoneNumber, isStr, isURL } from '@jebel/utils';

import { sendToSentry } from '../sentry';

export const withFormikHandle =
  (onSubmit: (data: any, helpers?: any) => any) =>
  async (formData: any, { setErrors, ...rest }: any) => {
    try {
      await onSubmit(formData, rest);
    } catch (e: any) {
      sendToSentry(e);

      if (e instanceof ApolloError) {
        const gqlErrors = (e.graphQLErrors?.[0] as unknown as Record<string, unknown>)?.details;

        setErrors(gqlErrors);
      }
    }
  };

export const required = (value: unknown) => {
  if (value === null || value === undefined) {
    return 'Must be filled';
  }

  if (Array.isArray(value) && value.length === 0) {
    return 'Must select at least one option';
  }

  if (typeof value === 'string' && value.length === 0) {
    return 'Must have at least 1 character';
  }

  return undefined;
};

export const maxLength = (value: any) =>
  (typeof value === 'string' &&
    value.length >= 1000 &&
    'Description has to be less than 1000 characters') ||
  undefined;

export const maxTextSymbols = (value: any, max: number) => {
  if (value && typeof value === 'string') {
    return value.length <= max ? undefined : `Max ${max} symbols. Please remove extra text.`;
  }

  return undefined;
};

export const isValidGPA = (given: unknown) => {
  if (!given) {
    return undefined;
  }

  const value = Number(given);

  if (isNaN(value)) {
    return 'Must be a integer number';
  }

  if (value < 0) {
    return 'Should be higher than 0';
  }

  return undefined;
};

/**
 * Compose multiple validation functions into a single one.
 * @deprecated Use {@linkcode composeFieldValidators} from `@jebel/utils` instead.
 */
export const composeValidators = composeFieldValidators;

export const isValidPhoneCode = (phoneCode?: string) => {
  if (phoneCode) {
    const isNumber = isFinite(Number(phoneCode));
    const isGreaterThanZero = Number(phoneCode) >= 1;
    const isLessThanThousand = Number(phoneCode) < 999;
    const isSpecialSymbol = /\+/g.test(phoneCode);

    const isValid = isNumber && isGreaterThanZero && isLessThanThousand && !isSpecialSymbol;
    return isValid ? undefined : 'Invalid phone code';
  }

  return undefined;
};

/** @deprecated Use `formatPhoneNumber` from `@jebel/utils` instead. */
export const formatToPhone = formatPhoneNumber;

/** Check `phone` is a valid phone number. */
export function mustBePhone(phone?: string) {
  const regex = /^\+[0-9]{1,3} [0-9]{0,11}$/i;
  const valid = regex.test(phone ?? '');

  if (phone && !valid) {
    return 'Invalid phone';
  }

  return undefined;
}

export const isValidEmail = (value: string) => {
  if (!value) {
    return;
  }

  if (value.length < 5) {
    return 'Email must be at least 5 characters long';
  }

  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  if (!regex.test(value)) {
    return 'Invalid email format';
  }
};

export const isValidPercent = (value: number) =>
  value > 0 && value <= 100 ? undefined : 'Invalid percent';

export const isCorrectDateInterval =
  (startDateRaw: string, minDateDiff: DurationLikeObject) => (value: string) => {
    const startDate = DateTime.fromISO(startDateRaw)
      .plus(minDateDiff)
      .set({ second: 0, millisecond: 0 });
    const endDate = DateTime.fromISO(value).set({ second: 0, millisecond: 0 });
    const diff = startDate.diff(endDate).as('minutes');

    if (diff > 0) {
      return 'Expiration date should be after start date';
    }
  };

export const isValidLink = (value: unknown) => {
  if (!value || !isStr(value)) {
    return undefined;
  }

  if (!isURL(value)) {
    return 'Invalid URL format';
  }

  return undefined;
};

export function isValidAmountDiscount(value: unknown, type: keyof typeof DISCOUNT_TYPES) {
  if (!value || !type) {
    return 'Must have an amount';
  }

  if (type === DISCOUNT_TYPES.free) {
    return undefined;
  }

  const number = Number(value);

  if (number < 0 || isNaN(number)) {
    return 'Must be a positive number';
  }

  if (type === DISCOUNT_TYPES.discount && number >= 100) {
    return 'Percentage are between 0% and 100%';
  }

  return undefined;
}

export function isValidZipCode(value: unknown) {
  const regex = /[0-9]{5,}/i;

  if (typeof value !== 'string') {
    return 'Must be a string';
  }

  if (value.length < 5) {
    return 'Should have at least 5 characters';
  }

  if (value.length > 10) {
    return 'Should have less than 10 characters';
  }

  if (!regex.test(value)) {
    return 'Should have a valid zip-code format (only numbers)';
  }

  return undefined;
}

/** Same as {@link isValidZipCode} but allowing empty zip-codes. */
export function isOptionalValidZipCode(value: unknown) {
  if (!value) {
    return;
  }

  return isValidZipCode(value);
}
